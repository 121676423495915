import axios from "axios";
/**
 * Create Axios Instances and Interceptors
 *
 * @type {string}
 */

export const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001"
    : process.env.NODE_ENV === "production"
    ? "https://api.portal.madukauniversity.edu.ng"
    : "";

let api = axios.create({ baseURL: baseUrl });

api.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error.response.status === 419 || error.response.status === 401) {
      window.location.replace("/");
    } else if (error.response.data) {
      return Promise.reject({
        ...error.response.data,
        statusCode: error.response.status,
      });
    } else if (error.request) {
      return Promise.reject({
        ...error.request,
        statusCode: error.response.status,
      });
    } else {
      return Promise.reject(error);
    }
  }
);

let Setup = () => {
  api.interceptors.request.use(
    function (config) {
      const token = localStorage.getItem("_token") || "";
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function (err) {
      return Promise.reject(err);
    }
  );
};

let token = localStorage.getItem("_token") || "";
const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  Authorization: `Bearer ${token}`,
};

const fetchApi = (url, method, data = "") => {
  const FULL_URL = `${process.env.REACT_APP_BASE_URL}${url}`;
  let config = {};
  config.method = method.toUpperCase();
  config.headers = headers;
  if (data) config.body = JSON.stringify(data);
  return new Promise((resolve, reject) => {
    fetch(FULL_URL, config)
      .then((response) => response.json())
      .then((dat) => resolve(data))
      .catch((error) => reject(error));
  });
};

const getCurrentSession = (url) => {
  return new Promise(async (resolve, reject) => {
    try {
      const currentSession = await fetch(url, { cache: "no-cache" });
      const session = await currentSession.json();

      resolve(session);
    } catch (e) {
      console.error("Error fetching current session<>", e);

      return reject(e.message);
    }
  });
};

export { api, Setup, fetchApi, getCurrentSession };
