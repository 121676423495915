import React, {useState} from 'react';
import {Button, Dialog, DialogContent, FormControl, FormHelperText} from '@mui/material';
import {v4 as uuidv4} from 'uuid';
import {api} from "../../../utils/http";
import {
  addFirstSitting,
  addSecondSitting,
  addUploads
} from "../../../redux/reducers/features/applicant-data/ssce-info-slice";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {ColorRing} from "react-loader-spinner";


function DirectTransferPayment({applicantData}) {
  const [file, setFile] = useState(null);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const uid = uuidv4();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleClick = () => {
    document.getElementById('file-input').click();
  };

  const updateApplicantData = async () => {
    setLoader(true);
    const formData = new FormData();
    formData.append('file', file);

    let response = await api.post(
      "/api/student-documents/upload-file",
      formData
    );

    if (response.success) {
      const applicantRegistration = await api
        .patch(`/api/applicant/${applicantData?._id}`, {
          transaction_ref: uid,
          paymentType: 'direct_transfer_payment',
          paymentFile: {...response?.data, amount: 500000}
        })

      if (applicantRegistration.success) {
        localStorage.setItem("applicant_id", applicantRegistration.data._id);
        dispatch(addUploads([...applicantRegistration.data?.documents]));
        dispatch(addFirstSitting(applicantRegistration.data?.exam_first_sitting));
        dispatch(addSecondSitting(applicantRegistration.data?.exam_second_sitting));

        //SAVE TRANSACTION ID TO THIS CREATION
        toast.success("Applicant Registration was successful", {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/jamb-application-summary");
      } else {
        toast.error("Applicant Registration was not successful", {
          position: "top-right",
        });
      }
    }
    setLoader(false);
  }


  return (
    <div style={{
      padding: '32px',
      backgroundColor: '#F2F9FF',
      border: 'solid 1px #00356B',
      textAlign: 'center',
      borderRadius: '10px',
    }}
    >
      {
        loader && (

          <Dialog
            open={loader}
            onClose={() => setLoader(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '0'
                }}>
                <ColorRing
                  visible={true}
                  height="30"
                  width="30"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{textAlign: 'center'}}
                  wrapperClass="color-ring-wrapper"
                  colors={['#00356B', '#00356B', '#00356B', '#00356B', '#00356B']}
                />
              </div>
            </DialogContent>
          </Dialog>
        )
      }

      <h3
        style={{
          fontSize: '24px',
          fontFamily: 'Inter',
          fontWeight: '500',
          lineHeight: '29px'
        }}
      >
        Make Direct Transfer
      </h3>

      <h6
        style={{
          fontWeight: '400',
          fontSize: '16px',
          fontFamily: 'Inter',
          lineHeight: '24px',
          color: '#2E5565'
        }}
      >
        You can transfer directly to the account below and submit your proof of payment
      </h6>

      <div>
        <h5
          style={{
            fontWeight: '400',
            fontSize: '16px',
            fontFamily: 'Inter',
            lineHeight: '24px',
            color: '#000000',
            marginBottom: '1rem'
          }}
        >
          Kindly pay into the account details below;
        </h5>

        <h4
          style={{
            fontWeight: '500',
            fontSize: '18px',
            fontFamily: 'Inter',
            lineHeight: '21px',
            color: '#00356b'
          }}
        >
          Peace Micro Finance Bank Ltd.
        </h4>

        <h4
          style={{
            fontWeight: '600',
            fontSize: '40px',
            fontFamily: 'Inter',
            lineHeight: '48px',
            color: '#00356b'
          }}
        >
          1100544852
        </h4>

        <h4
          style={{
            fontWeight: '400',
            fontSize: '18px',
            fontFamily: 'Inter',
            lineHeight: '27px',
            color: '#2E5565'
          }}
        >
          Samuel Maduka Onyishi Foundation.
        </h4>

      </div>

      <div style={{margin: '2rem 0'}}>
        <h6>
          Upload Proof of Payment
        </h6>

        <FormControl>
          <Button variant="contained" color="primary" onClick={handleClick}>
            Choose File
          </Button>
          <input
            type="file"
            id="file-input"
            style={{display: 'none'}}
            onChange={handleFileChange}
          />
          <FormHelperText>
            {file ? `Selected file: ${file.name}` : 'No file selected'}
          </FormHelperText>
        </FormControl>

      </div>


      <Button
        variant="contained"
        color="primary"
        onClick={async () => await updateApplicantData()}
        sx={{width: '100%'}}
      >
        submit
      </Button>

    </div>
  );
}

export default DirectTransferPayment;
