export const getFileUploads = (newFiles = [], oldFiles = []) => {

    const files = [];

    let keys = [];

    for(let oldFile of oldFiles){

        for(let newFile of newFiles){

            if(newFile.id === oldFile.upload_type){
                if(!keys.includes(newFile.id)){
                    files.push({...newFile,oldFilePath: oldFile.file_name})
                    keys.push(newFile.id)
                }

            }
        }
    }
    return files;

}