import React from "react";

const InvoiceComponent = () => {
  // Define CSS styles as an object
  const styles = {
    body: {
      marginTop: "20px",
      background: "#eee",
    },
    invoice: {
      padding: "30px",
    },
    small: {
      fontWeight: "300",
    },
    hr: {
      marginTop: "10px",
      borderColor: "#ddd",
    },
    tableLine: {
      borderBottom: "1px solid #ccc",
    },
    table: {
      border: "none",
    },
    identity: {
      marginTop: "10px",
      fontSize: "1.1em",
      fontWeight: "300",
    },
    identityStrong: {
      fontWeight: "600",
    },
    grid: {
      position: "relative",
      width: "100%",
      background: "#fff",
      color: "#666666",
      borderRadius: "2px",
      marginBottom: "25px",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
    },
  };

  return (
    <div className="container">
      <div className="row">
        {/* BEGIN INVOICE */}
        <div className="col-xs-12">
          <div className="grid invoice" style={styles.grid}>
            <div className="grid-body" style={styles.invoice}>
              <div className="invoice-title">
                <div className="row">
                  <div className="col-xs-12">
                    <img
                      src="http://vergo-kertas.herokuapp.com/assets/img/logo.png"
                      alt=""
                      height="35"
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-xs-12">
                    <h2>
                      invoice
                      <br />
                      <span className="small">Refernce #1082</span>
                    </h2>
                  </div>
                </div>
              </div>
              <hr style={styles.hr} />
              <div className="row">
                <div className="col-xs-6">
                  <address>
                    <strong>Billed To:</strong>
                    <br />
                    Twitter, Inc.
                    <br />
                    795 Folsom Ave, Suite 600
                    <br />
                    San Francisco, CA 94107
                    <br />
                    <abbr title="Phone">P:</abbr> (123) 456-7890
                  </address>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-6 text-right">
                  <address>
                    <strong>Order Date:</strong>
                    <br />
                    17/06/14
                  </address>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <h3>ORDER SUMMARY</h3>
                  <table className="table table-striped" style={styles.table}>
                    <thead>
                      <tr className="line" style={styles.tableLine}>
                        <td>
                          <strong>#</strong>
                        </td>
                        <td className="text-center">
                          <strong>PROJECT</strong>
                        </td>

                        <td className="text-right">
                          <strong>SUBTOTAL</strong>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>
                          <strong>Template Design</strong>
                          <br />A website template is a pre-designed webpage, or
                          set of webpages, that anyone can modify with their own
                          content and images to setup a website.
                        </td>

                        <td className="text-right">$1,125.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceComponent;
