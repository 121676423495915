import React from 'react';
import {PaystackConsumer} from "react-paystack";
import {api} from "../../../utils/http";
import {toast} from "react-toastify";
import {
  addFirstSitting,
  addSecondSitting,
  addUploads
} from "../../../redux/reducers/features/applicant-data/ssce-info-slice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import './paystack-payment.css'


function PaystackPayment({applicantData}) {
  const [paymentResponse, setPaymentResponse] = React.useState({});
  const [saveData, setSaveData] = React.useState();
  const [paymentSuccess, setPaymentSuccess] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [clickedProcess, setClickedProcess] = React.useState(false);
  const navigate = useNavigate();

  const config = {
    reference: new Date().getTime().toString(),
    email: applicantData?.email,
    first_name: applicantData?.first_name,
    last_name: applicantData?.last_name,
    amount: 500000,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    language: 'en',
  };

  let idOfApplicantPayment;
  const dispatch = useDispatch();

  const handleClose = () => {
    console.log("closed");
  };

  const updateApplicantData = async (applicantPayment) => {
    const applicantRegistration = await api.patch(`/api/applicant/${applicantData?._id}`, {
      transaction_ref: applicantPayment?.reference,
      payment: applicantPayment?._id,
      paymentType: 'paystack_payment',
    })

    if (applicantRegistration.success) {
      localStorage.setItem("applicant_id", applicantRegistration.data._id);
      dispatch(addUploads([...applicantRegistration.data?.documents]));
      dispatch(addFirstSitting(applicantRegistration.data?.exam_first_sitting));
      dispatch(addSecondSitting(applicantRegistration.data?.exam_second_sitting));

      //SAVE TRANSACTION ID TO THIS CREATION
      toast.success("Applicant Registration was successful", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setOpenDialog(false);
      navigate("/jamb-application-summary");
    } else {
      toast.error("Applicant Registration was not successful", {
        position: "top-right",
      });
    }
  }

  const handleSuccess = async (reference) => {

    try {
      setPaymentResponse(reference);
      const savePayment = await api.post("/api/applicant-payment/fee", {
        reference: reference?.reference,
        transaction: reference?.trans || new Date().getTime().toString()
      });

      idOfApplicantPayment = savePayment?.data?._id;
      let paidRes;

      if (!idOfApplicantPayment) {
        toast.error("An error occurred");
        return;
      }

      setSaveData(savePayment.data);


      const saveRes = await api.post("/api/payment/payment-verification", {
        ref_id: reference?.reference,
      });

      paidRes = saveRes;

      setPaymentResponse(saveRes);

      const updatePayment = await api.patch(
        `/api/applicant-payment/fee/${savePayment?.data?._id}`,
        {
          payment: saveRes?.paymentData,
        }
      );


      if (updatePayment.data) {
        setPaymentSuccess(true);
        localStorage.setItem("payRes", JSON.stringify(saveRes));

        await updateApplicantData(savePayment?.data);
      }

    } catch (e) {

      toast.error(e.message);

      console.error('Payment success handler<>', e)
    }


  };

  const componentProps = {
    ...config,
    text: "Paystack Button Implementation",
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };


  return (
    <div style={{
      padding: '32px',
      backgroundColor: '#EAFFEA',
      border: 'solid 1px #5D8487',
      textAlign: 'center',
      borderRadius: '10px',
    }}
    >

      <h3
        style={{
          fontSize: '24px',
          fontFamily: 'Inter',
          fontWeight: '500',
          lineHeight: '29px'
        }}
      >
        Pay with Paystack
      </h3>

      <h6
        style={{
          fontWeight: '400',
          fontSize: '16px',
          fontFamily: 'Inter',
          lineHeight: '24px',
          color: '#2E5565'
        }}
      >
        Your application is being submitted, Kindly proceed to make your payment.
      </h6>

      <div>
        <h5
          style={{
            fontWeight: '400',
            fontSize: '16px',
            fontFamily: 'Inter',
            lineHeight: '24px',
            color: '#000000',
            marginBottom: '1rem'
          }}
        >
          Medium of payment:
        </h5>

        <ul
          style={{
            fontWeight: '400',
            fontSize: '18px',
            fontFamily: 'Inter',
            lineHeight: '27px',
            color: '#2E5565',
            listStyle: 'none'

          }}
        >
          <li>
            Pay with Debit Card
          </li>
          <li>
            Pay Via Transfer
          </li>
          <li>
            Mobile money via USSD
          </li>
        </ul>

      </div>

      <PaystackConsumer
        {...componentProps}
      >
        {({initializePayment}) => (
          <Button
            className="paystack-payment-btn"
            sx={{
              width: '100%',
              marginBottom: '2rem',
              backgroundColor: '#019601',
              color: '#ffffff'
            }}
            variant="contained"
            onClick={() => initializePayment(handleSuccess, handleClose)}

            onClickCapture={() => setClickedProcess(true)}
          >

            Continue to Payment
          </Button>
        )}
      </PaystackConsumer>

      <em
        style={{}}
      >
        Note: Make sure your network is stable to use paystack payment
      </em>

    </div>
  );
}

export default PaystackPayment;
