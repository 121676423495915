import * as React from 'react';
import {useSelector} from 'react-redux'
import {Box, Button, Checkbox, FormControlLabel, Grid, Typography} from "@mui/material";
import axios from "axios";
import {baseUrl} from "../../../utils/http";
import {toast} from "react-toastify";

const filterUploads = (oldUploads, newUploads) => {
    const storage = [];
    for (let oldUpload of oldUploads) {

        for (let newUpload of newUploads) {
            if (oldUpload.upload_type === newUpload.upload_type) {
                storage.push(newUpload);
            }
        }
        storage.push(oldUpload);
    }
    return storage;
}

const ConfirmationPage = React.forwardRef(({applicant, setAttestation, attestation}, ref) => {

    const personalDetail = useSelector(state => state.personal);
    const jambDetail = useSelector(state => state.jamb);
    const ssceDetail = useSelector(state => state.ssce);


    const handleFormUpdate = async () => {

        console.log('in<>');

        const documentUploads = filterUploads(applicant?.documents, ssceDetail?.uploads);

        const data = {
            first_name: personalDetail?.first_name,
            last_name: personalDetail?.last_name,
            middle_name: personalDetail?.middle_name,
            phone: personalDetail?.phone,
            nationality: personalDetail?.nationality,
            state: personalDetail?.state,
            lg: personalDetail?.lg,
            city: personalDetail?.city,
            dob: new Date(personalDetail?.dob),
            gender: personalDetail?.gender,
            guardian: {
                name: personalDetail?.guardian_name,
                address: personalDetail?.guardian_address,
                phone: personalDetail?.phone,
                relationship: personalDetail?.guardian_relationship,
            },
            jamb_number: jambDetail?.jamb_number,
            jamb_total_score: jambDetail?.jamb_total_score,
            jamb_subject_scores: jambDetail?.jamb_subject_scores,
            jamb_first_choice: jambDetail?.jamb_first_choice,
            jamb_second_choice: jambDetail?.jamb_second_choice,
            applied_first_choice: jambDetail?.applied_first_choice,
            applied_second_choice: jambDetail?.applied_first_choice,
            exam_first_sitting: ssceDetail?.firstSitting,
            exam_second_sitting: ssceDetail?.secondSitting,
            documents: documentUploads,
        }
        const token = JSON.parse(localStorage.getItem('_token'));
        const response = await axios({
            url: `${baseUrl}/api/applicant/update-applicant/${applicant._id}`,
            method: 'PUT',
            data: {payload: data},
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        console.log(response,"<>Response")
        if (response.data) {
            toast.success("application Updated Successful", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }


    }


    const passport = ssceDetail?.uploads.find(item => item.upload_type === 'passport_photo');
    const passport2 = applicant?.documents.find(item => item.upload_type === 'passport_photo');

    return (
        <div className="d-flex flex-column">

            <Typography variant="h4" sx={{fontWeight: '500'}} className="text-success text-center">
                Applicant Confirmation Page
            </Typography>
            <Box component="div" className="gap-5 mt-5">


                <Box component="section">

                    <Grid container spacing="2" justifyItems={'center'} sx={{justifyContent:'center', marginTop:'2rem'}}>

                        <Grid item xs={6}>
                            <Box component="div" style={{textAlign: 'left'}}>

                                <Typography variant="h5" sx={{fontWeight: '300'}} className="mb-3">
                                    Personal Details
                                </Typography>


                                <ul style={{listStyle: 'none',textAlign:'left'}}>
                                    <li><b>First Name: </b>{personalDetail?.first_name}</li>
                                    <li><b>Last Name: </b>{personalDetail?.last_name}</li>
                                    <li><b>Middle Name: </b>{personalDetail?.middle_name}</li>
                                    <li><b>Phone: </b>{personalDetail?.phone}</li>
                                    <li><b>Nationality: </b>{personalDetail?.nationality}</li>
                                    <li><b>State: </b>{personalDetail?.state}</li>
                                    <li><b>Local Government Area: </b>{personalDetail?.lg}</li>
                                    <li><b>City: </b>{personalDetail?.city}</li>
                                    <li><b>Date of birth: </b>{new Date(personalDetail?.dob)?.toLocaleDateString()}</li>
                                    <li><b>Gender: </b>{personalDetail?.gender}</li>
                                </ul>

                            </Box>
                        </Grid>

                        <Grid item xs={6}>

                            <img
                                src={passport?.file_url || passport2?.file_url}
                                alt={'Passport photograph'}
                                width={100}
                                height={100}
                            />

                        </Grid>

                    </Grid>
                </Box>
                <Box component="section">

                    <Grid container spacing={2}>

                        <Grid item xs={6}>

                            <Box component="div" sx={{justifyContent:'center', marginTop:'1rem'}}>
                                <Typography sx={{fontWeight: '300'}} variant="h5" className="mb-3">
                                    JAMB Details
                                </Typography>

                                <ul style={{listStyle: 'none'}}>
                                    <li><b>JAMB Number:</b> {jambDetail?.jamb_number}</li>
                                    <li><b>JAMB Total Score: </b>{jambDetail?.jamb_total_score}</li>

                                    {
                                        jambDetail?.jamb_subject_scores.map((item, index) => (
                                            <li key={index}>
                                                <b>{item.subject}: &nbsp;</b>
                                                {item.score}
                                            </li>
                                        ))
                                    }

                                </ul>



                            </Box>


                        </Grid>

                        <Grid item xs={6}>
                            <Box component="div" sx={{justifyContent:'center', marginTop:'1rem'}}>
                                <Typography variant="h5" sx={{fontWeight: '300'}} className="mb-3">
                                    Guardian/Parents Details
                                </Typography>

                                <ul style={{listStyle: 'none'}}>
                                    <li><b>Guardian Name: </b>{personalDetail?.guardian_name}</li>
                                    <li><b>Guardian Phone: </b>{personalDetail?.guardian_phone}</li>
                                    <li><b>Guardian Address: </b>{personalDetail?.guardian_address}</li>
                                    <li><b>Relationship Type: </b>{personalDetail?.guardian_relationship}</li>
                                </ul>

                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container sx={{justifyContent:'center', marginTop:'1rem'}}>

                        <Grid item xs={6}>
                            <Typography sx={{fontWeight: '300'}} variant="h6" className="mb-3">
                                JAMB First Choice
                            </Typography>
                            <ul style={{listStyle: 'none'}}>
                                <li><b>University</b>: {jambDetail?.jamb_first_choice.university}</li>


                                <li><b>Polytechnics</b>: {jambDetail?.jamb_first_choice.polytechnic}</li>


                                <li><b>Course</b>: {jambDetail?.jamb_first_choice.course}</li>

                            </ul>

                        </Grid>

                        <Grid item xs={6}>

                            <Typography sx={{fontWeight: '300'}} variant="h6" className="mb-3">
                                JAMB Second Choice
                            </Typography>
                            <ul style={{listStyle: 'none'}}>
                                <li><b>University</b>: {jambDetail?.jamb_second_choice.university}</li>
                                <li><b>Polytechnics</b>: {jambDetail?.jamb_second_choice.polytechnic}</li>
                                <li><b>Course</b>: {jambDetail?.jamb_second_choice.course}</li>

                            </ul>
                        </Grid>


                    </Grid>
                </Box>

                <Box component="section" sx={{justifyContent:'center', marginTop:'1rem'}}>

                    <Typography sx={{fontWeight: '300', textAlign:'center'}} variant="h5" className="my-5">
                        SSCE Details
                    </Typography>


                    <Grid container>
                        <Grid item >

                            <Box component="section">

                                {
                                    ssceDetail?.firstSitting?.exam_body && (
                                        <Typography sx={{fontWeight: '200'}} variant="h6" className="mb-2">
                                            First Sitting
                                        </Typography>
                                    )
                                }
                                <ul style={{listStyle:'none'}}>
                                    <li><b>{ssceDetail?.firstSitting?.exam_body && 'Examination Type: '}</b> {ssceDetail?.firstSitting?.exam_body}</li>
                                    {
                                        ssceDetail?.firstSitting?.exam_body && (
                                            ssceDetail
                                                ?.firstSitting
                                                ?.subject_grade
                                                ?.map((item, index) => (
                                                    <li key={index}>
                                                        <b>{item.subject}: </b>
                                                        {item.grade}
                                                    </li>
                                                ))
                                        )

                                    }

                                </ul>


                            </Box>

                        </Grid>

                        <Grid item>
                            <Box component={'section'}>

                                {
                                    ssceDetail?.secondSitting?.exam_body && (
                                        <Typography sx={{fontWeight: '200'}} variant="h6" className="mb-2">
                                            Second Sitting
                                        </Typography>
                                    )
                                }


                                <ul style={{listStyle:'none'}}>
                                    <li><b>{ssceDetail?.secondSitting?.exam_body && 'Examination Type: '}</b> {ssceDetail?.secondSitting?.exam_body}</li>
                                    {
                                        ssceDetail?.secondSitting?.exam_body && (
                                            ssceDetail
                                                ?.secondSitting
                                                ?.subject_grade
                                                ?.map((item, index) => (
                                                    <li key={index}>
                                                        <b>{item.subject}: </b>
                                                        {item.grade}
                                                    </li>
                                                )))
                                    }


                                </ul>

                            </Box>
                        </Grid>
                    </Grid>




                </Box>





            </Box>
            <Box component="div">
                <Typography variant="body1" className="fw-bold mt-1">
                    Attestation
                </Typography>
                <FormControlLabel
                    sx={{mt: 0}}
                    control={
                        <Checkbox
                            checked={attestation}
                            onChange={(e) => setAttestation(e.target.checked)}
                            inputProps={{"aria-label": "controlled_1"}}
                        />
                    }
                    label="I hereby confess that the above information is correct to the best of my knowledge. If any of the information is proved to be false, I will be considered disqualified."
                />


                <Button
                    variant="contained"
                    sx={{display: 'none'}}
                    onClick={handleFormUpdate}
                    ref={ref}
                >
                    Update Record
                </Button>
            </Box>

        </div>
    );
})

export default ConfirmationPage;


