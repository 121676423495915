import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Typography } from "@mui/material";
import logo from "../../../assets/img/MU LOGO.png";
import { loginSuccess } from "../../../redux/reducers/features/auth/auth-slice";
import { toast } from "react-toastify";

function LoginPage(props) {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();
  const [apiURL, setApiURL] = React.useState("");

  React.useEffect(() => {
    const isLogin = !!auth.loginSuccess.success;
    console.log(isLogin, "auth.loginSuccess");

    if (isLogin) {
      navigate("/dashboard");
    }
  }, [auth.loginSuccess, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = { email: email, password: password };
      const response = await axios.post(`${apiURL}applicant/login`, data);
      if (response.data) {
        localStorage.setItem("applicantId", response.data?.user_id);
        localStorage.setItem("_token", JSON.stringify(response.data.token));
        dispatch(loginSuccess({ loginSuccess: response.data, success: true }));
        navigate("/dashboard");
      }
    } catch (e) {
      console.error("Error in Login<>", e);

      if (e.response?.status === 403) {
        toast.error("Invalid Username or Password", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (e?.code === "ERR_NETWORK") {
        toast.error("Network Connectivity Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Server Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  React.useEffect(() => {
    (function () {
      let apiURL = "";
      if (process.env.NODE_ENV === "production") {
        apiURL = "https://api.portal.madukauniversity.edu.ng/api/";
      } else if (process.env.NODE_ENV === "development") {
        apiURL = "http://localhost:5200/api/";
      }

      setApiURL(apiURL);
    })();
  }, []);

  return (
    <Container
      className="d-flex justify-content-center align-items-center container text-center"
      style={{ margin: "auto", height: "100vh" }}
    >
      <Grid
        style={{
          backgroundColor: "#ffffff",
          padding: "64px",
          borderRadius: "10px",
          width: "700px",
        }}
      >
        <Grid item xs={12} lg={12}>
          <img src={logo} width="100" height="100" alt="logo" />
        </Grid>

        <Grid item xs={12} lg={12}>
          <Typography
            variant="h4"
            style={{
              fontFamily: "Inter",
              fontWeight: "500",
              fontSize: "25px",
              lineHeight: "30.26px",
              color: "#001f2b",
            }}
          >
            Check Admission Status
          </Typography>

          <Typography
            variant="h4"
            style={{
              fontFamily: "Inter",
              fontSize: "18px",
              lineHeight: "25.2px",
              color: "#2e5565",
            }}
          >
            Securely login to check status
          </Typography>
        </Grid>

        <Grid item lg={12} xs={12}>
          <form className="mt-4">
            <div className="form-group mb-4 text-start">
              <label className="form-label text-start fw-bold" htmlFor="email">
                Registered Email Address
              </label>
              <input
                type="email"
                value={email}
                required={true}
                placeholder="johndoe@gmail.com"
                name="email"
                id="email"
                className="form-control text-bg-light"
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>

            <div className="form-group mb-3 text-start">
              <label className="form-label  fw-bold" htmlFor="email">
                Password
              </label>
              <input
                type="password"
                value={password}
                required={true}
                placeholder="Password"
                name="password"
                id="password"
                className="form-control"
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>

            <div>
              <button
                type="button"
                style={{ backgroundColor: "#00356B" }}
                className="btn text-white w-100 mt-2"
                onClick={handleSubmit}
              >
                Login
              </button>
            </div>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}

export default LoginPage;
