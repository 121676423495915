import React from "react";
import {Box, Container, Typography} from "@mui/material";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import "./applicationForm.css";
import JambApplication from "./jamb/JambApplication";
import DirectEntryApplication from './direct/DirectEntryApplication';
import IjmbApplication from './ijmb/IjmbApplication'


export default function ApplicationForm() {
  const [jambPage, setJambPage] = React.useState(1);
  const [jambApplicationData, setJambApplicationData] = React.useState({})
  const [directEntryPage, setDirectEntryPage] = React.useState(1);
  const [directEntryApplicationData, setDirectEntryApplicationData] = React.useState({});
  const [ijmbPage, setIjmbPage] = React.useState(1);
  const [ijmbApplicationData, setIjmbApplicationData] = React.useState({})
  const [value, setValue] = React.useState('1');
  const [currentTab, setCurrentTab] = React.useState(false)


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  React.useEffect(() => {

    switch (value) {

      case '1': {
        setCurrentTab(true)
        break;
      }

      case '2': {
        setCurrentTab(true)
        break;
      }

      case '3': {
        setCurrentTab(true)
        break;
      }

      default:
        break;

    }
  }, [value])


  React.useEffect(() => {
  }, [jambPage]);

  return (
    <Container sx={{my: {xs: 0.3, lg: 3}}}>
      <Box sx={{justify: "center", textAlign: "center"}} component="div">
        <Typography
          variant="h4"
          sx={{fontWeight: "bold", mt: {xs: 4, lg: 4}}}

        >
          Maduka University Application Portal
        </Typography>
      </Box>

      <Box sx={{typography: 'body1', marginTop: '3rem'}}>
        <TabContext value={value}>
          <Box sx={{backgroundColor: ''}}>
            <TabList onChange={handleChange} aria-label="Application Types"
            sx={{}}
            >
              <Tab label="JAMB Entry Application" className={currentTab ? 'active-tab' : ''} value="1"/>
              <Tab label="Direct Entry Application" className={currentTab ? 'active-tab' : ''} value="2"/>
              <Tab label="IJMB Entry Application" className={currentTab ? 'active-tab' : ''} value="3"/>
            </TabList>
          </Box>
          <TabPanel value="1">
            <JambApplication
              jambPage={jambPage}
              setJambPage={setJambPage}
              jambApplicationData={jambApplicationData}
              setJambApplicationData={setJambApplicationData}
            />
          </TabPanel>
          <TabPanel value="2">
            <DirectEntryApplication
              directEntryPage={directEntryPage}
              setDirectEntryPage={setDirectEntryPage}
              directEntryApplicationData={directEntryApplicationData}
              setDirectEntryApplicationData={setDirectEntryApplicationData}
            />
          </TabPanel>
          <TabPanel value="3">
            <IjmbApplication
              ijmbPage={ijmbPage}
              setIjmbPage={setIjmbPage}
              ijmbApplicationData={ijmbApplicationData}
              setIjmbApplicationData={setIjmbApplicationData}
            />
          </TabPanel>
        </TabContext>
      </Box>


    </Container>
  );
}
