import React from "react";
import {useNavigate} from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import {api} from "../../../utils/http";
import {toast} from "react-toastify";
import {ColorRing} from "react-loader-spinner";

const examBody = ["WAEC", "NECO", "NABTEB"];
let paidRes;

const subjects = [
    "COMMERCE",
    "FINANCIAL ACCOUNTING",
    "CHRISTIAN RELIGIOUS STUDIES",
    "ECONOMICS",
    "GEOGRAPHY",
    "GOVERNMENT",
    "ISLAMIC STUDIES",
    "LITERATURE IN ENGLISH",
    "CIVIC EDUCATION",
    "ENGLISH LANGUAGE",
    "HAUSA",
    "IGBO",
    "YORUBA",
    "FURTHER MATHEMATICS",
    "GENERAL MATHEMATICS",
    "AGRICULTURAL SCIENCE",
    "BIOLOGY",
    "CHEMISTRY",
    "PHYSICS",
    "French",
    "Fishery",
    "Computer Studies",
  ],
  grades = ["A1", "B2", "B3", "C4", "C5", "C6", "D7", "E8", "F9"];

const awaitingResultUploads = [
  {id: "utme_slip", name: "JAMB UTME Exam Result"},
  {id: 'transcript', name: 'Original Copy of OND, HND and NCE Transcript. (pdf or doc)'},
  {id: 'o_level_result', name: 'Photocopy of entry qualification certificate(s) e.g. WAEC / NECO / GCE'},
  {id: 'passport_photograph', name: 'Passport Photograph'},
  {id: 'letter_of_identification', name: 'Letter of Identification from your Local Government Area'},
  {
    id: 'means_of_identification',
    name: 'Means of Identification',
  },
  {id: 'birth_cert', name: 'Birth Certificate / Age Declaration'},
];

const completeResultUploads = [
  {id: "utme_slip", name: "JAMB UTME Exam Result"},
  {id: 'transcript', name: 'Original Copy of OND, HND and NCE Transcript. (pdf or doc)'},
  {id: 'o_level_result', name: 'Photocopy of entry qualification certificate(s) e.g. WAEC / NECO / GCE'},
  {id: 'passport_photograph', name: 'Passport Photograph'},
  {id: 'letter_of_identification', name: 'Letter of Identification from your Local Government Area'},
  {
    id: 'means_of_identification',
    name: 'Means of Identification',
  },
  {id: 'birth_cert', name: 'Birth Certificate / Age Declaration'},
];

export default function SsceResultAndFiles({onPreviousClick, formInputs}) {
  const [uploadingFiles, setUploadingFiles] = React.useState(false);
  const [clickedProcess, setClickedProcess] = React.useState(false);
  const [awaitingResult, setAwaitingResult] = React.useState(false);
  const [loaderDialog, setLoaderDialog] = React.useState(false);
  const [applicantData, setApplicantData] = React.useState({});
  const [examType1, setExamType1] = React.useState(""),
    [firstSubject1, setFirstSubject1] = React.useState("ENGLISH LANGUAGE"),
    [firstSubject2, setFirstSubject2] = React.useState("GENERAL MATHEMATICS"),
    [firstSubject3, setFirstSubject3] = React.useState(""),
    [firstSubject4, setFirstSubject4] = React.useState(""),
    [firstSubject5, setFirstSubject5] = React.useState(""),
    [firstSubject6, setFirstSubject6] = React.useState(""),
    [firstSubject7, setFirstSubject7] = React.useState(""),
    [firstSubject8, setFirstSubject8] = React.useState(""),
    [firstSubject9, setFirstSubject9] = React.useState(""),
    [firstGrade1, setFirstGrade1] = React.useState(""),
    [firstGrade2, setFirstGrade2] = React.useState(""),
    [firstGrade3, setFirstGrade3] = React.useState(""),
    [firstGrade4, setFirstGrade4] = React.useState(""),
    [firstGrade5, setFirstGrade5] = React.useState(""),
    [firstGrade6, setFirstGrade6] = React.useState(""),
    [firstGrade7, setFirstGrade7] = React.useState(""),
    [firstGrade8, setFirstGrade8] = React.useState(""),
    [firstGrade9, setFirstGrade9] = React.useState(""),
    [examType2, setExamType2] = React.useState(""),
    [secondSubject1, setSecondSubject1] = React.useState(""),
    [secondSubject2, setSecondSubject2] = React.useState(""),
    [secondSubject3, setSecondSubject3] = React.useState(""),
    [secondSubject4, setSecondSubject4] = React.useState(""),
    [secondSubject5, setSecondSubject5] = React.useState(""),
    [secondSubject6, setSecondSubject6] = React.useState(""),
    [secondSubject7, setSecondSubject7] = React.useState(""),
    [secondSubject8, setSecondSubject8] = React.useState(""),
    [secondSubject9, setSecondSubject9] = React.useState(""),
    [secondGrade1, setSecondGrade1] = React.useState(""),
    [secondGrade2, setSecondGrade2] = React.useState(""),
    [secondGrade3, setSecondGrade3] = React.useState(""),
    [secondGrade4, setSecondGrade4] = React.useState(""),
    [secondGrade5, setSecondGrade5] = React.useState(""),
    [secondGrade6, setSecondGrade6] = React.useState(""),
    [secondGrade7, setSecondGrade7] = React.useState(""),
    [secondGrade8, setSecondGrade8] = React.useState(""),
    [secondGrade9, setSecondGrade9] = React.useState(""),
    [showSecondSitting, setShowSecondSitting] = React.useState(false),
    [isSubmitting, setIsSubmitting] = React.useState(false),
    [attestation, setAttestation] = React.useState(false), //required
    [openDialog, setOpenDialog] = React.useState(false), //required
    [otherInputs, setOtherInputs] = React.useState({}),
    [uploadList, setUploadList] = React.useState([]); //required

  const navigate = useNavigate();


  const handleCheckboxChange = (event) => {
    setAwaitingResult(event.target.checked);
  };


  const closeLoaderDialog = () => {
    setLoaderDialog(false);
  }

  const makePayment = () => {
    navigate('/payments', {state: {applicant: applicantData}})
  }


  const handleCheckForm = async () => {
    setLoaderDialog(true)
    // window.scroll(0, 0)

    if (uploadList.length < 5) {
      toast.error("Ensure all fields are filled");
      setOpenDialog(false);
      return false;
    }


    await handleSubmit();


    if (applicantData) {
      setOpenDialog(true);
    }

  };


  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = async () => {
    if (attestation) {
      setIsSubmitting(true);
      setUploadingFiles(true);
      try {
        if (uploadList && uploadList.length > 4) {
          let uploadedDoc = [];
          for (let index = 0; index < uploadList.length; index++) {
            const formData = new FormData();
            formData.append("file", uploadList[index].file);
            let response = await api.post(
              "/api/student-documents/upload-file",
              formData
            );
            let object = {
              file_url: response.data.url,
              file_name: response.data.filename,
              file_size: response.data.size,
              upload_type: uploadList[index].id,
            };
            uploadedDoc.push(object);
          }

          let data = {
            exam_first_sitting: {
              exam_type: examType1,
              subject_grade: [
                {subject: firstSubject1, grade: firstGrade1},
                {subject: firstSubject2, grade: firstGrade2},
                {subject: firstSubject3, grade: firstGrade3},
                {subject: firstSubject4, grade: firstGrade4},
                {subject: firstSubject5, grade: firstGrade5},
                {subject: firstSubject6, grade: firstGrade6},
                {subject: firstSubject7, grade: firstGrade7},
                {subject: firstSubject8, grade: firstGrade8},
                {subject: firstSubject9, grade: firstGrade9},
              ],
            },

            exam_second_sitting: {
              exam_type: examType2,
              subject_grade: [
                {subject: secondSubject1, grade: secondGrade1},
                {subject: secondSubject2, grade: secondGrade2},
                {subject: secondSubject3, grade: secondGrade3},
                {subject: secondSubject4, grade: secondGrade4},
                {subject: secondSubject5, grade: secondGrade5},
                {subject: secondSubject6, grade: secondGrade6},
                {subject: secondSubject7, grade: secondGrade7},
                {subject: secondSubject8, grade: secondGrade8},
                {subject: secondSubject9, grade: secondGrade9},
              ],
            },

            documents: uploadedDoc,
          };

          let _data = {...data, ...otherInputs};

          if (uploadedDoc && uploadedDoc.length > 4) {
            _data.docoments = uploadedDoc;

            const responseApi = await api.post("/api/applicant/create", {
              ..._data
            });

            if (responseApi.success) {
              setApplicantData(responseApi.data)
            }

            toast.success('Applicant created successfully');


          } else {
            toast("unable to upload documents", {position: "top-right"});
          }
        } else {
          toast("please upload all required documents", {
            position: "top-right",
          });
        }
      } catch (e) {
        toast.error(e.message, {position: "top-right"});
      } finally {
        setUploadingFiles(false);
        setClickedProcess(false);
        setLoaderDialog(false);
      }
      setIsSubmitting(false);
    } else {
      toast.error("ensure all required fields are filled", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleFilesUpload = async (event, type) => {
    if (event.target.files) {
      let newList = uploadList.filter((item) => item.id !== type.id);
      setUploadList(newList);
      const doc = {id: type.id, file: event.target.files[0]};
      setUploadList((current) => [...current, doc]);
    }
  };

  const getFileName = (type) => {
    let file = uploadList.find((item) => item.id === type.id);
    if (file && file.file && file.file.name) return file.file.name;
    return null;
  };

  React.useEffect(() => {
    setOtherInputs(formInputs);
  }, [showSecondSitting, uploadList, formInputs]);

  return (
    <React.Fragment>
      <Card sx={{p: {xs: 0, lg: 3}, mt: {xs: 1.8, lg: 3}}}>
        <CardContent>
          <Typography
            variant="h6"
            component="h6"
            color="#00356B"
            sx={{fontStyle: 'italic', margin: '1rem 0', fontSize: '25px', lineHeight: '30px', fontWeight: '500'}}
          >
            Senior Secondary School Certificate Details
          </Typography>

          <Typography variant="body1" className="fw-bold mt-4">
            Details of O'level (Not more than two sittings) SSCE RESULTS (First
            Sitting)
          </Typography>
          <Grid container spacing={2} sx={{mt: 0.3}}>
            <FormControlLabel
              control={
                <Checkbox
                  name="awaiting_result"
                  checked={awaitingResult}
                  onChange={handleCheckboxChange}
                />
              }
              label="Do you have an awaiting result?"
            />
            {!awaitingResult && (
              <Grid item xs={12} lg={12}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    mt: 0.5,
                    border: 1,
                    p: 1.2,
                    borderColor: "primary.main",
                  }}
                >
                  <FormControl sx={{minWidth: "100%"}}>
                    <InputLabel>Select Exam Type</InputLabel>
                    <Select
                      value={examType1}
                      onChange={(e) => setExamType1(e.target.value)}
                      fullWidth
                      label="Select exam type"
                    >
                      {examBody.map((body, index) => (
                        <MenuItem value={body} key={index}>
                          {body}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {!awaitingResult && (
              <Grid item xs={12} lg={6}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    mt: 0.5,
                    border: 1,
                    p: 1.2,
                    borderColor: "primary.main",
                  }}
                >
                  <Grid item xs={12} lg={8}>
                    <FormControl sx={{minWidth: "100%"}}>
                      <InputLabel>Select Subject</InputLabel>
                      <Select
                        value={firstSubject1}
                        onChange={(e) => setFirstSubject1(e.target.value)}
                        fullWidth
                        label="Select Subject"
                      >
                        {subjects.map((subject, index) => (
                          <MenuItem
                            value={subject}
                            key={Math.random() + "-" + index}
                          >
                            {subject}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormControl sx={{minWidth: "100%"}}>
                      <InputLabel>Select Grade</InputLabel>
                      <Select
                        value={firstGrade1}
                        onChange={(e) => setFirstGrade1(e.target.value)}
                        fullWidth
                        label="Select Grade"
                      >
                        {grades.map((grade, index) => (
                          <MenuItem
                            value={grade}
                            key={Math.random() + "-" + index}
                          >
                            {grade}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!awaitingResult && (
              <Grid item xs={12} lg={6}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    mt: 0.5,
                    border: 1,
                    p: 1.2,
                    borderColor: "primary.main",
                  }}
                >
                  <Grid item xs={12} lg={8}>
                    <FormControl sx={{minWidth: "100%"}}>
                      <InputLabel>Select Subject</InputLabel>
                      <Select
                        value={firstSubject2}
                        onChange={(e) => setFirstSubject2(e.target.value)}
                        fullWidth
                        label="Select Subject"
                      >
                        {subjects.map((subject, index) => (
                          <MenuItem
                            value={subject}
                            key={Math.random() + "-" + index}
                          >
                            {subject}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormControl sx={{minWidth: "100%"}}>
                      <InputLabel>Select Grade</InputLabel>
                      <Select
                        value={firstGrade2}
                        onChange={(e) => setFirstGrade2(e.target.value)}
                        fullWidth
                        label="Select Grade"
                      >
                        {grades.map((grade, index) => (
                          <MenuItem
                            value={grade}
                            key={Math.random() + "-" + index}
                          >
                            {grade}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          {/*/End of Row One*/}

          {/*Start of Row Two*/}
          {!awaitingResult && (
            <Grid container spacing={2} sx={{mt: 0.3}}>
              <Grid item xs={12} lg={6}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    mt: 0.5,
                    border: 1,
                    p: 1.2,
                    borderColor: "primary.main",
                  }}
                >
                  <Grid item xs={12} lg={8}>
                    <FormControl sx={{minWidth: "100%"}}>
                      <InputLabel>Select Subject</InputLabel>
                      <Select
                        value={firstSubject3}
                        onChange={(e) => setFirstSubject3(e.target.value)}
                        fullWidth
                        label="Select Subject"
                      >
                        {subjects.map((subject, index) => (
                          <MenuItem
                            value={subject}
                            key={Math.random() + "-" + index}
                          >
                            {subject}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormControl sx={{minWidth: "100%"}}>
                      <InputLabel>Select Grade</InputLabel>
                      <Select
                        value={firstGrade3}
                        onChange={(e) => setFirstGrade3(e.target.value)}
                        fullWidth
                        label="Select Grade"
                      >
                        {grades.map((grade, index) => (
                          <MenuItem
                            value={grade}
                            key={Math.random() + "-" + index}
                          >
                            {grade}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    mt: 0.5,
                    border: 1,
                    p: 1.2,
                    borderColor: "primary.main",
                  }}
                >
                  <Grid item xs={12} lg={8}>
                    <FormControl sx={{minWidth: "100%"}}>
                      <InputLabel>Select Subject</InputLabel>
                      <Select
                        value={firstSubject4}
                        onChange={(e) => setFirstSubject4(e.target.value)}
                        fullWidth
                        label="Select Subject"
                      >
                        {subjects.map((subject, index) => (
                          <MenuItem
                            value={subject}
                            key={Math.random() + "-" + index}
                          >
                            {subject}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormControl sx={{minWidth: "100%"}}>
                      <InputLabel>Select Grade</InputLabel>
                      <Select
                        value={firstGrade4}
                        onChange={(e) => setFirstGrade4(e.target.value)}
                        fullWidth
                        label="Select Grade"
                      >
                        {grades.map((grade, index) => (
                          <MenuItem
                            value={grade}
                            key={Math.random() + "-" + index}
                          >
                            {grade}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {/*/End of Row Two*/}

          {/*Start of Row Three*/}
          {!awaitingResult && (
            <Grid container spacing={2} sx={{mt: 0.3}}>
              <Grid item xs={12} lg={6}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    mt: 0.5,
                    border: 1,
                    p: 1.2,
                    borderColor: "primary.main",
                  }}
                >
                  <Grid item xs={12} lg={8}>
                    <FormControl sx={{minWidth: "100%"}}>
                      <InputLabel>Select Subject</InputLabel>
                      <Select
                        value={firstSubject5}
                        onChange={(e) => setFirstSubject5(e.target.value)}
                        fullWidth
                        label="Select Subject"
                      >
                        {subjects.map((subject, index) => (
                          <MenuItem
                            value={subject}
                            key={Math.random() + "-" + index}
                          >
                            {subject}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormControl sx={{minWidth: "100%"}}>
                      <InputLabel>Select Grade</InputLabel>
                      <Select
                        value={firstGrade5}
                        onChange={(e) => setFirstGrade5(e.target.value)}
                        fullWidth
                        label="Select Grade"
                      >
                        {grades.map((grade, index) => (
                          <MenuItem
                            value={grade}
                            key={Math.random() + "-" + index}
                          >
                            {grade}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    mt: 0.5,
                    border: 1,
                    p: 1.2,
                    borderColor: "primary.main",
                  }}
                >
                  <Grid item xs={12} lg={8}>
                    <FormControl sx={{minWidth: "100%"}}>
                      <InputLabel>Select Subject</InputLabel>
                      <Select
                        value={firstSubject6}
                        onChange={(e) => setFirstSubject6(e.target.value)}
                        fullWidth
                        label="Select Subject"
                      >
                        {subjects.map((subject, index) => (
                          <MenuItem
                            value={subject}
                            key={Math.random() + "-" + index}
                          >
                            {subject}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormControl sx={{minWidth: "100%"}}>
                      <InputLabel>Select Grade</InputLabel>
                      <Select
                        value={firstGrade6}
                        onChange={(e) => setFirstGrade6(e.target.value)}
                        fullWidth
                        label="Select Grade"
                      >
                        {grades.map((grade, index) => (
                          <MenuItem
                            value={grade}
                            key={Math.random() + "-" + index}
                          >
                            {grade}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {/*/End of Row Three*/}

          {/*Start of Row Four*/}
          {!awaitingResult && (
            <Grid container spacing={2} sx={{mt: 0.3}}>
              <Grid item xs={12} lg={6}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    mt: 0.5,
                    border: 1,
                    p: 1.2,
                    borderColor: "primary.main",
                  }}
                >
                  <Grid item xs={12} lg={8}>
                    <FormControl sx={{minWidth: "100%"}}>
                      <InputLabel>Select Subject</InputLabel>
                      <Select
                        value={firstSubject7}
                        onChange={(e) => setFirstSubject7(e.target.value)}
                        fullWidth
                        label="Select Subject"
                      >
                        {subjects.map((subject, index) => (
                          <MenuItem
                            value={subject}
                            key={Math.random() + "-" + index}
                          >
                            {subject}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormControl sx={{minWidth: "100%"}}>
                      <InputLabel>Select Grade</InputLabel>
                      <Select
                        value={firstGrade7}
                        onChange={(e) => setFirstGrade7(e.target.value)}
                        fullWidth
                        label="Select Grade"
                      >
                        {grades.map((grade, index) => (
                          <MenuItem
                            value={grade}
                            key={Math.random() + "-" + index}
                          >
                            {grade}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    mt: 0.5,
                    border: 1,
                    p: 1.2,
                    borderColor: "primary.main",
                  }}
                >
                  <Grid item xs={12} lg={8}>
                    <FormControl sx={{minWidth: "100%"}}>
                      <InputLabel>Select Subject</InputLabel>
                      <Select
                        value={firstSubject8}
                        onChange={(e) => setFirstSubject8(e.target.value)}
                        fullWidth
                        label="Select Subject"
                      >
                        {subjects.map((subject, index) => (
                          <MenuItem
                            value={subject}
                            key={Math.random() + "-" + index}
                          >
                            {subject}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormControl sx={{minWidth: "100%"}}>
                      <InputLabel>Select Grade</InputLabel>
                      <Select
                        value={firstGrade8}
                        onChange={(e) => setFirstGrade8(e.target.value)}
                        fullWidth
                        label="Select Grade"
                      >
                        {grades.map((grade, index) => (
                          <MenuItem
                            value={grade}
                            key={Math.random() + "-" + index}
                          >
                            {grade}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {/*/End of Row Four*/}

          {/*Start of Row Five*/}
          {!awaitingResult && (
            <Grid container spacing={2} sx={{mt: 0.3}}>
              <Grid item xs={12} lg={6}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    mt: 0.5,
                    border: 1,
                    p: 1.2,
                    borderColor: "primary.main",
                  }}
                >
                  <Grid item xs={12} lg={8}>
                    <FormControl sx={{minWidth: "100%"}}>
                      <InputLabel>Select Subject</InputLabel>
                      <Select
                        value={firstSubject9}
                        onChange={(e) => setFirstSubject9(e.target.value)}
                        fullWidth
                        label="Select Subject"
                      >
                        {subjects.map((subject, index) => (
                          <MenuItem
                            value={subject}
                            key={Math.random() + "-" + index}
                          >
                            {subject}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormControl sx={{minWidth: "100%"}}>
                      <InputLabel>Select Grade</InputLabel>
                      <Select
                        value={firstGrade9}
                        onChange={(e) => setFirstGrade9(e.target.value)}
                        fullWidth
                        label="Select Grade"
                      >
                        {grades.map((grade, index) => (
                          <MenuItem
                            value={grade}
                            key={Math.random() + "-" + index}
                          >
                            {grade}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {/*/End of Row Five*/}

          {!awaitingResult && (
            <FormControlLabel
              sx={{mt: 3}}
              control={
                <Checkbox
                  checked={showSecondSitting}
                  onChange={(e) => setShowSecondSitting(e.target.checked)}
                  inputProps={{"aria-label": "controlled"}}
                />
              }
              label="Second Sitting?"
            />
          )}

          {showSecondSitting && (
            <React.Fragment>
              <Typography variant="body1" className="fw-bold mt-2">
                Details of O'level SSCE RESULTS (Second Sitting)
              </Typography>

              {/*Start of Row One*/}
              <Grid container spacing={2} sx={{mt: 0.3}}>
                <Grid item xs={12} lg={12}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mt: 0.5,
                      border: 1,
                      p: 1.2,
                      borderColor: "primary.main",
                    }}
                  >
                    <FormControl sx={{minWidth: "100%"}}>
                      <InputLabel>Select Exam Type</InputLabel>
                      <Select
                        value={examType2}
                        onChange={(e) => setExamType2(e.target.value)}
                        fullWidth
                        label="Select exam type"
                      >
                        {examBody.map((body, index) => (
                          <MenuItem value={body} key={index}>
                            {body}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mt: 0.5,
                      border: 1,
                      p: 1.2,
                      borderColor: "primary.main",
                    }}
                  >
                    <Grid item xs={12} lg={8}>
                      <FormControl sx={{minWidth: "100%"}}>
                        <InputLabel>Select Subject</InputLabel>
                        <Select
                          value={secondSubject1}
                          onChange={(e) => setSecondSubject1(e.target.value)}
                          fullWidth
                          label="Select Subject"
                        >
                          {subjects.map((subject, index) => (
                            <MenuItem
                              value={subject}
                              key={Math.random() + "-" + index}
                            >
                              {subject}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl sx={{minWidth: "100%"}}>
                        <InputLabel>Select Grade</InputLabel>
                        <Select
                          value={secondGrade1}
                          onChange={(e) => setSecondGrade1(e.target.value)}
                          fullWidth
                          label="Select Grade"
                        >
                          {grades.map((grade, index) => (
                            <MenuItem
                              value={grade}
                              key={Math.random() + "-" + index}
                            >
                              {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mt: 0.5,
                      border: 1,
                      p: 1.2,
                      borderColor: "primary.main",
                    }}
                  >
                    <Grid item xs={12} lg={8}>
                      <FormControl sx={{minWidth: "100%"}}>
                        <InputLabel>Select Subject</InputLabel>
                        <Select
                          value={secondSubject2}
                          onChange={(e) => setSecondSubject2(e.target.value)}
                          fullWidth
                          label="Select Subject"
                        >
                          {subjects.map((subject, index) => (
                            <MenuItem
                              value={subject}
                              key={Math.random() + "-" + index}
                            >
                              {subject}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl sx={{minWidth: "100%"}}>
                        <InputLabel>Select Grade</InputLabel>
                        <Select
                          value={secondGrade2}
                          onChange={(e) => setSecondGrade2(e.target.value)}
                          fullWidth
                          label="Select Grade"
                        >
                          {grades.map((grade, index) => (
                            <MenuItem
                              value={grade}
                              key={Math.random() + "-" + index}
                            >
                              {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*/End of Row One*/}

              {/*Start of Row Two*/}
              <Grid container spacing={2} sx={{mt: 0.3}}>
                <Grid item xs={12} lg={6}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mt: 0.5,
                      border: 1,
                      p: 1.2,
                      borderColor: "primary.main",
                    }}
                  >
                    <Grid item xs={12} lg={8}>
                      <FormControl sx={{minWidth: "100%"}}>
                        <InputLabel>Select Subject</InputLabel>
                        <Select
                          value={secondSubject3}
                          onChange={(e) => setSecondSubject3(e.target.value)}
                          fullWidth
                          label="Select Subject"
                        >
                          {subjects.map((subject, index) => (
                            <MenuItem
                              value={subject}
                              key={Math.random() + "-" + index}
                            >
                              {subject}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl sx={{minWidth: "100%"}}>
                        <InputLabel>Select Grade</InputLabel>
                        <Select
                          value={secondGrade3}
                          onChange={(e) => setSecondGrade3(e.target.value)}
                          fullWidth
                          label="Select Grade"
                        >
                          {grades.map((grade, index) => (
                            <MenuItem
                              value={grade}
                              key={Math.random() + "-" + index}
                            >
                              {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mt: 0.5,
                      border: 1,
                      p: 1.2,
                      borderColor: "primary.main",
                    }}
                  >
                    <Grid item xs={12} lg={8}>
                      <FormControl sx={{minWidth: "100%"}}>
                        <InputLabel>Select Subject</InputLabel>
                        <Select
                          value={secondSubject4}
                          onChange={(e) => setSecondSubject4(e.target.value)}
                          fullWidth
                          label="Select Subject"
                        >
                          {subjects.map((subject, index) => (
                            <MenuItem
                              value={subject}
                              key={Math.random() + "-" + index}
                            >
                              {subject}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl sx={{minWidth: "100%"}}>
                        <InputLabel>Select Grade</InputLabel>
                        <Select
                          value={secondGrade4}
                          onChange={(e) => setSecondGrade4(e.target.value)}
                          fullWidth
                          label="Select Grade"
                        >
                          {grades.map((grade, index) => (
                            <MenuItem
                              value={grade}
                              key={Math.random() + "-" + index}
                            >
                              {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*/End of Row Two*/}

              {/*Start of Row Three*/}
              <Grid container spacing={2} sx={{mt: 0.3}}>
                <Grid item xs={12} lg={6}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mt: 0.5,
                      border: 1,
                      p: 1.2,
                      borderColor: "primary.main",
                    }}
                  >
                    <Grid item xs={12} lg={8}>
                      <FormControl sx={{minWidth: "100%"}}>
                        <InputLabel>Select Subject</InputLabel>
                        <Select
                          value={secondSubject5}
                          onChange={(e) => setSecondSubject5(e.target.value)}
                          fullWidth
                          label="Select Subject"
                        >
                          {subjects.map((subject, index) => (
                            <MenuItem
                              value={subject}
                              key={Math.random() + "-" + index}
                            >
                              {subject}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl sx={{minWidth: "100%"}}>
                        <InputLabel>Select Grade</InputLabel>
                        <Select
                          value={secondGrade5}
                          onChange={(e) => setSecondGrade5(e.target.value)}
                          fullWidth
                          label="Select Grade"
                        >
                          {grades.map((grade, index) => (
                            <MenuItem
                              value={grade}
                              key={Math.random() + "-" + index}
                            >
                              {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mt: 0.5,
                      border: 1,
                      p: 1.2,
                      borderColor: "primary.main",
                    }}
                  >
                    <Grid item xs={12} lg={8}>
                      <FormControl sx={{minWidth: "100%"}}>
                        <InputLabel>Select Subject</InputLabel>
                        <Select
                          value={secondSubject6}
                          onChange={(e) => setSecondSubject6(e.target.value)}
                          fullWidth
                          label="Select Subject"
                        >
                          {subjects.map((subject, index) => (
                            <MenuItem
                              value={subject}
                              key={Math.random() + "-" + index}
                            >
                              {subject}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl sx={{minWidth: "100%"}}>
                        <InputLabel>Select Grade</InputLabel>
                        <Select
                          value={secondGrade6}
                          onChange={(e) => setSecondGrade6(e.target.value)}
                          fullWidth
                          label="Select Grade"
                        >
                          {grades.map((grade, index) => (
                            <MenuItem
                              value={grade}
                              key={Math.random() + "-" + index}
                            >
                              {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*/End of Row Three*/}

              {/*Start of Row Four*/}
              <Grid container spacing={2} sx={{mt: 0.3}}>
                <Grid item xs={12} lg={6}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mt: 0.5,
                      border: 1,
                      p: 1.2,
                      borderColor: "primary.main",
                    }}
                  >
                    <Grid item xs={12} lg={8}>
                      <FormControl sx={{minWidth: "100%"}}>
                        <InputLabel>Select Subject</InputLabel>
                        <Select
                          value={secondSubject7}
                          onChange={(e) => setSecondSubject7(e.target.value)}
                          fullWidth
                          label="Select Subject"
                        >
                          {subjects.map((subject, index) => (
                            <MenuItem
                              value={subject}
                              key={Math.random() + "-" + index}
                            >
                              {subject}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl sx={{minWidth: "100%"}}>
                        <InputLabel>Select Grade</InputLabel>
                        <Select
                          value={secondGrade7}
                          onChange={(e) => setSecondGrade7(e.target.value)}
                          fullWidth
                          label="Select Grade"
                        >
                          {grades.map((grade, index) => (
                            <MenuItem
                              value={grade}
                              key={Math.random() + "-" + index}
                            >
                              {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mt: 0.5,
                      border: 1,
                      p: 1.2,
                      borderColor: "primary.main",
                    }}
                  >
                    <Grid item xs={12} lg={8}>
                      <FormControl sx={{minWidth: "100%"}}>
                        <InputLabel>Select Subject</InputLabel>
                        <Select
                          value={secondSubject8}
                          onChange={(e) => setSecondSubject8(e.target.value)}
                          fullWidth
                          label="Select Subject"
                        >
                          {subjects.map((subject, index) => (
                            <MenuItem
                              value={subject}
                              key={Math.random() + "-" + index}
                            >
                              {subject}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl sx={{minWidth: "100%"}}>
                        <InputLabel>Select Grade</InputLabel>
                        <Select
                          value={secondGrade8}
                          onChange={(e) => setSecondGrade8(e.target.value)}
                          fullWidth
                          label="Select Grade"
                        >
                          {grades.map((grade, index) => (
                            <MenuItem
                              value={grade}
                              key={Math.random() + "-" + index}
                            >
                              {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*/End of Row Four*/}

              {/*Start of Row Five*/}
              <Grid container spacing={2} sx={{mt: 0.3}}>
                <Grid item xs={12} lg={6}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      mt: 0.5,
                      border: 1,
                      p: 1.2,
                      borderColor: "primary.main",
                    }}
                  >
                    <Grid item xs={12} lg={8}>
                      <FormControl sx={{minWidth: "100%"}}>
                        <InputLabel>Select Subject</InputLabel>
                        <Select
                          value={secondSubject9}
                          onChange={(e) => setSecondSubject9(e.target.value)}
                          fullWidth
                          label="Select Subject"
                        >
                          {subjects.map((subject, index) => (
                            <MenuItem
                              value={subject}
                              key={Math.random() + "-" + index}
                            >
                              {subject}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl sx={{minWidth: "100%"}}>
                        <InputLabel>Select Grade</InputLabel>
                        <Select
                          value={secondGrade9}
                          onChange={(e) => setSecondGrade9(e.target.value)}
                          fullWidth
                          label="Select Grade"
                        >
                          {grades.map((grade, index) => (
                            <MenuItem
                              value={grade}
                              key={Math.random() + "-" + index}
                            >
                              {grade}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*/End of Row One*/}
            </React.Fragment>
          )}

          <Typography variant="body1" className="fw-bold my-2">
            Upload Documents (attach copied of these documents)
          </Typography>
          <Grid container spacing={2}>
            {
              awaitingResult ? (
                awaitingResultUploads.map((row, index) => (
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    key={`${row.id}-${index}`}
                    sx={{width: "45%"}}
                  >
                    <Typography component={"div"} variant="body2" sx={{m: '10px 0'}}>
                      {row.name}
                    </Typography>
                    <label
                      htmlFor={`upload-image-${row.id}`}
                      style={{width: "100%", marginTop: "-13px"}}
                    >
                      <Button
                        variant="outlined"
                        component="span"
                        fullWidth
                        sx={{
                          py: 1.5,
                          mt: 2,
                          textTransform: "lowercase",
                          borderRadius: 2,
                          color: "#000",
                        }}
                      >
                        {getFileName(row) ? getFileName(row) : row.name}
                      </Button>
                      <input
                        id={`upload-image-${row.id}`}
                        hidden
                        accept="image/*,application/pdf"
                        type="file"
                        onChange={(e) => handleFilesUpload(e, row)}
                      />
                    </label>
                  </Grid>
                ))
              ) : ''
            }
            {
              !awaitingResult ? (
                completeResultUploads.map((row, index) => (
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    key={`${row.id}-${index}`}
                    sx={{width: "45%", m: '1rem 0'}}
                  >
                    <Typography component={"div"} variant="body2" sx={{m: '10px 0'}}>
                      {row.name}
                    </Typography>
                    <label
                      htmlFor={`upload-image-${row.id}`}
                      style={{width: "100%", marginTop: "-13px"}}
                    >
                      <Button
                        variant="outlined"
                        component="span"
                        fullWidth
                        sx={{
                          py: 1.5,
                          mt: 2,
                          textTransform: "lowercase",
                          borderRadius: 2,
                          color: "#000",
                        }}
                      >
                        {getFileName(row) ? getFileName(row) : row.name}
                      </Button>
                      <input
                        id={`upload-image-${row.id}`}
                        hidden
                        accept={
                          `
                        ${
                            row.id === 'transcript'
                              ?
                              'application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'
                              :
                              'image/*,application/pdf'
                          }`
                        }
                        type="file"
                        onChange={(e) => handleFilesUpload(e, row)}
                      />
                    </label>
                  </Grid>
                ))) : ''
            }
          </Grid>

          <Typography variant="body1" className="fw-bold mt-3">
            Attestation
          </Typography>
          <FormControlLabel
            sx={{mt: 0}}
            control={
              <Checkbox
                checked={attestation}
                onChange={(e) => setAttestation(e.target.checked)}
                inputProps={{"aria-label": "controlled_1"}}
              />
            }
            label="I hereby confess that the above information is correct to the best of my knowledge. If any of the information is proved to be false, I will be considered disqualified."
          />
        </CardContent>

        <CardActions>
          <Grid container spacing={2} className="px-2">
            <Grid item xs={6} lg={6}>
              <Button
                variant="outlined"
                color="tertiary"
                fullWidth
                onClick={onPreviousClick}
                sx={{
                  py: 1.5,
                  mt: 2,
                  textTransform: "uppercase",
                  borderRadius: 2,
                  color: "#000",
                }}
              >
                Previous
              </Button>
            </Grid>

            <Grid item xs={6} lg={6}>
              {attestation ? (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleCheckForm}
                  sx={{
                    py: 1.5,
                    mt: 2,
                    textTransform: "uppercase",
                    borderRadius: 2,
                    color: "#fff",
                  }}
                >
                  Proceed
                </Button>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </CardActions>
      </Card>


      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Attention"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {clickedProcess && (
              <p style={{textAlign: "center"}}>Processing...</p>
            )}

            {!clickedProcess && (
              <p>
                "Please, note that candidates are expected to pay a
                non-refundable processing fee of ₦5,000. Failure of payment is
                an automatic disqualification."{" "}
              </p>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          <Button variant="outlined" onClick={handleCloseDialog}>
            Cancel Application
          </Button>

          <Button variant="outlined" onClick={makePayment}>
            Proceed To Make Payment
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={loaderDialog}
        onClose={closeLoaderDialog}
        aria-labelledby="Processing event"
        aria-describedby="Processing application submission"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div

              style={{padding: '60px', textAlign: 'center'}}
            >

              <ColorRing
                visible={true}
                height="60"
                width="60"
                ariaLabel="color-ring-loading"
                wrapperStyle={{margin: '10px'}}
                wrapperClass="color-ring-wrapper"
                colors={['#00356B', '#00356B', '#00356B', '#00356B', '#00356B']}
              />

              <div>
                <Typography sx={{fontWeight: '600', color: '#00356B', fontSize: '16px', margin: '10px 0'}}>
                  Submitting Application ...
                </Typography>

                <Typography sx={{fontWeight: '400', color: '#00356B', fontSize: '12px', marginBottom: '10px'}}>
                  Your application is being submitted. Kindly wait for a moment
                </Typography>
              </div>

            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
