import React from 'react';
import {Button, Container, CssBaseline, Grid, Paper, Typography} from "@mui/material";
import {useNavigate} from 'react-router-dom';
import UpdateInfoModal from "../modal/updateInfoModal";

function DashboardItem({applicant}) {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const admissionStatusClass = applicant?.admission_status === 'Processing'
        ? 'amber'
        : applicant.admission_status === 'Admitted'
            ? 'green'
            : 'red'
    return (

        <React.Fragment>
            <CssBaseline/>
            <Container maxWidth="sm">
                <Paper className="d-flex justify-content-center gap-5 flex-column"
                       sx={{p: '50px', marginTop: '40px', backgroundColor: '#ffffff'}}>

                    <Typography variant="h4" style={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '16.94px',
                        color: '#2e5565'
                    }}>
                        ADMISSION STATUS: <span
                        className={`fw-bold ${admissionStatusClass}`}>{applicant?.admission_status}</span>
                    </Typography>

                    <Grid container spacing={2}>

                        <Grid item xs={6}>
                            <Button
                                variant='contained'
                                size='large'
                                sx={{color: '#ffffff'}}
                                onClick={handleOpen}
                            >
                                View My Application
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                size='large'
                                onClick={() => navigate('/application-summary')}
                            >
                                Print Slip
                            </Button>
                        </Grid>

                        <UpdateInfoModal applicant={applicant} closeDialog={handleClose} openModal={open}/>

                    </Grid>

                </Paper>
            </Container>
        </React.Fragment>
    );
}

export default DashboardItem;