import React, {useState} from "react";
import moment from "moment";
import NaijaStates from "naija-state-local-government";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {toast} from "react-toastify";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {useDispatch} from "react-redux";
import {api, baseUrl, getCurrentSession} from "../../../utils/http";
import {addPersonalInfo} from "../../../redux/reducers/features/applicant-data/personal-info-slice";

const ng_universities = require("ng_universities");

export default function PersonalInfo({onSubmit}) {
  const dispatch = useDispatch();

  const [academicSession, setAcademicSesion] = useState(""),
    [firstName, setFirstName] = React.useState(""),
    [lastName, setLastName] = React.useState(""),
    [middleName, setMiddleName] = React.useState(""),
    [phone, setPhone] = React.useState(""),
    [nationality, setNationality] = React.useState(""),
    [state, setState] = React.useState(""),
    [lg, setLg] = React.useState([]),
    [city, setCity] = React.useState(""),
    [email, setEmail] = React.useState(""),
    [password, setPassword] = React.useState(""),
    [confirmPassword, setConfirmPassword] = React.useState(""),
    [guardianName, setGuardianName] = React.useState(""),
    [guardianAddress, setGuardianAddress] = React.useState(""),
    [guardianPhone, setGuardianPhone] = React.useState(""),
    [allStates, setAllStates] = React.useState([]),
    [guardianRelationship, setGuardianRelationship] = React.useState(""),
    [dob, setDob] = React.useState(null),
    [showPassword, setShowPassword] = React.useState(false),
    [showConfirmPassword, setShowConfirmPassword] = React.useState(false),
    [gender, setGender] = React.useState(""),
    [isSubmitting, setIsSubmitting] = React.useState(false),
    [errors, setErrors] = React.useState({});
  const [selectedState, setSelectedState] = useState("");
  const [lgas, setLgas] = useState([]);

  const handlePasswordIconClick = () => setShowPassword(!showPassword);
  const handleConfirmPasswordIconClick = () =>
    setShowConfirmPassword(!showConfirmPassword);

  console.log(" states", selectedState);
  console.log(" lga", lg);
  const handleOnProceed = async () => {
    setIsSubmitting(true);
    if (
      firstName &&
      lastName &&
      phone &&
      nationality &&
      selectedState &&
      lg &&
      city &&
      email &&
      password &&
      password === confirmPassword &&
      guardianName &&
      guardianPhone &&
      guardianAddress &&
      guardianRelationship &&
      dob &&
      gender
    ) {
      console.log(dob, "date ===>");
      let data = {
        first_name: firstName,
        last_name: lastName,
        middle_name: middleName,
        phone: phone,
        nationality: nationality,
        state: selectedState,
        entry_mode: 'Direct Entry',
        lg: lg,
        city: city,
        email: email,
        dob: dob,
        gender: gender,
        password: password,
        guardian: {
          name: guardianName,
          phone: guardianPhone,
          address: guardianAddress,
          relationship: guardianRelationship,
        },
      };

      dispatch(
        addPersonalInfo({
          first_name: data.first_name,
          last_name: data.last_name,
          middle_name: data.middle_name,
          phone: data.phone,
          nationality: data.nationality,
          state: data.state,
          lg: data.lg,
          city: data.city,
          email: data.email,
          gender: data.gender,
          dob: moment(dob).format("YYYY-MM-DD"),
          guardian_phone: data.guardian.phone,
          guardian_name: data.guardian.name,
          guardian_relationship: data.guardian.relationship,
          guardian_address: data.guardian.address,
        })
      );
      onSubmit(data);
    } else {
      toast.error("ensure all required fields are filled", {
        position: toast.POSITION.TOP_RIGHT,
      });
      if (password !== confirmPassword) {
        toast.error("password did not match", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      if (dob === null || dob === "") {
        toast.error("enter date of birth", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  const handleStateSelect = (event) => {
    const selectedState = event.target.value;
    setSelectedState(selectedState);

    const stateData = allStates.find((state) => state.state === selectedState);

    // Update the LGAs based on the selected state
    setLgas(stateData ? stateData.lgas : []);
  };
  React.useEffect(() => {
    const states = NaijaStates.all();
    setAllStates(states);
  }, []);

  React.useEffect(() => {
    (async function () {
      try {

        const url = baseUrl + '/api/department'

        const res = await api.get(url)
        const departments = res.data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
    (async function () {
      try {
        const url = baseUrl + '/api/faculty'
        const res = await api.get(url)
        const faculties = res.data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, []);

  React.useEffect(() => {
    (async function () {
      if (process.env.NODE_ENV === "development") {
        const session = await getCurrentSession(
          baseUrl + "/api/session/current-session"
        );

        const sessionData = `${session?.data.start_year}/${session?.data.end_year}`;
        setAcademicSesion(sessionData);
      } else if (process.env.NODE_ENV === "production") {
        const session = await getCurrentSession(
          baseUrl + "/api/session/current-session"
        );

        const sessionData = `${session?.data.start_year}/${session?.data.end_year}`;
        setAcademicSesion(sessionData);
      }
    })();
  }, []);

  React.useEffect(() => {
    const validateData = async () => {
      let fieldList = {
        first_name: firstName,
        last_name: lastName,
        middle_name: middleName,
        phone: phone,
        nationality: nationality,
        state: state,
        lg: lg,
        city: city,
        email: email,
        dob: dob,
        gender: gender,
        password: password,
        guardian_name: guardianName,
        guardian_phone: guardianPhone,
        guardian_address: guardianAddress,
        guardian_relationship: guardianRelationship,
        academic_session: academicSession,
      };

      for (const [key, value] of Object.entries(fieldList)) {
        if (key === dob) {
          errors[key] = null;
        } else {
          errors[key] = "";
        }

        await setErrors(errors);
      }

      for (const [key, value] of Object.entries(fieldList)) {
        if (value === "" || value === null) {
          errors[key] = `${key} is required`;
          await setErrors(errors);
        }
      }
    };
    validateData();
  }, [
    errors,
    city,
    dob,
    email,
    firstName,
    gender,
    guardianAddress,
    guardianName,
    guardianPhone,
    guardianRelationship,
    lastName,
    lg,
    middleName,
    nationality,
    password,
    phone,
    state,
  ]);

  return (
    <Card sx={{p: {xs: 0, lg: 3}, mt: {xs: 1.8, lg: 3}}}>
      <CardContent>
        <Typography
          variant="h6"
          component="h6"
          color="#00356B"
          sx={{fontStyle: 'italic', margin: '1rem 0', fontSize: '25px', lineHeight: '30px', fontWeight: '500'}}
        >
          Personal Details
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
              <TextField
                label="First Name"
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                error={!!(isSubmitting && errors && errors.first_name)}
                helperText={isSubmitting && errors && errors.first_name}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
              <TextField
                label="Last Name"
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                error={!!(isSubmitting && errors && errors.last_name)}
                helperText={isSubmitting && errors && errors.last_name}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
              <TextField
                label="Middle Name (Optional)"
                variant="outlined"
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
              <TextField
                label="Phone Number"
                variant="outlined"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                error={!!(isSubmitting && errors && errors.phone)}
                helperText={isSubmitting && errors && errors.phone}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <FormControl
              sx={{mt: 2, minWith: "100%"}}
              variant="outlined"
              fullWidth
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    format="DD-MM-YYYY"
                    label="Select Date of Birth"
                    value={dob}
                    onChange={(newValue) => setDob(newValue)}
                    slotProps={{textField: {fullWidth: true}}}
                    error={!!(isSubmitting && errors && errors.dob)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
              <TextField
                label="Email"
                variant="outlined"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!(isSubmitting && errors && errors.email)}
                helperText={isSubmitting && errors && errors.email}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handlePasswordIconClick}
                      onMouseDown={handlePasswordIconClick}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffIcon/>
                      ) : (
                        <VisibilityIcon/>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-confirm-password">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirm-password"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleConfirmPasswordIconClick}
                      onMouseDown={handleConfirmPasswordIconClick}
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <VisibilityOffIcon/>
                      ) : (
                        <VisibilityIcon/>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
              <TextField
                label="Guardian name"
                variant="outlined"
                placeholder="Enter Guardian Name"
                value={guardianName}
                onChange={(e) => setGuardianName(e.target.value)}
                error={!!(isSubmitting && errors && errors.guardian_name)}
                helperText={isSubmitting && errors && errors.guardian_name}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
              <TextField
                label="Guardian Phone Number"
                variant="outlined"
                placeholder="Enter Guardian Phone Number"
                value={guardianPhone}
                onChange={(e) => setGuardianPhone(e.target.value)}
                error={!!(isSubmitting && errors && errors.guardian_phone)}
                helperText={isSubmitting && errors && errors.guardian_phone}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
              <TextField
                label="Guardian Address"
                variant="outlined"
                placeholder="Enter Guardian Address"
                value={guardianAddress}
                onChange={(e) => setGuardianAddress(e.target.value)}
                error={!!(isSubmitting && errors && errors.guardian_address)}
                helperText={isSubmitting && errors && errors.guardian_address}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
              <TextField
                label="Guardian Relationship"
                placeholder="brother, father, uncle, aunt"
                variant="outlined"
                value={guardianRelationship}
                onChange={(e) => setGuardianRelationship(e.target.value)}
                error={
                  !!(isSubmitting && errors && errors.guardian_relationship)
                }
                helperText={
                  isSubmitting && errors && errors.guardian_relationship
                }
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
              <TextField
                label="Nationality"
                placeholder="e.g Nigerian"
                variant="outlined"
                value={nationality}
                onChange={(e) => setNationality(e.target.value)}
                error={!!(isSubmitting && errors && errors.nationality)}
                helperText={isSubmitting && errors && errors.nationality}
              />
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} lg={6}>
            <FormControl sx={{ mt: 3 }} variant="outlined" fullWidth>
              <TextField
                label="State of Origin"
                placeholder="Anambra, Niger, Sokoto, Lagos"
                variant="outlined"
                value={state}
                onChange={(e) => setState(e.target.value)}
                error={!!(isSubmitting && errors && errors.state)}
                helperText={isSubmitting && errors && errors.state}
              />
            </FormControl>
          </Grid> */}

          <Grid item xs={12} lg={6} spacing={2} mt={3}>
            <FormControl sx={{minWidth: "100%"}}>
              <InputLabel>Select State of Origin</InputLabel>
              <Select
                onChange={handleStateSelect}
                fullWidth
                label="Select State of Origin"
                value={selectedState}
              >
                {allStates?.map((stateData, index) => (
                  <MenuItem value={stateData.state} key={index}>
                    {stateData.state}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <FormControl sx={{marginTop: "16px", minWidth: "100%"}}>
              <InputLabel>Select LGA</InputLabel>
              <Select
                value={lg}
                onChange={(e) => setLg(e.target.value)}
                fullWidth
                label="Select LGA"
              >
                {/* <MenuItem value="" defaultChecked>"Select Local Govt"</MenuItem> */}
                {lgas?.map((lga, index) => (
                  <MenuItem value={lga} key={index}>
                    {lga}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
              <TextField
                label="City"
                placeholder="Onitsa, Kano, Suleja, Sabon Gida"
                variant="outlined"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                error={!!(isSubmitting && errors && errors.city)}
                helperText={isSubmitting && errors && errors.city}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container spacing={2} className="px-2">
          <Grid item xs={6}>
            <FormControl>
              <FormLabel>Gender</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={gender}
                defaultValue={"male"}
                onChange={(e) => setGender(e.target.value)}
              >
                <FormControlLabel
                  value="male"
                  control={<Radio/>}
                  label="Male"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio/>}
                  label="Female"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleOnProceed}
              sx={{
                py: 1.5,
                mt: 2,
                textTransform: "uppercase",
                borderRadius: 2,
                color: "#fff",
              }}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
