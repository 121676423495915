import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import logo from "../../../assets/img/MU LOGO.png";
import "../../../styles/dashbaord/dashboard-styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import MyApplication from "./MyApplication";
import { Button, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import axios from "axios";
import { baseUrl } from "../../../utils/http";
import { loginSuccess } from "../../../redux/reducers/features/auth/auth-slice";

const drawerWidth = 300;
const drawerHeight = 0;

export default function DrawerComponent({ applicant }) {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [url, setUrl] = React.useState(new URL(window.location.href).pathname);
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const applicantId = auth.loginSuccess?.user_id;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = async () => {
    setAnchorEl(null);
    const logout = await axios({
      url: `${baseUrl}/api/applicant/logout`,
      data: { applicantId },
      headers: { "Content-Type": "application/json" },
      method: "POST",
    });

    if (logout.data) {
      localStorage.removeItem("_token");
      localStorage.removeItem("applicantId");
      dispatch(loginSuccess({ loginSuccess: {}, success: false }));
      navigate("/");
    } else {
      console.error("Error in logout");
    }
  };

  const handleItemClick = (item) => {
    navigate(item.url);
  };

  React.useEffect(() => {
    setUrl(location.pathname);
  }, [location.pathname]);

  const dashboardComponents = [
    {
      path: "/dashboard",
      component: <Dashboard applicant={applicant} />,
    },
    {
      path: "/dashboard/my-application",
      component: <MyApplication applicant={applicant} />,
    },
  ];

  const display = dashboardComponents.find((cmp) => cmp.path === url);

  const photo = applicant.documents?.find(
    (item) => item.upload_type === "passport_photo"
  );
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: `calc(15% - ${drawerHeight}px)`,
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <Toolbar className="d-flex justify-content-end bg-white">
          <Typography
            variant="h6"
            noWrap
            component="div"
            className="d-flex justify-content-between gap-3"
          >
            <div>
              <NotificationsOutlinedIcon className="mt-3" />
            </div>

            <div>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <img
                  src={photo?.file_url}
                  alt="Profile Photo"
                  height={40}
                  width={40}
                  className="rounded-circle me-2"
                />
                {applicant.last_name} {applicant.first_name}
                <KeyboardArrowDownIcon />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {/*<MenuItem onClick={handleClose}>Profile</MenuItem>*/}
                {/*<MenuItem onClick={handleClose}>My account</MenuItem>*/}
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box className="d-flex gap-2 justify-content-center align-items-center my-3">
          <img
            src={logo}
            alt="logo"
            height="60"
            width="60"
            className="text-center"
          />
          <div>
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "20.8px",
                color: "#00356B",
                display: "block",
              }}
            >
              MU Admissions
            </span>
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "18.2px",
                color: "#2e5565",
              }}
            >
              Applicant PORTAL
            </span>
          </div>
        </Box>

        <Divider />
        <List>
          {[
            {
              name: "Dashboard",
              url: "/dashboard",
              id: 1,
              icon: <DashboardIcon />,
            },
            // {
            //     name: 'My Application',
            //     url: '/dashboard/my-application',
            //     id: 2,
            //     icon: (<AccountCircleIcon/>),
            //
            // }
          ].map((text, index) => (
            <ListItem
              key={text.id}
              disablePadding
              className={url === text.url ? "active" : ""}
              onClick={() => handleItemClick(text)}
            >
              <ListItemButton>
                <ListItemIcon className={url === text.url ? "text-white" : ""}>
                  {text.icon}
                </ListItemIcon>
                <ListItemText primary={text.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Box>{display.component}</Box>
      </Box>
    </Box>
  );
}
