import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    firstSitting: {
        exam_type: '',
        subject_grade: [],
    },
    secondSitting: {
        exam_type: '',
        subject_grade: [],
    },
    uploads: []
};

const ssceSlice = createSlice({
    name: 'ssceSlice',
    initialState,
    reducers: {
        addUploads: (state, action) => {
            state.uploads = action.payload;
        },
        addFirstSitting: (state, action) => {
            state.firstSitting = action.payload;
        },
        addSecondSitting: (state, action) => {
            state.secondSitting = action.payload;
        }
    }
})


export const {addFirstSitting, addSecondSitting, addUploads} = ssceSlice.actions;
export default ssceSlice.reducer