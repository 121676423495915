import React from 'react';
import {
    Card,
    CardActions,
    CardContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import day from 'dayjs'
import {useDispatch} from 'react-redux';
import {addPersonalInfo} from '../../../redux/reducers/features/applicant-data/personal-info-slice';


function PersonalInfo({applicant}) {

    const dispatch = useDispatch();

    const [firstName, setFirstName] = React.useState(applicant?.first_name),
        [lastName, setLastName] = React.useState(applicant?.last_name),
        [middleName, setMiddleName] = React.useState(applicant?.middle_name),
        [phone, setPhone] = React.useState(applicant?.phone),
        [nationality, setNationality] = React.useState(applicant?.nationality),
        [state, setState] = React.useState(applicant?.state),
        [lg, setLg] = React.useState(applicant?.lg),
        [city, setCity] = React.useState(applicant?.city),
        [guardianName, setGuardianName] = React.useState(applicant?.guardian?.name),
        [guardianAddress, setGuardianAddress] = React.useState(applicant?.guardian?.address),
        [guardianPhone, setGuardianPhone] = React.useState(applicant?.guardian?.phone),
        [guardianRelationship, setGuardianRelationship] = React.useState(applicant?.guardian?.relationship),
        [dob, setDob] = React.useState(day(applicant?.dob)),
        [gender, setGender] = React.useState(applicant?.gender),
        [isSubmitting, setIsSubmitting] = React.useState(false),
        [errors, setErrors] = React.useState({});



    React.useEffect(() => {
        const payload = {
            first_name: firstName,
            last_name: lastName,
            middle_name: middleName,
            phone: phone,
            nationality: nationality,
            state: state,
            lg: lg,
            city: city,
            dob: dob.toISOString(),
            gender: gender,
            guardian_name: guardianName,
            guardian_phone: guardianPhone,
            guardian_address: guardianAddress,
            guardian_relationship: guardianRelationship,
        };


        dispatch(addPersonalInfo(payload))

    }, [applicant, city, dispatch, dob, firstName, gender, guardianAddress, guardianName, guardianPhone, guardianRelationship, lastName, lg, middleName, nationality, phone, state])

    return (
        <Card sx={{p: {xs: 0, lg: 3}, mt: {xs: 1.8, lg: 3}, width: '100%'}}>
            <CardContent>
                <Typography variant="h5" className="fw-bold">
                    Personal Details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
                            <TextField
                                label="First Name"
                                variant="outlined"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                error={!!(isSubmitting && errors && errors.first_name)}
                                helperText={isSubmitting && errors && errors.first_name}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
                            <TextField
                                label="Last Name"
                                variant="outlined"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                error={!!(isSubmitting && errors && errors.last_name)}
                                helperText={isSubmitting && errors && errors.last_name}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
                            <TextField
                                label="Middle Name (Optional)"
                                variant="outlined"
                                value={middleName}
                                onChange={(e) => setMiddleName(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
                            <TextField
                                label="Phone Number"
                                variant="outlined"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                error={!!(isSubmitting && errors && errors.phone)}
                                helperText={isSubmitting && errors && errors.phone}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <FormControl
                            sx={{mt: 2, minWith: "100%"}}
                            variant="outlined"
                            fullWidth
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                        format="DD-MM-YYYY"
                                        label="Select Date of Birth"
                                        value={dob}
                                        onChange={(newValue) => setDob(newValue)}
                                        slotProps={{textField: {fullWidth: true}}}
                                        error={!!(isSubmitting && errors && errors.dob)}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
                            <TextField
                                label="Guardian name"
                                variant="outlined"
                                placeholder="Enter Guardian Name"
                                value={guardianName}
                                onChange={(e) => setGuardianName(e.target.value)}
                                error={!!(isSubmitting && errors && errors.guardian_name)}
                                helperText={isSubmitting && errors && errors.guardian_name}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
                            <TextField
                                label="Guardian Phone Number"
                                variant="outlined"
                                placeholder="Enter Guardian Phone Number"
                                value={guardianPhone}
                                onChange={(e) => setGuardianPhone(e.target.value)}
                                error={!!(isSubmitting && errors && errors.guardian_phone)}
                                helperText={isSubmitting && errors && errors.guardian_phone}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
                            <TextField
                                label="Guardian Address"
                                variant="outlined"
                                placeholder="Enter Guardian Address"
                                value={guardianAddress}
                                onChange={(e) => setGuardianAddress(e.target.value)}
                                error={!!(isSubmitting && errors && errors.guardian_address)}
                                helperText={isSubmitting && errors && errors.guardian_address}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
                            <TextField
                                label="Guardian Relationship"
                                placeholder="brother, father, uncle, aunt"
                                variant="outlined"
                                value={guardianRelationship}
                                onChange={(e) => setGuardianRelationship(e.target.value)}
                                error={
                                    !!(isSubmitting && errors && errors.guardian_relationship)
                                }
                                helperText={
                                    isSubmitting && errors && errors.guardian_relationship
                                }
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
                            <TextField
                                label="Nationality"
                                placeholder="e.g Nigerian"
                                variant="outlined"
                                value={nationality}
                                onChange={(e) => setNationality(e.target.value)}
                                error={!!(isSubmitting && errors && errors.nationality)}
                                helperText={isSubmitting && errors && errors.nationality}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
                            <TextField
                                label="State of Origin"
                                placeholder="Anambra, Niger, Sokoto, Lagos"
                                variant="outlined"
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                error={!!(isSubmitting && errors && errors.state)}
                                helperText={isSubmitting && errors && errors.state}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
                            <TextField
                                label="Local Government"
                                placeholder="e.g Akoko-North, Bida"
                                variant="outlined"
                                value={lg}
                                onChange={(e) => setLg(e.target.value)}
                                error={!!(isSubmitting && errors && errors.lg)}
                                helperText={isSubmitting && errors && errors.lg}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormControl sx={{mt: 3}} variant="outlined" fullWidth>
                            <TextField
                                label="City"
                                placeholder="Onitsa, Kano, Suleja, Sabon Gida"
                                variant="outlined"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                error={!!(isSubmitting && errors && errors.city)}
                                helperText={isSubmitting && errors && errors.city}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Grid container spacing={2} className="px-2">
                    <Grid item xs={6}>
                        <FormControl>
                            <FormLabel>Gender</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={gender}
                                defaultValue={"male"}
                                onChange={(e) => setGender(e.target.value)}
                            >
                                <FormControlLabel
                                    value="male"
                                    control={<Radio/>}
                                    label="Male"
                                />
                                <FormControlLabel
                                    value="female"
                                    control={<Radio/>}
                                    label="Female"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
}

export default PersonalInfo;