import {Box, Typography} from "@mui/material";
import React, {Fragment} from 'react';
import PersonalInfo from "../components/direct-entry/personal-info";
import JambInformation from "../components/direct-entry/jamb-info";
import SsceResultAndFiles from "../components/direct-entry/ssce-info";


function DirectEntryApplication({
                                  directEntryPage,
                                  setDirectEntryPage,
                                  directEntryApplicationData,
                                  setDirectEntryApplicationData
                                }) {


  const handlePersonalDetailsSubmit = (data) => {
    let newObj = {...directEntryApplicationData, ...data};
    setDirectEntryApplicationData(newObj);
    setDirectEntryPage(2);
  };


  const handleJambDataSubmit = (data) => {
    let newObj = {...directEntryApplicationData, ...data};
    setDirectEntryApplicationData(newObj);
    setDirectEntryPage(3);
  };


  return (
    <Fragment>

      <Typography variant="h5" component="h5">
        Direct Entry Admission Entry Form
      </Typography>


      <Box sx={{
        margin: '2rem 0',
        backgroundColor: 'FCFCFC',
        border: 'solid 1px #00356B',
        p: '20px',
        borderRadius: '10px'
      }}>

        <Typography variant='h6' component='h6' color="#B5861E">
          Important Information
        </Typography>

        <Typography
          variant="h6"
          component="h6"
          color="#000000"
          sx={{marginTop: '1rem', fontSize: '14px', fontFamily: 'Inter', lineHeight: '21px', fontWeight: '400'}}
        >
          MADUKA UNIVERSITY announces Direct Entry admission for 2024/2025 academic session!! Only candidates who who
          posses advanced qualifications such as A-Level results, OND, NCE or their equivalent are eligible to apply.
          Admission Form fee of ₦5,000 (Five thousand Naira, only). After filling out the online application, continue
          to make payment, using PAYSTACK. <br/>
          <span className="fw-bold mt-5">NOTE: Forms received after deadline will not be processed.</span>
        </Typography>

      </Box>

      <Typography
        variant="h6"
        component="h6"
        color="#00356B"
        sx={{fontStyle: 'italic', margin: '1rem 0', fontSize: '25px', lineHeight: '30px', fontWeight: '400'}}
      >
        Fill out with accurate and valid information. All entries must be in block letters.
      </Typography>


      <Box sx={{display: directEntryPage === 1 ? "block" : "none"}}>
        <PersonalInfo onSubmit={(data) => handlePersonalDetailsSubmit(data)}/>
      </Box>

      <Box sx={{display: directEntryPage === 2 ? "block" : "none"}}>
        <JambInformation
          onForwardClick={(data) => handleJambDataSubmit(data)}
          onPreviousClick={() => directEntryPage(1)}
        />
      </Box>

      <Box sx={{display: directEntryPage === 3 ? "block" : "none"}}>
        <SsceResultAndFiles
          formInputs={directEntryApplicationData}
          onPreviousClick={() => directEntryPage(2)}
        />
      </Box>
    </Fragment>
  )
}

export default DirectEntryApplication;
