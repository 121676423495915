import {Box, Typography} from "@mui/material";
import React, {Fragment} from 'react';
import PersonalInfo from "../components/ijmb-entry/personal-info";
import JambInformation from "../components/ijmb-entry/jamb-info";
import SsceResultAndFiles from "../components/ijmb-entry/ssce-info";


function IjmbApplication({
                           ijmbPage,
                           setIjmbPage,
                           ijmbApplicationData,
                           setIjmbApplicationData
                         }) {


  const handlePersonalDetailsSubmit = (data) => {
    let newObj = {...ijmbApplicationData, ...data};
    setIjmbApplicationData(newObj);
    setIjmbPage(2);
  };


  const handleJambDataSubmit = (data) => {
    let newObj = {...ijmbApplicationData, ...data};
    setIjmbApplicationData(newObj);
    setIjmbPage(3);
  };


  return (
    <Fragment>

      <Typography variant="h5" component="h5">
        IJMB Admission Entry Form
      </Typography>


      <Box sx={{
        margin: '2rem 0',
        backgroundColor: 'FCFCFC',
        border: 'solid 1px #00356B',
        p: '20px',
        borderRadius: '10px'
      }}>

        <Typography variant='h6' component='h6' color="#B5861E">
          Important Information
        </Typography>


        <Typography
          variant="h6"
          component="h6"
          color="#000000"
          sx={{marginTop: '1rem', fontSize: '14px', fontFamily: 'Inter', lineHeight: '21px', fontWeight: '400'}}
        >
          This is for Candidates who have completed their secondary school education and must have obtained a minimum of
          five credits in relevant subjects, including English Language and Mathematics. Admission Form fee is N5000
          (Five thousand Naira only). After filling out the online application, continue to make payment through
          PAYSTACK.
          Forms received after the deadline will not be processed.
        </Typography>

      </Box>

      <Typography
        variant="h6"
        component="h6"
        color="#00356B"
        sx={{fontStyle: 'italic', margin: '1rem 0', fontSize: '25px', lineHeight: '30px', fontWeight: '400'}}
      >
        Fill out with accurate and valid information. All entries must be in block letters.
      </Typography>


      <Box sx={{display: ijmbPage === 1 ? "block" : "none"}}>
        <PersonalInfo onSubmit={(data) => handlePersonalDetailsSubmit(data)}/>
      </Box>

      <Box sx={{display: ijmbPage === 2 ? "block" : "none"}}>
        <JambInformation
          onForwardClick={(data) => handleJambDataSubmit(data)}
          onPreviousClick={() => setIjmbPage(1)}
        />
      </Box>

      <Box sx={{display: ijmbPage === 3 ? "block" : "none"}}>
        <SsceResultAndFiles
          formInputs={ijmbApplicationData}
          onPreviousClick={() => setIjmbPage(2)}
        />
      </Box>
    </Fragment>
  )
}

export default IjmbApplication;
