import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loginSuccess: {
        success: false
    }

};

const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            state.loginSuccess = action.payload.loginSuccess
            state.loginSuccess.success = action.payload.success

        }
    }
})


export const {login, loginSuccess} = authSlice.actions;
export default authSlice.reducer