import * as React from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import {useDispatch} from 'react-redux';
import axios from "axios";
import {
    addFirstSitting,
    addSecondSitting,
    addUploads
} from "../../../redux/reducers/features/applicant-data/ssce-info-slice";
import {getFileUploads} from '../../../utils/getFileUploads'
import {baseUrl} from '../../../utils/http';
import '../../../styles/dashbaord/dashboard-styles.css'
import Modal from "@mui/material/Modal";


const examBody = ["WAEC", "NECO", "NABTEB"];

const subjects = [
        "COMMERCE",
        "FINANCIAL ACCOUNTING",
        "CHRISTIAN RELIGIOUS STUDIES",
        "ECONOMICS",
        "GEOGRAPHY",
        "GOVERNMENT",
        "ISLAMIC STUDIES",
        "LITERATURE IN ENGLISH",
        "CIVIC EDUCATION",
        "ENGLISH LANGUAGE",
        "HAUSA",
        "IGBO",
        "YORUBA",
        "FURTHER MATHEMATICS",
        "GENERAL MATHEMATICS",
        "AGRICULTURAL SCIENCE",
        "BIOLOGY",
        "CHEMISTRY",
        "PHYSICS",
        "French",
        "Fishery",
        "Computer Studies"
    ],
    grades = ["A1", "B2", "B3", "C4", "C5", "C6", "D7", "E8", "F9"],
    uploads = [
        {id: "utme_slip", name: "JAMB UTME Exam Result"},
        {id: "bank_slip", name: "Bank Slip Proof of Payment"},
        {
            id: "sse_result",
            name: "Entry Qualification Certificate (WASSCE, NECO, NABTEB)",
        },
        {id: "passport_photo", name: "Recent Passport Sized Photo"},
        {id: "lg_origin", name: "Local Govt Identification Letter"},
        {id: "stamped_envelop", name: "Self Stamped Envelop"},
        {
            id: "nin_slip",
            name: "Scanned National Identification Number Slip/Card",
        },
        {id: "birth_cert", name: "Birth Certificate"},
    ];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: '60%',
    height: '80%',
    p: 4,
};


const SsceInfo = React.forwardRef(({applicant}, ref) => {
    const dispatch = useDispatch();
    const [fileUrl, setFileUrl] = React.useState("");
    const [fileName, setFileName] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const handleOpen = (row, documents) => {
        setOpen(true)
        const file = documents.find((item) => item.upload_type === row.id);
        if (file) {
            setFileUrl(file.file_url);
            setFileName(row.name);
        }
    };
    const handleClose = () => setOpen(false);

    const [examType1, setExamType1] = React.useState(applicant?.exam_first_sitting.exam_type),
        [firstSubject1, setFirstSubject1] = React.useState("ENGLISH LANGUAGE"),
        [firstSubject2, setFirstSubject2] = React.useState("GENERAL MATHEMATICS"),
        [firstSubject3, setFirstSubject3] = React.useState(applicant?.exam_first_sitting.subject_grade[2].subject),
        [firstSubject4, setFirstSubject4] = React.useState(applicant?.exam_first_sitting.subject_grade[3].subject),
        [firstSubject5, setFirstSubject5] = React.useState(applicant?.exam_first_sitting.subject_grade[4].subject),
        [firstSubject6, setFirstSubject6] = React.useState(applicant?.exam_first_sitting.subject_grade[5].subject),
        [firstSubject7, setFirstSubject7] = React.useState(applicant?.exam_first_sitting.subject_grade[6].subject),
        [firstSubject8, setFirstSubject8] = React.useState(applicant?.exam_first_sitting.subject_grade[7].subject),
        [firstSubject9, setFirstSubject9] = React.useState(applicant?.exam_first_sitting.subject_grade[8].subject),
        [firstGrade1, setFirstGrade1] = React.useState(applicant?.exam_first_sitting.subject_grade[0].grade),
        [firstGrade2, setFirstGrade2] = React.useState(applicant?.exam_first_sitting.subject_grade[1].grade),
        [firstGrade3, setFirstGrade3] = React.useState(applicant?.exam_first_sitting.subject_grade[2].grade),
        [firstGrade4, setFirstGrade4] = React.useState(applicant?.exam_first_sitting.subject_grade[3].grade),
        [firstGrade5, setFirstGrade5] = React.useState(applicant?.exam_first_sitting.subject_grade[4].grade),
        [firstGrade6, setFirstGrade6] = React.useState(applicant?.exam_first_sitting.subject_grade[5].grade),
        [firstGrade7, setFirstGrade7] = React.useState(applicant?.exam_first_sitting.subject_grade[6].grade),
        [firstGrade8, setFirstGrade8] = React.useState(applicant?.exam_first_sitting.subject_grade[7].grade),
        [firstGrade9, setFirstGrade9] = React.useState(applicant?.exam_first_sitting.subject_grade[8].grade),
        [examType2, setExamType2] = React.useState(applicant?.exam_second_sitting.exam_type),
        [secondSubject1, setSecondSubject1] = React.useState(applicant?.exam_second_sitting.subject_grade[0].subject),
        [secondSubject2, setSecondSubject2] = React.useState(applicant?.exam_second_sitting.subject_grade[1].subject),
        [secondSubject3, setSecondSubject3] = React.useState(applicant?.exam_second_sitting.subject_grade[2].subject),
        [secondSubject4, setSecondSubject4] = React.useState(applicant?.exam_second_sitting.subject_grade[3].subject),
        [secondSubject5, setSecondSubject5] = React.useState(applicant?.exam_second_sitting.subject_grade[4].subject),
        [secondSubject6, setSecondSubject6] = React.useState(applicant?.exam_second_sitting.subject_grade[5].subject),
        [secondSubject7, setSecondSubject7] = React.useState(applicant?.exam_second_sitting.subject_grade[6].subject),
        [secondSubject8, setSecondSubject8] = React.useState(applicant?.exam_second_sitting.subject_grade[7].subject),
        [secondSubject9, setSecondSubject9] = React.useState(applicant?.exam_second_sitting.subject_grade[8].subject),
        [secondGrade1, setSecondGrade1] = React.useState(applicant?.exam_second_sitting.subject_grade[0].grade),
        [secondGrade2, setSecondGrade2] = React.useState(applicant?.exam_second_sitting.subject_grade[1].grade),
        [secondGrade3, setSecondGrade3] = React.useState(applicant?.exam_second_sitting.subject_grade[2].grade),
        [secondGrade4, setSecondGrade4] = React.useState(applicant?.exam_second_sitting.subject_grade[3].grade),
        [secondGrade5, setSecondGrade5] = React.useState(applicant?.exam_second_sitting.subject_grade[4].grade),
        [secondGrade6, setSecondGrade6] = React.useState(applicant?.exam_second_sitting.subject_grade[5].grade),
        [secondGrade7, setSecondGrade7] = React.useState(applicant?.exam_second_sitting.subject_grade[6].grade),
        [secondGrade8, setSecondGrade8] = React.useState(applicant?.exam_second_sitting.subject_grade[7].grade),
        [secondGrade9, setSecondGrade9] = React.useState(applicant?.exam_second_sitting.subject_grade[8].grade),
        [showSecondSitting, setShowSecondSitting] = React.useState(false),
        [uploadList, setUploadList] = React.useState([]), //required
        [updateUploadedFile, setUpdatedUploadedFile] = React.useState([]);


    const handleSubmit = async () => {

        try {
            const token = JSON.parse(localStorage.getItem('_token'));
            if (updateUploadedFile.length > 0) {
                let uploadedDoc = [];

                for (let upload of updateUploadedFile) {
                    const formData = new FormData();
                    formData.append("file", upload.file);
                    formData.append("file_name", upload.oldFilePath);
                    let response = await axios({
                        url: `${baseUrl}/api/student-documents/file-update`,
                        data: formData,
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (response.data) {
                        let object = {
                            file_url: response.data?.url,
                            file_name: response.data?.filename,
                            file_size: response.data?.size,
                            upload_type: upload?.id,
                        };
                        uploadedDoc.push(object);
                    }

                }
                dispatch(addUploads(uploadedDoc));
            }
        } catch (e) {
            console.error('Error in Updating files<>', e)
        }

    };

    const handleFilesUpload = async (event, type) => {
        if (event.target.files) {
            let newList = uploadList.filter((item) => item.id !== type.id);
            setUploadList(newList);
            const doc = {id: type.id, file: event.target.files[0]};
            setUploadList((current) => [...current, doc]);
        }
    };


    const getFileName = (type) => {
        let file = uploadList.find((item) => item.id === type.id);
        if (file && file.file && file.file.name) return file.file.name;
        return null;
    };

    const getFile = (rows, oldFiles) => {
        const file = oldFiles.find(item => item.upload_type === rows.id);
        if (file) {
            setFileUrl(file.file_url)

        }

    }

    React.useEffect(() => {

        dispatch(addFirstSitting({
            exam_body: examType1,
            subject_grade: [
                {subject: firstSubject1, grade: firstGrade1},
                {subject: firstSubject2, grade: firstGrade2},
                {subject: firstSubject3, grade: firstGrade3},
                {subject: firstSubject4, grade: firstGrade4},
                {subject: firstSubject5, grade: firstGrade5},
                {subject: firstSubject6, grade: firstGrade6},
                {subject: firstSubject7, grade: firstGrade7},
                {subject: firstSubject8, grade: firstGrade8},
                {subject: firstSubject9, grade: firstGrade9},
            ]
        }))

        dispatch(addSecondSitting({
            exam_body: examType2,
            subject_grade: [
                {subject: secondSubject1, grade: secondGrade1},
                {subject: secondSubject2, grade: secondGrade2},
                {subject: secondSubject3, grade: secondGrade3},
                {subject: secondSubject4, grade: secondGrade4},
                {subject: secondSubject5, grade: secondGrade5},
                {subject: secondSubject6, grade: secondGrade6},
                {subject: secondSubject7, grade: secondGrade7},
                {subject: secondSubject8, grade: secondGrade8},
                {subject: secondSubject9, grade: secondGrade9},
            ]
        }))
        const newUploaded = getFileUploads(uploadList, applicant.documents)
        setUpdatedUploadedFile(newUploaded);

    }, [
        applicant,
        dispatch,
        examType1,
        examType2,
        firstGrade1,
        firstGrade2,
        firstGrade3,
        firstGrade4,
        firstGrade5,
        firstGrade6,
        firstGrade7,
        firstGrade8,
        firstGrade9,
        firstSubject1,
        firstSubject2,
        firstSubject3,
        firstSubject4,
        firstSubject5,
        firstSubject6,
        firstSubject7,
        firstSubject8,
        firstSubject9,
        secondGrade1,
        secondGrade2,
        secondGrade3,
        secondGrade4,
        secondGrade5,
        secondGrade6,
        secondGrade7,
        secondGrade8,
        secondGrade9,
        secondSubject1,
        secondSubject2,
        secondSubject3,
        secondSubject4,
        secondSubject5,
        secondSubject6,
        secondSubject7,
        secondSubject8,
        secondSubject9,
        uploadList
    ])

    React.useEffect(() => {
    }, [handleOpen]);

    return (
        <React.Fragment>
            <Card sx={{p: {xs: 0, lg: 3}, mt: {xs: 1.8, lg: 3}}}>
                <CardContent>
                    <Typography variant="h5" className="fw-bold">
                        Senior Secondary School Certificate Details
                    </Typography>

                    <Typography variant="body1" className="fw-bold mt-4">
                        Details of O'level (Not more than two sittings) SSCE RESULTS (First
                        Sitting)
                    </Typography>
                    <Grid container spacing={2} sx={{mt: 0.3}}>
                        <Grid item xs={12} lg={12}>
                            <Grid
                                container
                                spacing={1}
                                sx={{
                                    mt: 0.5,
                                    border: 1,
                                    p: 1.2,
                                    borderColor: "primary.main",
                                }}
                            >
                                <FormControl sx={{minWidth: "100%"}}>
                                    <InputLabel>Select Exam Type</InputLabel>
                                    <Select
                                        value={examType1}
                                        onChange={(e) => setExamType1(e.target.value)}
                                        fullWidth
                                        label="Select exam type"
                                    >
                                        {examBody.map((body, index) => (
                                            <MenuItem value={body} key={index}>
                                                {body}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid
                                container
                                spacing={1}
                                sx={{mt: 0.5, border: 1, p: 1.2, borderColor: "primary.main"}}
                            >
                                <Grid item xs={12} lg={8}>
                                    <FormControl sx={{minWidth: "100%"}}>
                                        <InputLabel>Select Subject</InputLabel>
                                        <Select
                                            value={firstSubject1}
                                            onChange={(e) => setFirstSubject1(e.target.value)}
                                            fullWidth
                                            label="Select Subject"
                                        >
                                            {subjects.map((subject, index) => (
                                                <MenuItem
                                                    value={subject}
                                                    key={Math.random() + "-" + index}
                                                >
                                                    {subject}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <FormControl sx={{minWidth: "100%"}}>
                                        <InputLabel>Select Grade</InputLabel>
                                        <Select
                                            value={firstGrade1}
                                            onChange={(e) => setFirstGrade1(e.target.value)}
                                            fullWidth
                                            label="Select Grade"
                                        >
                                            {grades.map((grade, index) => (
                                                <MenuItem
                                                    value={grade}
                                                    key={Math.random() + "-" + index}
                                                >
                                                    {grade}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid
                                container
                                spacing={1}
                                sx={{mt: 0.5, border: 1, p: 1.2, borderColor: "primary.main"}}
                            >
                                <Grid item xs={12} lg={8}>
                                    <FormControl sx={{minWidth: "100%"}}>
                                        <InputLabel>Select Subject</InputLabel>
                                        <Select
                                            value={firstSubject2}
                                            onChange={(e) => setFirstSubject2(e.target.value)}
                                            fullWidth
                                            label="Select Subject"
                                        >
                                            {subjects.map((subject, index) => (
                                                <MenuItem
                                                    value={subject}
                                                    key={Math.random() + "-" + index}
                                                >
                                                    {subject}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <FormControl sx={{minWidth: "100%"}}>
                                        <InputLabel>Select Grade</InputLabel>
                                        <Select
                                            value={firstGrade2}
                                            onChange={(e) => setFirstGrade2(e.target.value)}
                                            fullWidth
                                            label="Select Grade"
                                        >
                                            {grades.map((grade, index) => (
                                                <MenuItem
                                                    value={grade}
                                                    key={Math.random() + "-" + index}
                                                >
                                                    {grade}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*/End of Row One*/}

                    {/*Start of Row Two*/}
                    <Grid container spacing={2} sx={{mt: 0.3}}>
                        <Grid item xs={12} lg={6}>
                            <Grid
                                container
                                spacing={1}
                                sx={{mt: 0.5, border: 1, p: 1.2, borderColor: "primary.main"}}
                            >
                                <Grid item xs={12} lg={8}>
                                    <FormControl sx={{minWidth: "100%"}}>
                                        <InputLabel>Select Subject</InputLabel>
                                        <Select
                                            value={firstSubject3}
                                            onChange={(e) => setFirstSubject3(e.target.value)}
                                            fullWidth
                                            label="Select Subject"
                                        >
                                            {subjects.map((subject, index) => (
                                                <MenuItem
                                                    value={subject}
                                                    key={Math.random() + "-" + index}
                                                >
                                                    {subject}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <FormControl sx={{minWidth: "100%"}}>
                                        <InputLabel>Select Grade</InputLabel>
                                        <Select
                                            value={firstGrade3}
                                            onChange={(e) => setFirstGrade3(e.target.value)}
                                            fullWidth
                                            label="Select Grade"
                                        >
                                            {grades.map((grade, index) => (
                                                <MenuItem
                                                    value={grade}
                                                    key={Math.random() + "-" + index}
                                                >
                                                    {grade}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid
                                container
                                spacing={1}
                                sx={{mt: 0.5, border: 1, p: 1.2, borderColor: "primary.main"}}
                            >
                                <Grid item xs={12} lg={8}>
                                    <FormControl sx={{minWidth: "100%"}}>
                                        <InputLabel>Select Subject</InputLabel>
                                        <Select
                                            value={firstSubject4}
                                            onChange={(e) => setFirstSubject4(e.target.value)}
                                            fullWidth
                                            label="Select Subject"
                                        >
                                            {subjects.map((subject, index) => (
                                                <MenuItem
                                                    value={subject}
                                                    key={Math.random() + "-" + index}
                                                >
                                                    {subject}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <FormControl sx={{minWidth: "100%"}}>
                                        <InputLabel>Select Grade</InputLabel>
                                        <Select
                                            value={firstGrade4}
                                            onChange={(e) => setFirstGrade4(e.target.value)}
                                            fullWidth
                                            label="Select Grade"
                                        >
                                            {grades.map((grade, index) => (
                                                <MenuItem
                                                    value={grade}
                                                    key={Math.random() + "-" + index}
                                                >
                                                    {grade}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*/End of Row Two*/}

                    {/*Start of Row Three*/}
                    <Grid container spacing={2} sx={{mt: 0.3}}>
                        <Grid item xs={12} lg={6}>
                            <Grid
                                container
                                spacing={1}
                                sx={{mt: 0.5, border: 1, p: 1.2, borderColor: "primary.main"}}
                            >
                                <Grid item xs={12} lg={8}>
                                    <FormControl sx={{minWidth: "100%"}}>
                                        <InputLabel>Select Subject</InputLabel>
                                        <Select
                                            value={firstSubject5}
                                            onChange={(e) => setFirstSubject5(e.target.value)}
                                            fullWidth
                                            label="Select Subject"
                                        >
                                            {subjects.map((subject, index) => (
                                                <MenuItem
                                                    value={subject}
                                                    key={Math.random() + "-" + index}
                                                >
                                                    {subject}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <FormControl sx={{minWidth: "100%"}}>
                                        <InputLabel>Select Grade</InputLabel>
                                        <Select
                                            value={firstGrade5}
                                            onChange={(e) => setFirstGrade5(e.target.value)}
                                            fullWidth
                                            label="Select Grade"
                                        >
                                            {grades.map((grade, index) => (
                                                <MenuItem
                                                    value={grade}
                                                    key={Math.random() + "-" + index}
                                                >
                                                    {grade}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid
                                container
                                spacing={1}
                                sx={{mt: 0.5, border: 1, p: 1.2, borderColor: "primary.main"}}
                            >
                                <Grid item xs={12} lg={8}>
                                    <FormControl sx={{minWidth: "100%"}}>
                                        <InputLabel>Select Subject</InputLabel>
                                        <Select
                                            value={firstSubject6}
                                            onChange={(e) => setFirstSubject6(e.target.value)}
                                            fullWidth
                                            label="Select Subject"
                                        >
                                            {subjects.map((subject, index) => (
                                                <MenuItem
                                                    value={subject}
                                                    key={Math.random() + "-" + index}
                                                >
                                                    {subject}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <FormControl sx={{minWidth: "100%"}}>
                                        <InputLabel>Select Grade</InputLabel>
                                        <Select
                                            value={firstGrade6}
                                            onChange={(e) => setFirstGrade6(e.target.value)}
                                            fullWidth
                                            label="Select Grade"
                                        >
                                            {grades.map((grade, index) => (
                                                <MenuItem
                                                    value={grade}
                                                    key={Math.random() + "-" + index}
                                                >
                                                    {grade}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*/End of Row Three*/}

                    {/*Start of Row Four*/}
                    <Grid container spacing={2} sx={{mt: 0.3}}>
                        <Grid item xs={12} lg={6}>
                            <Grid
                                container
                                spacing={1}
                                sx={{mt: 0.5, border: 1, p: 1.2, borderColor: "primary.main"}}
                            >
                                <Grid item xs={12} lg={8}>
                                    <FormControl sx={{minWidth: "100%"}}>
                                        <InputLabel>Select Subject</InputLabel>
                                        <Select
                                            value={firstSubject7}
                                            onChange={(e) => setFirstSubject7(e.target.value)}
                                            fullWidth
                                            label="Select Subject"
                                        >
                                            {subjects.map((subject, index) => (
                                                <MenuItem
                                                    value={subject}
                                                    key={Math.random() + "-" + index}
                                                >
                                                    {subject}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <FormControl sx={{minWidth: "100%"}}>
                                        <InputLabel>Select Grade</InputLabel>
                                        <Select
                                            value={firstGrade7}
                                            onChange={(e) => setFirstGrade7(e.target.value)}
                                            fullWidth
                                            label="Select Grade"
                                        >
                                            {grades.map((grade, index) => (
                                                <MenuItem
                                                    value={grade}
                                                    key={Math.random() + "-" + index}
                                                >
                                                    {grade}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid
                                container
                                spacing={1}
                                sx={{mt: 0.5, border: 1, p: 1.2, borderColor: "primary.main"}}
                            >
                                <Grid item xs={12} lg={8}>
                                    <FormControl sx={{minWidth: "100%"}}>
                                        <InputLabel>Select Subject</InputLabel>
                                        <Select
                                            value={firstSubject8}
                                            onChange={(e) => setFirstSubject8(e.target.value)}
                                            fullWidth
                                            label="Select Subject"
                                        >
                                            {subjects.map((subject, index) => (
                                                <MenuItem
                                                    value={subject}
                                                    key={Math.random() + "-" + index}
                                                >
                                                    {subject}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <FormControl sx={{minWidth: "100%"}}>
                                        <InputLabel>Select Grade</InputLabel>
                                        <Select
                                            value={firstGrade8}
                                            onChange={(e) => setFirstGrade8(e.target.value)}
                                            fullWidth
                                            label="Select Grade"
                                        >
                                            {grades.map((grade, index) => (
                                                <MenuItem
                                                    value={grade}
                                                    key={Math.random() + "-" + index}
                                                >
                                                    {grade}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*/End of Row Four*/}

                    {/*Start of Row Five*/}
                    <Grid container spacing={2} sx={{mt: 0.3}}>
                        <Grid item xs={12} lg={6}>
                            <Grid
                                container
                                spacing={1}
                                sx={{mt: 0.5, border: 1, p: 1.2, borderColor: "primary.main"}}
                            >
                                <Grid item xs={12} lg={8}>
                                    <FormControl sx={{minWidth: "100%"}}>
                                        <InputLabel>Select Subject</InputLabel>
                                        <Select
                                            value={firstSubject9}
                                            onChange={(e) => setFirstSubject9(e.target.value)}
                                            fullWidth
                                            label="Select Subject"
                                        >
                                            {subjects.map((subject, index) => (
                                                <MenuItem
                                                    value={subject}
                                                    key={Math.random() + "-" + index}
                                                >
                                                    {subject}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <FormControl sx={{minWidth: "100%"}}>
                                        <InputLabel>Select Grade</InputLabel>
                                        <Select
                                            value={firstGrade9}
                                            onChange={(e) => setFirstGrade9(e.target.value)}
                                            fullWidth
                                            label="Select Grade"
                                        >
                                            {grades.map((grade, index) => (
                                                <MenuItem
                                                    value={grade}
                                                    key={Math.random() + "-" + index}
                                                >
                                                    {grade}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*/End of Row Five*/}

                    <FormControlLabel
                        sx={{mt: 3}}
                        control={
                            <Checkbox
                                checked={showSecondSitting}
                                onChange={(e) => setShowSecondSitting(e.target.checked)}
                                inputProps={{"aria-label": "controlled"}}
                            />
                        }
                        label="Second Sitting?"
                    />

                    {showSecondSitting && (
                        <React.Fragment>
                            <Typography variant="body1" className="fw-bold mt-2">
                                Details of O'level SSCE RESULTS (Second Sitting)
                            </Typography>

                            {/*Start of Row One*/}
                            <Grid container spacing={2} sx={{mt: 0.3}}>
                                <Grid item xs={12} lg={12}>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            mt: 0.5,
                                            border: 1,
                                            p: 1.2,
                                            borderColor: "primary.main",
                                        }}
                                    >
                                        <FormControl sx={{minWidth: "100%"}}>
                                            <InputLabel>Select Exam Type</InputLabel>
                                            <Select
                                                value={examType2}
                                                onChange={(e) => setExamType2(e.target.value)}
                                                fullWidth
                                                label="Select exam type"
                                            >
                                                {examBody.map((body, index) => (
                                                    <MenuItem value={body} key={index}>
                                                        {body}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} lg={6}>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            mt: 0.5,
                                            border: 1,
                                            p: 1.2,
                                            borderColor: "primary.main",
                                        }}
                                    >
                                        <Grid item xs={12} lg={8}>
                                            <FormControl sx={{minWidth: "100%"}}>
                                                <InputLabel>Select Subject</InputLabel>
                                                <Select
                                                    value={secondSubject1}
                                                    onChange={(e) => setSecondSubject1(e.target.value)}
                                                    fullWidth
                                                    label="Select Subject"
                                                >
                                                    {subjects.map((subject, index) => (
                                                        <MenuItem
                                                            value={subject}
                                                            key={Math.random() + "-" + index}
                                                        >
                                                            {subject}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl sx={{minWidth: "100%"}}>
                                                <InputLabel>Select Grade</InputLabel>
                                                <Select
                                                    value={secondGrade1}
                                                    onChange={(e) => setSecondGrade1(e.target.value)}
                                                    fullWidth
                                                    label="Select Grade"
                                                >
                                                    {grades.map((grade, index) => (
                                                        <MenuItem
                                                            value={grade}
                                                            key={Math.random() + "-" + index}
                                                        >
                                                            {grade}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            mt: 0.5,
                                            border: 1,
                                            p: 1.2,
                                            borderColor: "primary.main",
                                        }}
                                    >
                                        <Grid item xs={12} lg={8}>
                                            <FormControl sx={{minWidth: "100%"}}>
                                                <InputLabel>Select Subject</InputLabel>
                                                <Select
                                                    value={secondSubject2}
                                                    onChange={(e) => setSecondSubject2(e.target.value)}
                                                    fullWidth
                                                    label="Select Subject"
                                                >
                                                    {subjects.map((subject, index) => (
                                                        <MenuItem
                                                            value={subject}
                                                            key={Math.random() + "-" + index}
                                                        >
                                                            {subject}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl sx={{minWidth: "100%"}}>
                                                <InputLabel>Select Grade</InputLabel>
                                                <Select
                                                    value={secondGrade2}
                                                    onChange={(e) => setSecondGrade2(e.target.value)}
                                                    fullWidth
                                                    label="Select Grade"
                                                >
                                                    {grades.map((grade, index) => (
                                                        <MenuItem
                                                            value={grade}
                                                            key={Math.random() + "-" + index}
                                                        >
                                                            {grade}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/*/End of Row One*/}

                            {/*Start of Row Two*/}
                            <Grid container spacing={2} sx={{mt: 0.3}}>
                                <Grid item xs={12} lg={6}>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            mt: 0.5,
                                            border: 1,
                                            p: 1.2,
                                            borderColor: "primary.main",
                                        }}
                                    >
                                        <Grid item xs={12} lg={8}>
                                            <FormControl sx={{minWidth: "100%"}}>
                                                <InputLabel>Select Subject</InputLabel>
                                                <Select
                                                    value={secondSubject3}
                                                    onChange={(e) => setSecondSubject3(e.target.value)}
                                                    fullWidth
                                                    label="Select Subject"
                                                >
                                                    {subjects.map((subject, index) => (
                                                        <MenuItem
                                                            value={subject}
                                                            key={Math.random() + "-" + index}
                                                        >
                                                            {subject}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl sx={{minWidth: "100%"}}>
                                                <InputLabel>Select Grade</InputLabel>
                                                <Select
                                                    value={secondGrade3}
                                                    onChange={(e) => setSecondGrade3(e.target.value)}
                                                    fullWidth
                                                    label="Select Grade"
                                                >
                                                    {grades.map((grade, index) => (
                                                        <MenuItem
                                                            value={grade}
                                                            key={Math.random() + "-" + index}
                                                        >
                                                            {grade}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            mt: 0.5,
                                            border: 1,
                                            p: 1.2,
                                            borderColor: "primary.main",
                                        }}
                                    >
                                        <Grid item xs={12} lg={8}>
                                            <FormControl sx={{minWidth: "100%"}}>
                                                <InputLabel>Select Subject</InputLabel>
                                                <Select
                                                    value={secondSubject4}
                                                    onChange={(e) => setSecondSubject4(e.target.value)}
                                                    fullWidth
                                                    label="Select Subject"
                                                >
                                                    {subjects.map((subject, index) => (
                                                        <MenuItem
                                                            value={subject}
                                                            key={Math.random() + "-" + index}
                                                        >
                                                            {subject}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl sx={{minWidth: "100%"}}>
                                                <InputLabel>Select Grade</InputLabel>
                                                <Select
                                                    value={secondGrade4}
                                                    onChange={(e) => setSecondGrade4(e.target.value)}
                                                    fullWidth
                                                    label="Select Grade"
                                                >
                                                    {grades.map((grade, index) => (
                                                        <MenuItem
                                                            value={grade}
                                                            key={Math.random() + "-" + index}
                                                        >
                                                            {grade}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/*/End of Row Two*/}

                            {/*Start of Row Three*/}
                            <Grid container spacing={2} sx={{mt: 0.3}}>
                                <Grid item xs={12} lg={6}>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            mt: 0.5,
                                            border: 1,
                                            p: 1.2,
                                            borderColor: "primary.main",
                                        }}
                                    >
                                        <Grid item xs={12} lg={8}>
                                            <FormControl sx={{minWidth: "100%"}}>
                                                <InputLabel>Select Subject</InputLabel>
                                                <Select
                                                    value={secondSubject5}
                                                    onChange={(e) => setSecondSubject5(e.target.value)}
                                                    fullWidth
                                                    label="Select Subject"
                                                >
                                                    {subjects.map((subject, index) => (
                                                        <MenuItem
                                                            value={subject}
                                                            key={Math.random() + "-" + index}
                                                        >
                                                            {subject}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl sx={{minWidth: "100%"}}>
                                                <InputLabel>Select Grade</InputLabel>
                                                <Select
                                                    value={secondGrade5}
                                                    onChange={(e) => setSecondGrade5(e.target.value)}
                                                    fullWidth
                                                    label="Select Grade"
                                                >
                                                    {grades.map((grade, index) => (
                                                        <MenuItem
                                                            value={grade}
                                                            key={Math.random() + "-" + index}
                                                        >
                                                            {grade}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            mt: 0.5,
                                            border: 1,
                                            p: 1.2,
                                            borderColor: "primary.main",
                                        }}
                                    >
                                        <Grid item xs={12} lg={8}>
                                            <FormControl sx={{minWidth: "100%"}}>
                                                <InputLabel>Select Subject</InputLabel>
                                                <Select
                                                    value={secondSubject6}
                                                    onChange={(e) => setSecondSubject6(e.target.value)}
                                                    fullWidth
                                                    label="Select Subject"
                                                >
                                                    {subjects.map((subject, index) => (
                                                        <MenuItem
                                                            value={subject}
                                                            key={Math.random() + "-" + index}
                                                        >
                                                            {subject}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl sx={{minWidth: "100%"}}>
                                                <InputLabel>Select Grade</InputLabel>
                                                <Select
                                                    value={secondGrade6}
                                                    onChange={(e) => setSecondGrade6(e.target.value)}
                                                    fullWidth
                                                    label="Select Grade"
                                                >
                                                    {grades.map((grade, index) => (
                                                        <MenuItem
                                                            value={grade}
                                                            key={Math.random() + "-" + index}
                                                        >
                                                            {grade}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/*/End of Row Three*/}

                            {/*Start of Row Four*/}
                            <Grid container spacing={2} sx={{mt: 0.3}}>
                                <Grid item xs={12} lg={6}>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            mt: 0.5,
                                            border: 1,
                                            p: 1.2,
                                            borderColor: "primary.main",
                                        }}
                                    >
                                        <Grid item xs={12} lg={8}>
                                            <FormControl sx={{minWidth: "100%"}}>
                                                <InputLabel>Select Subject</InputLabel>
                                                <Select
                                                    value={secondSubject7}
                                                    onChange={(e) => setSecondSubject7(e.target.value)}
                                                    fullWidth
                                                    label="Select Subject"
                                                >
                                                    {subjects.map((subject, index) => (
                                                        <MenuItem
                                                            value={subject}
                                                            key={Math.random() + "-" + index}
                                                        >
                                                            {subject}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl sx={{minWidth: "100%"}}>
                                                <InputLabel>Select Grade</InputLabel>
                                                <Select
                                                    value={secondGrade7}
                                                    onChange={(e) => setSecondGrade7(e.target.value)}
                                                    fullWidth
                                                    label="Select Grade"
                                                >
                                                    {grades.map((grade, index) => (
                                                        <MenuItem
                                                            value={grade}
                                                            key={Math.random() + "-" + index}
                                                        >
                                                            {grade}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            mt: 0.5,
                                            border: 1,
                                            p: 1.2,
                                            borderColor: "primary.main",
                                        }}
                                    >
                                        <Grid item xs={12} lg={8}>
                                            <FormControl sx={{minWidth: "100%"}}>
                                                <InputLabel>Select Subject</InputLabel>
                                                <Select
                                                    value={secondSubject8}
                                                    onChange={(e) => setSecondSubject8(e.target.value)}
                                                    fullWidth
                                                    label="Select Subject"
                                                >
                                                    {subjects.map((subject, index) => (
                                                        <MenuItem
                                                            value={subject}
                                                            key={Math.random() + "-" + index}
                                                        >
                                                            {subject}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl sx={{minWidth: "100%"}}>
                                                <InputLabel>Select Grade</InputLabel>
                                                <Select
                                                    value={secondGrade8}
                                                    onChange={(e) => setSecondGrade8(e.target.value)}
                                                    fullWidth
                                                    label="Select Grade"
                                                >
                                                    {grades.map((grade, index) => (
                                                        <MenuItem
                                                            value={grade}
                                                            key={Math.random() + "-" + index}
                                                        >
                                                            {grade}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/*/End of Row Four*/}

                            {/*Start of Row Five*/}
                            <Grid container spacing={2} sx={{mt: 0.3}}>
                                <Grid item xs={12} lg={6}>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            mt: 0.5,
                                            border: 1,
                                            p: 1.2,
                                            borderColor: "primary.main",
                                        }}
                                    >
                                        <Grid item xs={12} lg={8}>
                                            <FormControl sx={{minWidth: "100%"}}>
                                                <InputLabel>Select Subject</InputLabel>
                                                <Select
                                                    value={secondSubject9}
                                                    onChange={(e) => setSecondSubject9(e.target.value)}
                                                    fullWidth
                                                    label="Select Subject"
                                                >
                                                    {subjects.map((subject, index) => (
                                                        <MenuItem
                                                            value={subject}
                                                            key={Math.random() + "-" + index}
                                                        >
                                                            {subject}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormControl sx={{minWidth: "100%"}}>
                                                <InputLabel>Select Grade</InputLabel>
                                                <Select
                                                    value={secondGrade9}
                                                    onChange={(e) => setSecondGrade9(e.target.value)}
                                                    fullWidth
                                                    label="Select Grade"
                                                >
                                                    {grades.map((grade, index) => (
                                                        <MenuItem
                                                            value={grade}
                                                            key={Math.random() + "-" + index}
                                                        >
                                                            {grade}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/*/End of Row One*/}
                        </React.Fragment>
                    )}

                    <Typography variant="body1" className="fw-bold my-2">
                        Upload Documents (attach copied of these documents)
                    </Typography>
                    <Grid container spacing={2}>
                        {uploads.map((row, index) => (
                            <Grid
                                item
                                xs={12}
                                lg={6}
                                key={`${Math.random()}-${index}`}
                                sx={{width: "45%"}}
                            >
                                <Typography component={"div"} variant="body2" sx={{fontWeight: 'normal'}}>
                                    {row.name}
                                </Typography>
                                <div className="d-flex gap-2 mt-3">
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleOpen(row, applicant.documents)}
                                        sx={{fontSize:'small',fontWeight:'normal',color:'#3E79E0'}}
                                    >
                                        View
                                    </Button>

                                    <label
                                        htmlFor={`upload-image-${row.id}`}
                                    >
                                        <Button
                                            variant="outlined"
                                            component="span"
                                            sx={{fontSize:'small', fontWeight:'normal',color:'#3E79E0'}}
                                        >
                                            update
                                        </Button>
                                        <input
                                            id={`upload-image-${row.id}`}
                                            hidden
                                            accept="image/*,application/pdf"
                                            type="file"
                                            onChange={(e) => handleFilesUpload(e, row)}
                                        />

                                    </label>
                                    <Button variant="text"
                                            sx={{color: '#3E79E0', fontStyle: 'italic', fontSize: '12px'}}>
                                        {getFileName(row)}
                                    </Button>
                                </div>


                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </Card>

            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{width: '100%', height:'100%'}}
                onClose={handleClose}
            >
                    <Box sx={style}>
                        <img
                            src={fileUrl}
                            height="100%"
                            width="100%"
                            alt={fileName}
                            style={{objectFit: 'cover'}}
                            onClick={handleClose}
                        />
                    </Box>

            </Modal>

            <Button
                variant="contained"
                onClick={handleSubmit}
                ref={ref}
                sx={{display: 'none'}}
            >
                Upload Files
            </Button>

        </React.Fragment>
    );
})

export default SsceInfo;
