import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "../../../styles/dashbaord/modal/modal-style.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1500,
  height: 700,
  backgroundColor: "background.paper",
  border: 0,
  p: 4,
};

const StatusModal = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const admissionStatusClass =
    props?.admissionStatus === "Processing"
      ? "amber"
      : props.admissionStatus === "Admitted"
      ? "green"
      : "red";
  return (
    <React.Fragment>
      <Button className="d-none" ref={ref} onClick={handleOpen}>
        Open modal
      </Button>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ border: "none" }}
      >
        <Box
          sx={style}
          className="d-flex justify-content-center align-items-center flex-column"
        >
          <Typography variant="h6">
            ADMISSION STATUS:
            <span className={`text-center ms-2 ${admissionStatusClass}`}>
              {props?.admissionStatus}
            </span>
          </Typography>

          <Typography
            className={`text-center ${admissionStatusClass}`}
            variant="h2"
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            {props?.admissionStatus}
          </Typography>

          <Typography className="text-start" variant="h6">
            Dear{" "}
            <span className="text-success">
              {props?.lastName} {props?.firstName}
            </span>{" "}
            , You have not been given any admission yet in Maduka University.
            Kindly and patiently check back later. Thanks
          </Typography>

          <Box className="d-flex justify-content-center">
            <button
              onClick={handleClose}
              className="btn btn-outline-success  mt-5 text-end"
            >
              View My Application
            </button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
});

export default StatusModal;
