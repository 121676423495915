import * as React from 'react';
import {useSelector} from 'react-redux';
import {Outlet,Navigate} from "react-router-dom";

function PrivateRoutes(props) {
    const auth = useSelector(state => state.auth);
    const token = auth?.loginSuccess?.token
    const cmp = token ? (<Outlet/>) : (<Navigate to={'/login'}/>)
    return (
        cmp
    );
}

export default PrivateRoutes;