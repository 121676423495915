import {combineReducers} from "redux";
import ssceReducer from './features/applicant-data/ssce-info-slice'
import jambReducer from './features/applicant-data/jamb-info-slice'
import personalReducer from './features/applicant-data/personal-info-slice'
import authReducer from './features/auth/auth-slice'

const rootReducer = combineReducers({
    ssce: ssceReducer,
    jamb: jambReducer,
    personal: personalReducer,
    auth: authReducer
})

export default rootReducer;