import React from "react";
import {useRoutes} from "react-router-dom";
import ApplicantList from "../views/appicants";
import ApplicantPage from "../views/applicant";
import ApplicationForm from "../views/applicationForm";
import DirectEntrySummary from "../views/components/direct-entry/SummaryPage";
import IjmbEntrySummary from "../views/components/ijmb-entry/SummaryPage";
import JambEntrySummary from "../views/components/jamb-entry/SummaryPage";
import LoginPage from "../views/components/jamb-entry/LoginPage";
import DashboardComponent from "../views/dashboard/Dashboard";
import InvoiceComponent from "../views/components/jamb-entry/Invoice";
import PaymentPage from "../share/payments/paymentPage";

export default function Routes() {
  return useRoutes([
    {
      path: "/",
      element: <ApplicationForm/>,
    },

    {
      path: "/applicants",
      element: <ApplicantList/>,
    },
    {
      path: "/applicants/:id",
      element: <ApplicantPage/>,
    },
    {
      path: "/jamb-application-summary",
      element: <JambEntrySummary/>,
    },
    {
      path: "/direct-entry-application-summary",
      element: <DirectEntrySummary/>,
    },
    {
      path: "/ijmb-application-summary",
      element: <IjmbEntrySummary/>,
    },
    {
      path: "/invoice",
      element: <InvoiceComponent/>,
    },
    {
      path: "/login",
      element: <LoginPage/>,
    },
    {
      path: "/dashboard/*",
      element: <DashboardComponent/>,
    },
    {
      path: "/payments",
      element: <PaymentPage/>
    }
  ]);
}
