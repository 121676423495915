import * as  React from 'react';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Grid} from "@mui/material";
import PersonalInfo from "../components/PersonalInfo";
import JambInfo from "../components/JambInfo";
import SSCEInfo from "../components/SSCEInfo";
import ConfirmationPage from "../components/ConfirmationPage";

const style = {
    position: 'absolute',
    top: '0',
    left: '',
    transform: 'translateX(0)',
    width: '100%',
    height: 'auto',
    backgroundColor: 'background.paper',
    border: 0,
    p: 4,
    // overflow: 'scroll',
    display: 'block'
};


function UpdateInfoModal({openModal, closeDialog, applicant}) {
    const [inc, setInc] = React.useState(0);
    const [attestation, setAttestation] = React.useState(false);
    const buttonRef = React.useRef();
    const updateRecordRef = React.useRef();

    const dataItem = React.useMemo(() => {
        return [
            (<PersonalInfo applicant={applicant}/>),
            (<JambInfo applicant={applicant}/>),
            (<SSCEInfo applicant={applicant} ref={buttonRef}/>),
            (<ConfirmationPage
                attestation={attestation}
                setAttestation={setAttestation}
                applicant={applicant}
                ref={updateRecordRef}
            />)
        ]
    }, [applicant, attestation])

    const [currentComp, setCurrentComp] = React.useState(dataItem[0])

    const handlePrevious = (event) => {
        event.preventDefault()
        if (inc > 0)
            setInc(inc - 1);
    }

    const handleNext = (event) => {
        event.preventDefault()
        if (inc < dataItem.length - 1)
            setInc(inc + 1)

        //handle click operation on the ssce file upload page
        buttonRef.current?.click()
    }

    const formUpload = async (event) => {
        updateRecordRef.current?.click();
    }


    React.useEffect(() => {
        if (inc < dataItem.length - 1 || inc > -1) {
            setCurrentComp(dataItem[inc])
        } else {
            setCurrentComp(dataItem[inc]);
        }


    }, [dataItem, inc])
    return (
        <React.Fragment>
            <Modal
                open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                    border: 'none',
                    overflowY: 'scroll',
                    maxWidth: '100%',
                    overflowX: 'hidden',
                    paddingRight: '5px'
                }}
            >
                <Box sx={style}>
                    <Grid container spacing={2}>

                        <Grid item sm={11}>
                            <Typography variant="h4">

                            </Typography>
                        </Grid>

                        <Grid item sm={1}>

                            <Button sx={{color: 'red', borderColor: 'red'}} onClick={closeDialog} variant="outlined">
                                X
                            </Button>
                        </Grid>

                    </Grid>

                    <Box className="d-flex justify-content-center align-items-center" component="section"
                         sx={{marginTop: '1rem', height: 'auto', width: '100%'}}>
                        {currentComp}
                    </Box>

                    <Grid container spacing={3} sx={{marginTop: '2rem', justifyContent: 'center'}}>

                        <Grid item>
                            {
                                inc > 0 && (
                                    <Button variant="contained" sx={{color: '#ffffff'}}
                                            onClick={(event) => handlePrevious(event)}>
                                        Previous
                                    </Button>
                                )
                            }


                        </Grid>

                        <Grid item>
                            {
                                inc === dataItem.length - 1
                                    ? attestation &&
                                    (
                                        <Button
                                            variant="contained"
                                            sx={{color: '#ffffff'}}
                                            onClick={formUpload}

                                        >
                                            Update Records
                                        </Button>
                                    )
                                    : (
                                        <Button
                                            variant="contained"
                                            sx={{color: '#ffffff'}}
                                            onClick={(event) => handleNext(event)}
                                            ref={buttonRef}
                                        >
                                            Next
                                        </Button>
                                    )

                            }

                        </Grid>

                    </Grid>

                </Box>
            </Modal>
        </React.Fragment>

    );
}

export default UpdateInfoModal;