import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    first_name: '',
    last_name: '',
    middle_name: '',
    phone: '',
    nationality: '',
    state: '',
    lg: '',
    city: '',
    email: '',
    dob: '',
    gender: '',
    password: '',
    guardian_name: '',
    guardian_phone: '',
    guardian_address: '',
    guardian_relationship: '',
    academic_session: '',
}

const personalInfoSlice = createSlice({
    name: 'personaSlice',
    initialState,
    reducers: {
        addPersonalInfo: (state, action) => {
            state.first_name = action.payload?.first_name;
            state.last_name = action.payload?.last_name;
            state.middle_name = action.payload?.middle_name;
            state.phone = action.payload?.phone;
            state.nationality = action.payload?.nationality;
            state.state = action.payload?.state;
            state.lg = action.payload?.lg;
            state.city = action.payload?.city;
            state.email = action.payload?.email;
            state.dob = action.payload?.dob;
            state.gender = action.payload?.gender;
            state.guardian_name = action.payload?.guardian_name;
            state.guardian_phone = action.payload?.guardian_phone;
            state.guardian_address = action.payload?.guardian_address;
            state.guardian_relationship = action.payload?.guardian_relationship;
            state.academic_session = action.payload?.academic_session;
            state.password = action.payload?.password;
        }
    }
})

export const {addPersonalInfo} = personalInfoSlice.actions;
export default personalInfoSlice.reducer;
