import {Box, Typography} from "@mui/material";
import React, {Fragment} from 'react';
import PersonalInfo from "../components/jamb-entry/personal-info";
import JambInformation from "../components/jamb-entry/jamb-info";
import SsceResultAndFiles from "../components/jamb-entry/ssce-info";


function JambApplication({
                           jambPage,
                           setJambPage,
                           jambApplicationData,
                           setJambApplicationData
                         }) {


  const handlePersonalDetailsSubmit = (data) => {
    let newObj = {...jambApplicationData, ...data};
    setJambApplicationData(newObj);
    setJambPage(2);
  };


  const handleJambDataSubmit = (data) => {
    let newObj = {...jambApplicationData, ...data};
    setJambApplicationData(newObj);
    setJambPage(3);
  };


  return (
    <Fragment>

      <Typography variant="h5" component="h5">
        JAMB Admission Entry Form
      </Typography>

      <Box sx={{
        margin: '2rem 0',
        backgroundColor: 'FCFCFC',
        border: 'solid 1px #00356B',
        p: '20px',
        borderRadius: '10px'
      }}>

        <Typography variant='h6' component='h6' color="#B5861E" sx={{marginBottom: '1rem',}}>
          Important Information
        </Typography>

        <ol style={{padding: '0', paddingLeft: '12px'}}>
          <li>
            Only candidates who scored above 140 in UTME for the 2024/2025 Academic Session are eligible to apply.
          </li>
          <li>
            Admission Form fee is N5000 (Five thousand Naira only). After filling out the online application, continue to
            make payment through PAYSTACK.
          </li>
          <li>
            Forms received after the deadline will not be processed.
          </li>
        </ol>

      </Box>

      <Typography
        variant="h6"
        component="h6"
        color="#00356B"
        sx={{fontStyle: 'italic', margin: '1rem 0', fontSize: '25px', lineHeight: '30px', fontWeight: '400'}}
      >
        Fill out with accurate and valid information. All entries must be in block letters.
      </Typography>


      <Box sx={{display: jambPage === 1 ? "block" : "none"}}>
        <PersonalInfo onSubmit={(data) => handlePersonalDetailsSubmit(data)}/>
      </Box>

      <Box sx={{display: jambPage === 2 ? "block" : "none"}}>
        <JambInformation
          onForwardClick={(data) => handleJambDataSubmit(data)}
          onPreviousClick={() => setJambPage(1)}
        />
      </Box>

      <Box sx={{display: jambPage === 3 ? "block" : "none"}}>
        <SsceResultAndFiles
          formInputs={jambApplicationData}
          onPreviousClick={() => setJambPage(2)}
        />
      </Box>
    </Fragment>
  )
}

export default JambApplication;
