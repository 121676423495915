import React from "react";
import {useLocation} from "react-router-dom";
import {Box, Typography,} from "@mui/material";
import './payment.css'
import DirectTransferPayment from "./direct-transfer/DirectTransferPayment";
import PaystackPayment from "./paystack-payment/PaystackPayment";


export default function PaymentPage() {
  const [applicantData, setApplicantData] = React.useState({});

  const location = useLocation();

  React.useEffect(() => {
    window.scroll(0, 0);
    setApplicantData(location.state?.applicant)
  }, [location])

  return (
    <Box sx={{
      textAlign: 'center',
      padding: '32px'
    }}
    >
      <Box sx={{margin: '1rem 0'}}>
        <Typography variant="h2"
                    sx={{
                      fontWeight: '500',
                      fontFamily: 'Inter',
                      fontSize: '32px',
                      lineHeight: '38px',
                      color: '#00356B'
                    }}>
          Application is being processed
        </Typography>


        <Typography variant="h6" sx={{
          fontWeight: '400',
          fontSize: '16px',
          fontFamily: 'Inter',
          lineHeight: '24px',
          color: '#2E5565'
        }}>
          Your application is being submitted, Kindly proceed to make your payment.
        </Typography>
      </Box>
      <Typography variant="h2" sx={{
        fontWeight: '500',
        fontSize: '30px',
        fontFamily: 'Inter',
        lineHeight: '36px',
        color: '#2E5565',
        marginBottom: '3rem'
      }}
      >
        Choose Payment Option
      </Typography>

      <Box className="payment-options" gap="30px">

        <DirectTransferPayment
          applicantData={applicantData}
        />


        <PaystackPayment
          applicantData={applicantData}
        />

      </Box>
    </Box>
  );
}
