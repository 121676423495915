import React, {useEffect, useState} from "react";
import {Box, Container, CssBaseline, Typography} from "@mui/material";
import axios from "axios";
import "../../../styles/document-to-print.css";
import logo from "../../../assets/img/MU LOGO.png";
import moment from "moment/moment";

const ComponentToPrint = React.forwardRef((props, ref) => {
  const [applicantData, setApplicantData] = useState({});
  const [applicantPayment, setApplicantPayment] = useState({});

  const passport = applicantData?.documents?.find(
    (value) => value.upload_type === "passport_photo"
  );

  const umteSlip = applicantData?.documents?.find(
    (value) => value.upload_type === "utme_slip"
  );

  const bankSlip = applicantData?.documents?.find(
    (value) => value.upload_type === "bank_slip"
  );
  const ssceSlip = applicantData?.documents?.find(
    (value) => value.upload_type === "sse_result"
  );
  const lgaOriginSlip = applicantData?.documents?.find(
    (value) => value.upload_type === "lg_origin"
  );
  const ninSlip = applicantData?.documents?.find(
    (value) => value.upload_type === "nin_slip"
  );
  const birthCertSlip = applicantData?.documents?.find(
    (value) => value.upload_type === "birth_cert"
  );

  const examGrade = (item, index) => (
    <li key={index}>
      {item.subject}- <span className="fw-bold">{item.grade}</span>
    </li>
  );

  useEffect(() => {
    (async function () {
      const applicantId = localStorage.getItem("applicant_id");
      let applicantData = {};
      if (process.env.NODE_ENV === "production") {
        applicantData = await axios.get(
          `https://api.portal.madukauniversity.edu.ng/api/applicant/${applicantId}`
        );
      } else if (process.env.NODE_ENV === "development") {
        applicantData = await axios.get(
          `http://localhost:3001/api/applicant/${applicantId}`
        );
      }
      setApplicantData(applicantData.data.data);

    })();
  }, []);
  useEffect(() => {
    const data = localStorage.getItem("payRes");
    const paymentInfo = JSON.parse(data);
    setApplicantPayment(paymentInfo);
  }, []);

  return (
    <div ref={ref}>
      <CssBaseline/>
      <Container sx={{padding: '50px', marginTop: '1rem', marginBottom: '1rem'}} className="shadow-sm">

        <div style={{textAlign: 'center'}}>
          <img
            src={logo}
            height="60"
            width="60"
            alt="School Logo"
          />

          <Typography variant="h4" sx={{
            fontWeight: "600",
            fontFamily: 'Inter',
            fontSize: '24px',
            lineHeight: '31px',
            color: '#00356B',
            margin: '5px 0',
          }}>
            MADUKA UNIVERSITY, Ekwegbe - Nsukka, Enugu State. Nigeria
          </Typography>

          <Typography variant="h6" sx={{
            margin: '5px 0',
            fontFamily: 'Inter',
            fontSize: '18px',
            lineHeight: '23px',
            color: '#00356B',
            fontWeight: '500',
          }}>
            THE REGISTRAR’S OFFICE
          </Typography>

          <Typography variant="h6" sx={{
            margin: '5px 0',
            fontFamily: 'Inter',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '18px',
            color: '#2E5565'
          }}>
            JAMB ENTRY APPLICATION SUMMARY
          </Typography>
        </div>
        <Box textAlign="left" width="100" sx={{margin: '2rem 0',}}>

          <Typography variant="h5" sx={{
            fontWeight: '600',
            fontSize: '16px',
            fontFamily: 'Inter',
            lineHeight: '20px',
            color: '#00356B',
            margin: '1rem 0'
          }}>
            PERSONAL DETAILS
          </Typography>

          <Box width="100" sx={{display: 'flex', justifyContent: 'space-between', width: '100%',}}>
            <div style={{width: '30%'}}>

              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'
                }}>
                  First Name
                </Typography>

                <Typography variant="h6" sx={{
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#001F2B',
                }}>
                  {applicantData?.first_name}
                </Typography>


              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0'}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'
                }}>
                  Other Name
                </Typography>

                <Typography variant="h6" sx={{
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#001F2B',
                  textAlign: 'right',
                }}>
                  {applicantData?.middle_name}
                </Typography>


              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0'}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'
                }}>
                  Last Name
                </Typography>

                <Typography variant="h6" sx={{
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#001F2B',
                  textAlign: 'right',
                }}>
                  {applicantData?.last_name}
                </Typography>


              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0'}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'
                }}>
                  Email Address
                </Typography>

                <Typography variant="h6" sx={{
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#001F2B',
                  textAlign: 'right',
                }}>
                  {applicantData?.email}
                </Typography>


              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0'}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'
                }}>
                  Nationality
                </Typography>

                <Typography variant="h6" sx={{
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#001F2B',
                  textAlign: 'left',
                }}>
                  {applicantData?.nationality}
                </Typography>


              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0'}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'
                }}>
                  State of Origin
                </Typography>

                <Typography variant="h6" sx={{
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#001F2B',
                  textAlign: 'left',
                }}>
                  {applicantData?.state}
                </Typography>


              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0'}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'
                }}>
                  L.G.A.
                </Typography>

                <Typography variant="h6" sx={{
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#001F2B'
                }}>
                  {applicantData?.lg}
                </Typography>


              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0'}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'
                }}>
                  City
                </Typography>

                <Typography variant="h6" sx={{
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#001F2B',
                  textAlign: 'left',
                }}>
                  {applicantData?.city}
                </Typography>


              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0'}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'
                }}>
                  Date of Birth
                </Typography>

                <Typography variant="h6" sx={{
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#001F2B',
                  textAlign: 'left',
                }}>
                  {moment(applicantData?.dob).format('YYYY-MM-DD')}
                </Typography>


              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0'}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'

                }}>
                  Gender
                </Typography>

                <Typography variant="h6" sx={{
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#001F2B',
                  textAlign: 'left',
                }}>
                  {applicantData?.gender}
                </Typography>


              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0'}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'
                }}>
                  Session
                </Typography>

                <Typography variant="h6" sx={{
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#001F2B',
                  textAlign: 'left',
                }}>
                  {applicantData?.session?.start_year}/{applicantData?.session?.end_year}
                </Typography>


              </div>


            </div>

            <Box component="div" sx={{}}>
              <img
                src={passport?.file_url}
                alt={`${applicantData?.last_name} ${applicantData?.last_name} Passport photo`}
                style={{width: '100px', height: '100px'}}
              />
            </Box>
          </Box>

        </Box>
        <Box component="div" textAlign="left" width="100" sx={{margin: '2rem 0'}}>

          <Typography variant="h5" sx={{
            fontWeight: '600',
            fontSize: '16px',
            fontFamily: 'Inter',
            lineHeight: '20px',
            color: '#00356B',
            margin: '1rem 0'
          }}>
            GUARDIAN INFORMATION
          </Typography>

          <Box component="div" width="100" sx={{display: 'flex', justifyContent: 'space-between', width: '100%',}}>
            <div style={{width: '30%'}}>

              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'
                }}>
                  Full Name
                </Typography>

                <Typography variant="h6" sx={{
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#001F2B'
                }}>
                  {applicantData?.guardian?.name}
                </Typography>


              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'
                }}>
                  Relationship
                </Typography>

                <Typography variant="h6" sx={{
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#001F2B'
                }}>
                  {applicantData?.guardian?.relationship}
                </Typography>


              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'
                }}>
                  Phone Number
                </Typography>

                <Typography variant="h6" sx={{
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#001F2B'
                }}>
                  {applicantData?.guardian?.phone}
                </Typography>


              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'
                }}>
                  Address
                </Typography>

                <Typography variant="h6" sx={{
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#001F2B'
                }}>
                  {applicantData?.guardian?.address}
                </Typography>


              </div>

            </div>

          </Box>

        </Box>
        <Box component="div" textAlign="left" width="100" sx={{margin: '2rem 0'}}>

          <Typography variant="h5" sx={{
            fontWeight: '600',
            fontSize: '16px',
            fontFamily: 'Inter',
            lineHeight: '20px',
            color: '#00356B',
            margin: '1rem 0'
          }}>
            JAMB INFORMATION
          </Typography>

          <Box component="div" width="100" sx={{display: 'flex', justifyContent: 'space-between', width: '100%',}}>
            <div style={{width: '30%'}}>

              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'
                }}>
                  Jamb Reg. No
                </Typography>

                <Typography variant="h6" sx={{
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#001F2B',
                }}>
                  {applicantData?.jamb_number}
                </Typography>


              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'
                }}>
                  Score
                </Typography>

                <Typography variant="h6" sx={{
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#001F2B'
                }}>
                  {applicantData?.jamb_total_score}
                </Typography>


              </div>

            </div>

          </Box>
          <Box component="div">
            <Typography variant="h5" sx={{
              fontWeight: '500',
              fontSize: '16px',
              fontFamily: 'Inter',
              lineHeight: '20px',
              color: '#00356B',
              marginTop: '1rem'
            }}>
              Subjects
            </Typography>
            <div style={{width: '30%'}}>

              {
                applicantData?.jamb_subject_scores?.map((value, index) => (

                  <div
                    style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}
                    key={index}
                  >

                    <Typography variant="h6" sx={{
                      fontWeight: '400',
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      lineHeight: '18px',
                      color: '#2E5565'
                    }}>
                      {value.subject}
                    </Typography>

                    <Typography variant="h6" sx={{
                      fontWeight: '500',
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      lineHeight: '18px',
                      color: '#001F2B'
                    }}>
                      {value.score}
                    </Typography>


                  </div>
                ))
              }


            </div>

          </Box>
        </Box>
        <Box textAlign="left" width="100" component="div" sx={{margin: '2rem 0'}}>

          <Typography variant="h5" sx={{
            fontWeight: '600',
            fontSize: '16px',
            fontFamily: 'Inter',
            lineHeight: '20px',
            color: '#00356B',
            margin: '1rem 0'
          }}>
            Choice Institutions
          </Typography>

          <Box component="div"
               style={{display: 'flex', width: '100%', justifyContent: 'space-between', margin: '5px 0',}}>
            <Box component="div" sx={{width: '30%'}}>
              <Typography variant="h5" sx={{
                fontWeight: '500',
                fontSize: '16px',
                fontFamily: 'Inter',
                lineHeight: '20px',
                color: '#00356B',
                margin: '1rem 0'
              }}>
                First Choice
              </Typography>

              <div>

                <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', margin: '5px 0',}}>

                  <Typography variant="h6" sx={{
                    fontWeight: '400',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#2E5565'
                  }}>
                    University
                  </Typography>

                  <Typography variant="h6" sx={{
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#001F2B'
                  }}>
                    {applicantData?.jamb_first_choice?.university}
                  </Typography>


                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}>

                  <Typography variant="h6" sx={{
                    fontWeight: '400',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#2E5565'
                  }}>
                    College/Polytechnic
                  </Typography>

                  <Typography variant="h6" sx={{
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#001F2B'
                  }}>
                    {applicantData?.jamb_first_choice?.polytechnic}
                  </Typography>


                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}>

                  <Typography variant="h6" sx={{
                    fontWeight: '400',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#2E5565'
                  }}>
                    Course
                  </Typography>

                  <Typography variant="h6" sx={{
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#001F2B'
                  }}>
                    {applicantData?.jamb_first_choice?.course}
                  </Typography>


                </div>


              </div>

            </Box>
            <Box component="div" sx={{width: '30%'}}>
              <Typography variant="h5" sx={{
                fontWeight: '500',
                fontSize: '16px',
                fontFamily: 'Inter',
                lineHeight: '20px',
                color: '#00356B',
                margin: '1rem 0'
              }}>
                Second Choice
              </Typography>

              <div>

                <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', margin: '5px 0',}}>

                  <Typography variant="h6" sx={{
                    fontWeight: '400',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#2E5565'
                  }}>
                    University
                  </Typography>

                  <Typography variant="h6" sx={{
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#001F2B'
                  }}>
                    {applicantData?.jamb_second_choice?.university}
                  </Typography>


                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}>

                  <Typography variant="h6" sx={{
                    fontWeight: '400',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#2E5565'
                  }}>
                    College/Polytechnic
                  </Typography>

                  <Typography variant="h6" sx={{
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#001F2B'
                  }}>
                    {applicantData?.jamb_second_choice?.polytechnic}
                  </Typography>


                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}>

                  <Typography variant="h6" sx={{
                    fontWeight: '400',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#2E5565'
                  }}>
                    Course
                  </Typography>

                  <Typography variant="h6" sx={{
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#001F2B'
                  }}>
                    {applicantData?.jamb_second_choice?.course}
                  </Typography>


                </div>


              </div>

            </Box>

          </Box>

        </Box>
        <Box component="div" textAlign="left" width="100" sx={{margin: '2rem 0'}}>

          <Typography variant="h5" sx={{
            fontWeight: '600',
            fontSize: '16px',
            fontFamily: 'Inter',
            lineHeight: '20px',
            color: '#00356B',
            margin: '1rem 0'
          }}>
            SSCE RESULT
          </Typography>

          <Box component="div" sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box component="div" sx={{width: '30%'}}>
              <Typography variant="h5" sx={{
                fontWeight: '500',
                fontSize: '16px',
                fontFamily: 'Inter',
                lineHeight: '20px',
                color: '#00356B',
                margin: '1rem 0'
              }}>
                First Sitting
              </Typography>
              <div>

                <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}>

                  <Typography variant="h6" sx={{
                    fontWeight: '400',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#2E5565'
                  }}>
                    Type
                  </Typography>

                  <Typography variant="h6" sx={{
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#001F2B'
                  }}>
                    {applicantData?.exam_first_sitting?.exam_type}
                  </Typography>


                </div>

                {
                  applicantData?.exam_first_sitting?.subject_grade?.map((value, index) => (
                    <div
                      style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}
                      key={index}
                    >

                      <Typography variant="h6" sx={{
                        fontWeight: '400',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        lineHeight: '18px',
                        color: '#2E5565'
                      }}>
                        {value?.subject}
                      </Typography>

                      <Typography variant="h6" sx={{
                        fontWeight: '500',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        lineHeight: '18px',
                        color: '#001F2B'
                      }}>
                        {value?.grade}
                      </Typography>


                    </div>
                  ))
                }

              </div>

            </Box>
            <Box component="div" sx={{width: '30%'}}>
              <Typography variant="h5" sx={{
                fontWeight: '500',
                fontSize: '16px',
                fontFamily: 'Inter',
                lineHeight: '20px',
                color: '#00356B',
                margin: '1rem 0'
              }}>
                Second Sitting
              </Typography>
              <div>

                <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}>

                  <Typography variant="h6" sx={{
                    fontWeight: '400',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#2E5565'
                  }}>
                    Type
                  </Typography>

                  <Typography variant="h6" sx={{
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#001F2B'
                  }}>
                    {applicantData?.exam_second_sitting?.exam_type}
                  </Typography>


                </div>

                {
                  applicantData?.exam_second_sitting?.subject_grade?.map((value, index) => (
                    <div
                      style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}
                      key={index}
                    >

                      <Typography variant="h6" sx={{
                        fontWeight: '400',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        lineHeight: '18px',
                        color: '#2E5565'
                      }}>
                        {value?.subject}
                      </Typography>

                      <Typography variant="h6" sx={{
                        fontWeight: '500',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        lineHeight: '18px',
                        color: '#001F2B'
                      }}>
                        {value?.grade}
                      </Typography>


                    </div>
                  ))
                }

              </div>

            </Box>
          </Box>

        </Box>
        <Box component="div" textAlign="left" width="100" sx={{margin: '2rem 0'}}>

          <Typography variant="h5" sx={{
            fontWeight: '600',
            fontSize: '16px',
            fontFamily: 'Inter',
            lineHeight: '20px',
            color: '#00356B',
            margin: '1rem 0'
          }}>
            DOCUMENTS/ATTACHMENTS
          </Typography>

          <Box component="div">
            <div style={{width: '30%'}}>

              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'
                }}>
                  UTME SLIP
                </Typography>


                <a
                  style={{
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#0580FF',
                    textDecoration: 'none'
                  }}
                  target="_blank"
                  href={umteSlip?.file_url}
                >
                  view document
                </a>


              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'
                }}>
                  Birth Certificate
                </Typography>

                <a
                  style={{
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#0580FF',
                    textDecoration: 'none'
                  }}
                  target="_blank"
                  href={birthCertSlip?.file_url}
                >
                  view document
                </a>


              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'
                }}>
                  NIN SLIP
                </Typography>

                <a
                  style={{
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#0580FF',
                    textDecoration: 'none'
                  }}
                  target="_blank"
                  href={ninSlip?.file_url}
                >
                  view document
                </a>


              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}>

                <Typography variant="h6" sx={{
                  fontWeight: '400',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#2E5565'
                }}>
                  SSCE CERTIFICATE
                </Typography>

                <a
                  style={{
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#0580FF',
                    textDecoration: 'none'
                  }}
                  target="_blank"
                  href={ssceSlip?.file_url}
                >
                  view document
                </a>


              </div>

            </div>

          </Box>

        </Box>
        <Box component="div" textAlign="left" width="100" sx={{margin: '2rem 0'}}>

          <Typography variant="h5" sx={{
            fontWeight: '600',
            fontSize: '16px',
            fontFamily: 'Inter',
            lineHeight: '20px',
            color: '#00356B'
          }}>
            PAYMENT DETAILS
          </Typography>

          <Box component="div" sx={{width: '30%'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}>

              <Typography variant="h6" sx={{
                fontWeight: '400',
                fontFamily: 'Inter',
                fontSize: '14px',
                lineHeight: '18px',
                color: '#2E5565'
              }}>
                Transaction Reference
              </Typography>

              <Typography variant="h6" sx={{
                fontWeight: '500',
                fontFamily: 'Inter',
                fontSize: '14px',
                lineHeight: '18px',
                color: '#001F2B'
              }}>
                {applicantData?.transaction_ref}
              </Typography>


            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}>

              <Typography variant="h6" sx={{
                fontWeight: '400',
                fontFamily: 'Inter',
                fontSize: '14px',
                lineHeight: '18px',
                color: '#2E5565'
              }}>
                Amount Paid
              </Typography>

              <Typography variant="h6" sx={{
                fontWeight: '500',
                fontFamily: 'Inter',
                fontSize: '14px',
                lineHeight: '18px',
                color: '#001F2B'
              }}>
                {applicantData?.payment?.payment?.amount / 100}
              </Typography>


            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', margin: '5px 0',}}>

              <Typography variant="h6" sx={{
                fontWeight: '400',
                fontFamily: 'Inter',
                fontSize: '14px',
                lineHeight: '18px',
                color: '#2E5565'
              }}>
                Date Paid
              </Typography>

              <Typography variant="h6" sx={{
                fontWeight: '500',
                fontFamily: 'Inter',
                fontSize: '14px',
                lineHeight: '18px',
                color: '#001F2B'
              }}>
                {moment(applicantData?.payment?.payment?.paid_at).format('YYYY-MM-DD')}
              </Typography>


            </div>
          </Box>

        </Box>
      </Container>
    </div>
  );
});

export default ComponentToPrint;
