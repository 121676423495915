export const Polytechnics = [
  {
    id: 1,
    name: "Air Force Institute of Technology (AFIT), Airforce Base, Kaduna",
  },
  { id: 2, name: "Akanu Ibiam Federal Polytechnic, Unwana-Afikpo" },
  { id: 3, name: "Auchi Polytechnic, Auchi" },
  { id: 4, name: "Federal Polytechnic, Ado-Ekiti" },
  { id: 5, name: "Federal Polytechnic, Ayede" },
  { id: 6, name: "Federal Polytechnic, Bali" },
  { id: 7, name: "Federal Polytechnic, Bauchi" },
  { id: 8, name: "Federal Polytechnic, Bida" },
  { id: 9, name: "Federal Polytechnic, Daura" },
  { id: 10, name: "Federal Polytechnic, Damaturu" },
  { id: 11, name: "Federal Polytechnic, Ede" },
  { id: 12, name: "Federal Polytechnic, Ekowe" },
  { id: 13, name: "Federal Polytechnic Idah" },
  { id: 14, name: "Federal Polytechnic, Ilaro" },
  { id: 15, name: "Federal Polytechnic Ile-Oluji" },
  { id: 16, name: "Federal Polytechnic Isuochi" },
  { id: 17, name: "Federal Polytechnic Kabo" },
  { id: 18, name: "Federal Polytechnic Kaltungo" },
  { id: 19, name: "Federal Polytechnic Kaura-Namoda" },
  { id: 20, name: "Federal Polytechnic, Monguno" },
  { id: 21, name: "Federal Polytechnic, Mubi" },
  { id: 22, name: "Federal Polytechnic, Nasarawa" },
  { id: 23, name: "Federal Polytechnic, Neede" },
  { id: 24, name: "Federal Polytechnic, Nyak, Shendam" },
  { id: 25, name: "Federal Polytechnic of Oil And Gas, Bonny" },
  { id: 26, name: "Federal Polytechnic, Offa" },
  { id: 27, name: "Federal Polytechnic, Ohodo" },
  { id: 28, name: "Federal Polytechnic, Oko" },
  { id: 29, name: "Federal Polytechnic, Orogun" },
  { id: 30, name: "Federal Polytechnic, Ukana" },
  { id: 31, name: "Federal Polytechnic, Ugep" },
  { id: 32, name: "Federal Polytechnic, Wannune" },
  { id: 33, name: "Hussaini Adamu Federal Polytechnic, Kazaure" },
  { id: 34, name: "Kaduna Polytechnic, Kaduna" },
  { id: 35, name: "Maritime Academy of Nigeria, Oron" },
  {
    id: 36,
    name: "National Institute of Construction Technology and Management, Uromi",
  },
  {
    id: 37,
    name: "Nigerian Army College of Environmental Science and Technology, Makurdi",
  },
  {
    id: 38,
    name: "Nigerian College of Aviation Technology, Zaria, Kaduna State",
  },
  { id: 39, name: "Petroleum Training Institute, Effurun Delta State" },
  { id: 40, name: "Waziri Umaru Federal Polytechnic, Birnin-Kebbi" },
  { id: 41, name: "Yaba College of Technology, Yaba" },
  { id: 42, name: "Abdu Gusau Polytechnic, Talata Mafara" },
  { id: 43, name: "Abia State Polytechnic, Aba" },
  { id: 44, name: "Abraham Adesanya Polytechnic, Ijebu-Igbo" },
  { id: 45, name: "Abubakar Tatari Ali Polytechnic, Bauchi" },
  { id: 46, name: "Adamawa State Polytechnic, Yola" },
  { id: 47, name: "Adeseun Ogundoyin Polytechnic, Eruwa" },
  { id: 48, name: "Akwa Ibom State College of Arts & Science, Nung Ukim" },
  { id: 49, name: "Akwa Ibom State Polytechnic, Ikot Osurua" },
  { id: 50, name: "Anambra State Polytechnic, Mgbakwu" },
  { id: 51, name: "Bayelsa State Polytechnic, Aleibiri, Bayelsa" },
  { id: 52, name: "Benue State Polytechnic, Ugbokolo" },
  { id: 53, name: "Binyaminu Usman Polytechnic, Hadejia" },
  { id: 54, name: "College of Agriculture, Science And Technology, Gujba" },
  {
    id: 55,
    name: "College of Administration, Management And Technology, Potiskum",
  },
  { id: 56, name: "College of Agriculture, Science and Technology, Lafia" },
  { id: 57, name: "Cross River Institute of Technology And Management, Ugep" },
  { id: 58, name: "Delta State Polytechnic, Ogwashi-Uku" },
  { id: 59, name: "Delta State Polytechnic, Otefe-Oghara" },
  {
    id: 60,
    name: "Delta State Polytechnic, Ozoro, Delta State (Now Delta State University of Science And Technology)",
  },
  { id: 61, name: "Delta State School of Marine Technology, Burutu" },
  { id: 62, name: "D.S. Adegbenro ICT Polytechnic, Itori-Ewekoro" },
  { id: 63, name: "Edo State Polytechnic, Usen" },
  {
    id: 64,
    name: "Ekiti State College of Agriculture And Technology, Isan-Ekiti",
  },
  { id: 65, name: "Enugu State Polytechnic, Iwollo" },
  { id: 66, name: "Gateway Polytechnic, Saapade" },
  { id: 67, name: "Gombe State Polytechnic, Bajoga" },
  { id: 68, name: "Hassan Usman Katsina Polytechnic, Katsina" },
  { id: 69, name: "Imo State Polytechnic, Umuagwo" },
  { id: 70, name: "Institute of Management And Technology, Enugu" },
  { id: 71, name: "Isa Mustapha Agwai Polytechnic, Lafia" },
  { id: 72, name: "Jigawa State Polytechnic, Dutse" },
  { id: 73, name: "Kano State Polytechnic, Kano" },
  { id: 74, name: "Katsina Institute of Technology And Management, Katsina" },
  { id: 75, name: "Kenule Beeson Saro-Wiwa Polytechnic, Bori" },
  { id: 76, name: "Kogi State Polytechnic, Lokoja" },
  { id: 77, name: "Kwara State Polytechnic, Ilorin" },
  {
    id: 78,
    name: "Lagos State Polytechnic, Ikorodu (Now Lagos State University of Science and Technology)",
  },
  { id: 79, name: "Mai-Idris Alooma Polytechnic, Geidam" },
  { id: 80, name: "Moshood Abiola Polytechnic, Abeokuta" },
  { id: 81, name: "Niger State Polytechnic, Zungeru" },
  { id: 82, name: "Nuhu Bamalli Polytechnic, Zaria" },
  { id: 83, name: "Ogun State Institute of Technology, Igbesa" },
  { id: 84, name: "Ogun State Polytechnic, Ipokia" },
  { id: 85, name: "Osun State College of Technology, Esa-Oke" },
  { id: 86, name: "Osun State Polytechnic, Iree" },
  { id: 87, name: "Oyo State College of Agriculture And Technology, Igbo-Ora" },
  { id: 88, name: "Plateau State Polytechnic, Barkin-Ladi" },
  { id: 89, name: "Port-Harcourt Polytechnic, Port-Harcourt" },
  { id: 90, name: "Ramat Polytechnic, Maiduguri" },
  { id: 91, name: "Rufus Giwa Polytechnic, Owo" },
  { id: 92, name: "Taraba State Polytechnic, Suntai" },
  { id: 93, name: "The Oke-Ogun Polytechnic, Saki (TOPS)" },
  { id: 94, name: "The Polytechnic, Ibadan" },
  { id: 95, name: "Umaru Ali Shinkafi Polytechnic, Sokoto" },
  { id: 96, name: "Ajayi Polytechnic, Ara-Ekiti" },
  { id: 97, name: "Al-Hikma Polytechnic, Karu" },
  { id: 98, name: "Allover Central Polytechnic, Sango-Ota" },
  { id: 99, name: "Ashi Polytechnic, Anyin" },
  { id: 100, name: "Bellarks Polytechnic, Kwale" },
  { id: 101, name: "Best Solution Polytechnic, Akure" },
  { id: 102, name: "Bolmor Polytechnic, Ibadan" },
  { id: 103, name: "Brainfill Polytechnic, Ikot-Ekpene" },
  { id: 104, name: "British Transatlantic Polytechnic, Akure" },
  { id: 105, name: "Calvary Polytechnic, Owo-Oyibu, Delta State" },
  { id: 106, name: "Citi Polytechnic, Abuja" },
  { id: 107, name: "Coastal Polytechnic, Badagry" },
  { id: 108, name: "Covenant Polytechnic, Aba" },
  { id: 109, name: "Crown Polytechnic, Ado-Ekiti" },
  { id: 110, name: "Distinct Polytechnic, Ekosin" },
  { id: 111, name: "Dorben Polytechnic Bwari" },
  { id: 112, name: "Eastern Polytechnic, Port Harcourt" },
  { id: 113, name: "Ekiti City Polytechnic Omuo-Ekiti" },
  { id: 114, name: "Enville Institute of Management and Technology, Ikeja" },
  { id: 115, name: "Fidei Polytechnic, Gboko" },
  { id: 116, name: "Foundation Polytechnic, Ikot Ekpene" },
  { id: 117, name: "Gboko Polytechnic, Gboko" },
  { id: 118, name: "Global Polytechnic, Benin" },
  { id: 119, name: "Gloryland Polytechnic, Anka" },
  { id: 120, name: "Grace Polytechnic, Surulere" },
  { id: 121, name: "Graceland Polytechnic, Offa" },
  { id: 122, name: "Grundtvig Polytechnic, Oba" },
  { id: 123, name: "Harvard Polytechnic, Ilorin" },
  { id: 124, name: "Harry Pass Polytechnic, Gboko" },
  { id: 125, name: "Heritage Polytechnic, Eket" },
  { id: 126, name: "Hope Polytechnic, Ikono" },
  { id: 127, name: "Ibadan City Polytechnic, Ibadan" },
  { id: 128, name: "Ibom Metropolitan Polytechnic, Uyo" },
  { id: 129, name: "1-Con Universal Polytechnic, Osogbo" },
  { id: 130, name: "Igbajo Polytechnic, Igbajo" },
  { id: 131, name: "Iheachukwu Madubuike Institute of Technology, Isuochi" },
  { id: 132, name: "Inspire Polytechnic, Lagos" },
  { id: 133, name: "Interlink Polytechnic, Ijebu-Jesa" },
  { id: 134, name: "Iwo City Polytechnic, Iwo" },
  { id: 135, name: "Kalac Crystal Polytechnic, Lekki" },
  { id: 136, name: "Kings Polytechnic, Ubiaja" },
  { id: 137, name: "Lagos City Polytechnic, Ikeja" },
  { id: 138, name: "Landmark Polytechnic, Ayetoro" },
  { id: 139, name: "Lens Polytechnic, Offa" },
  { id: 140, name: "Lighthouse Polytechnic, Evbuobanosa" },
  { id: 141, name: "Loam Polytechnic, Ikono" },
  { id: 142, name: "Marist Polytechnic, Emene" },
  { id: 143, name: "Mater Dei Polytechnic, Egwuoba" },
  { id: 144, name: "NACABS Polytechnic, Akwanga" },
  { id: 145, name: "Newland Polytechnic, Ilorin" },
  { id: 146, name: "NOGAK Polytechnic, Ikom" },
  { id: 147, name: "Novelty Polytechnic, Kishi" },
  { id: 148, name: "Oduduwa Polytechnic, Idimu" },
  {
    id: 149,
    name: "Our Saviour Institute of Science, Agriculture & Technology, Enugu",
  },
  { id: 150, name: "Paramount Polytechnic, Ejigbo" },
  { id: 151, name: "Prime Polytechnic, Jida Bassa, Ajaokuta" },
  { id: 152, name: "Redeemers College of Technology And Management, Mowe" },
  { id: 153, name: "Ronik Polytechnic, Ejigbo, Ikeja" },
  { id: 154, name: "Saf Polytechnic, Iseyin" },
  { id: 155, name: "Shaka Polytechnic, Benin City" },
  { id: 156, name: "Southern Atlantic Polytechnic, Uyo" },
  { id: 157, name: "Speedway Polytechnic, Osoba" },
  { id: 158, name: "St. Mary Polytechnic, Kwamba, Suleja" },
  { id: 159, name: "Stars Polytechnic, Ota" },
  { id: 160, name: "Sure Foundation Polytechnic, Ikot-Akai" },
  { id: 161, name: "Teedek Polytechnic, Ilogbo-Ekiti" },
  { id: 162, name: "Temple Gate Polytechnic, Aba" },
  { id: 163, name: "The Polytechnic, Adoka" },
  { id: 164, name: "The Polytechnic Aran-Orin" },
  { id: 165, name: "The Polytechnic, Igbo-Owu" },
  { id: 166, name: "The Polytechnic, Ile-Ife" },
  { id: 167, name: "The Polytechnic, Iresi" },
  { id: 168, name: "The Polytechnic, Omuo-Ekiti" },
  { id: 169, name: "Timeon Kairos Polytechnic, Lagos" },
  { id: 170, name: "Tower Polytechnic, Ibadan" },
  { id: 171, name: "Trinity Polytechnic, Uyo" },
  { id: 172, name: "Uma Ukpai Polytechnic, Ohafia" },
  { id: 173, name: "Ultra Excellence Polytechnic, Uyo" },
  { id: 174, name: "United Polytechnic, Jos" },
  { id: 175, name: "Uyo City Polytechnic, Uyo" },
  { id: 176, name: "Valley View Polytechnic, Ohafia" },
  { id: 177, name: "Villanova Polytechnic, Imesi-lle" },
  { id: 178, name: "Westland Polytechnic, Ilobu, Osun" },
  { id: 179, name: "Wolex Polytechnic, Iwo, Osun State" },
];
