import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import ComponentToPrint from "./ComponentToPrint";

function SummaryPage(props) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Application slip",
    pageStyle: "",
  });

  return (
    <div style={{width:'100%', padding: '1rem 0'}}>
      <ComponentToPrint ref={componentRef} />
      <div className="text-center">
        <button
          style={{
            color: '#ffffff',
            padding: '1rem',
            backgroundColor: '#00356B',
            borderRadius: '10px',
            width: '196px'
          }}
          onClick={handlePrint}
        >
          Print Summary
        </button>
      </div>
    </div>
  );
}

export default SummaryPage;
