export const Universities = [
  {
    name: "Maduka University",
    acronym: "MU",
    web: "http://madukauniversity.edu.ng/",
  },
  {
    name: "Abia State University",
    acronym: "ABSU",
    motto: "Excellence and Service",
    web: "http://abiastateuniversity.edu.ng/",
    logo: "http://abiastateuniversity.edu.ng/wp-content/themes/Poseidon/assets/Images/logo1.png",
  },

  {
    name: "Abubakar Tafawa Balewa University",
    acronym: "ATBU",
    motto:
      "Doctrina matter artium (Education is the mother of the practical arts)",
    web: "https://www.atbu.edu.ng/web/front",
    logo: "https://www.atbu.edu.ng/public/assets/images/atbu_logo.png",
  },
  {
    name: "Achievers University, Owo",
    acronym: "AUO",
    motto: "Knowledge, Integrity and Leadership",
    web: "https://www.achievers.edu.ng/",
    logo: "https://www.achievers.edu.ng/wp-content/uploads/2019/06/new_logo-2.png",
  },
  {
    name: "Adamawa State University",
    acronym: "ADSU",
    motto: "Non Found",
    web: "https://adsu.edu.ng/",
    logo: "https://adsu.edu.ng/wp-content/uploads/2020/11/ADSU-LOGO.png",
  },
  {
    name: "Adekunle Ajasin University",
    acronym: "AAUA",
    motto: "For Learning and Service",
    web: "https://aaua.edu.ng/b/",
    logo: "https://aaua.edu.ng/b/",
  },
  {
    name: "Adeleke University",
    acronym: "Adeleke University",
    motto: "Non Found",
    web: "https://adelekeuniversity.edu.ng/",
    logo: "https://adelekeuniversity.edu.ng/wp-content/uploads/2016/10/logos.png",
  },
  {
    name: "Afe Babalola University",
    acronym: "ABUAD",
    motto: "Labor Servitum Et Integritas(Labor for Service and Integrity)",
    web: "http://www.abuad.edu.ng/",
    logo: "http://www.abuad.edu.ng/wp-content/uploads/2017/03/logo-5.png",
  },
  {
    name: "Admiralty University of Nigeria",
    acronym: "ADUN",
    motto: "Exellence in Education",
    web: "https://adun.edu.ng/",
    logo: "https://adun.edu.ng/wp-content/uploads/2020/09/adun.jpg",
  },
  {
    name: "African University of Science and Technology",
    acronym: "AUST",
    motto: "Non Found",
    web: "https://aust.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/13967.png",
  },
  {
    name: "Ahmadu Bello University",
    acronym: "ABU",
    motto: "Non Found",
    web: "https://abu.edu.ng/",
    logo: "https://abu.edu.ng/images/logo.png",
  },
  {
    name: "Ajayi Crowther University",
    acronym: "ACU",
    motto: "Scientia Probitas(Knowledge with Probity)",
    web: "https://acu.edu.ng/",
    logo: "https://acu.edu.ng/wp-content/uploads/2020/08/acu-logo3-2-1.png",
  },
  {
    name: "Akwa Ibom State University",
    acronym: "AKSU",
    motto: "Knowledge and Technology for Development",
    web: "https://www.aksu.edu.ng/",
    logo: "https://www.aksu.edu.ng//images/logosch.png",
  },
  {
    name: "Alex Ekwueme Federal University, Ndufu-Alike",
    acronym: "FUNAI",
    motto: "Excellence and Integrity",
    web: "https://funai.edu.ng/",
    logo: "https://funai.edu.ng/wp-content/uploads/2020/03/cropped-funai_Logo-1.png",
  },
  {
    name: "Al-Hikmah University",
    acronym: "HUI",
    motto: "Learning for wisdom and morality",
    web: "https://alhikmahuniversity.edu.ng/",
    logo: "https://alhikmahuniversity.edu.ng/wp-content/uploads/2020/09/au-logo.png",
  },
  {
    name: "Al-Qalam University, Katsina",
    acronym: "AUK",
    motto: "Non Found",
    web: "http://www.auk.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10748.png",
  },
  {
    name: "Ambrose Alli University",
    acronym: "AAU",
    motto: "Knowledge for Advancement",
    web: "https://www.aauekpoma.edu.ng/",
    logo: "https://www.aauekpoma.edu.ng/images/aau/logo.png",
  },
  {
    name: "American University of Nigeria",
    acronym: "AUN",
    motto: "Quality, Integrity, Style",
    web: "http://www.aun.edu.ng/",
    logo: "http://www.aun.edu.ng/images/aun-logo-top.png",
  },
  {
    name: "Anchor University, Lagos",
    acronym: "AUL",
    motto: "Character, Competence, Courage",
    web: "https://aul.edu.ng/",
    logo: "https://aul.edu.ng/wp-content/uploads/2020/03/skin12r.header-style-transparent-mobile-logo-hd-full-logo-01.png",
  },
  {
    name: "Arthur Jarvis University",
    acronym: "AJU",
    motto: "Non Found",
    web: "http://arthurjarvisuniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/17752.png",
  },
  {
    name: "Atiba University",
    acronym: "Atiba University",
    motto: "Non Found",
    web: "https://www.atibauniversity.edu.ng/",
    logo: "https://www.atibauniversity.edu.ng/images/big_logo_sm.png",
  },
  {
    name: "Babcock University",
    acronym: "BU",
    motto: "Knowledge, Truth, Service",
    web: "https://www.babcock.edu.ng/",
    logo: "https://www.babcock.edu.ng/assets/images/bu_logo_main_2.jpg",
  },
  {
    name: "Bauchi State University",
    acronym: "BSU",
    motto: "limi Tushen Cigaba(knowledge is the foundation of progress)",
    web: "https://basug.edu.ng/",
    logo: "https://basug.edu.ng/wp-content/uploads/2018/11/basug_logo.jpg",
  },
  {
    name: "Bayero University Kano",
    acronym: "BUK",
    motto:
      "...and above every possessor of knowledge there is One more learned",
    web: "http://www.buk.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/3450.png",
  },
  {
    name: "Baze University",
    acronym: "Baze University",
    motto: "Learn to live",
    web: "https://www.bazeuniversity.edu.ng/",
    logo: "https://www.bazeuniversity.edu.ng/images/logo.png",
  },
  {
    name: "Bells University of Technology",
    acronym: "BellsTech",
    motto: "Only the best is good for Bells",
    web: "https://www.bellsuniversity.edu.ng/",
    logo: "https://www.bellsuniversity.edu.ng/wp-content/uploads/2019/04/Bells-Logo.2png.png",
  },
  {
    name: "Benson Idahosa University",
    acronym: "BIU",
    motto: "Academic Excellence with Godliness",
    web: "https://www.biu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/3451.png",
  },
  {
    name: "Benue State University",
    acronym: "BSU",
    motto: "Scientia Liberatio Populurum(Science makes People free)",
    web: "https://www.bsum.edu.ng/",
    logo: "https://bsum.edu.ng/img/logo/logo.png",
  },
  {
    name: "Bingham University",
    acronym: "BHU",
    motto: "Mission for Service",
    web: "https://binghamuni.edu.ng/v2/",
    logo: "https://binghamuni.edu.ng/v2/images/logo.png",
  },
  {
    name: "Borno State University",
    acronym: "BSU",
    motto: "Non Found",
    web: "https://bosu.edu.ng/",
    logo: "https://bosu.edu.ng/wp-content/uploads/2020/09/HEADER.png",
  },
  {
    name: "Bowen University",
    acronym: "BUI",
    motto: "Non Found",
    web: "https://bowen.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10777.png",
  },
  {
    name: "Caleb University",
    acronym: "Caleb University",
    motto: "For God and Humanity",
    web: "https://calebuniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10778.png",
  },
  {
    name: "Caritas University",
    acronym: "CAU",
    motto: "Love for Education and Morals",
    web: "http://www.caritasuni.edu.ng/",
    logo: "http://www.caritasuni.edu.ng/assets/images/logo-header-1-116x128.png",
  },
  {
    name: "Chrisland University",
    acronym: "Chrisland University",
    motto: "Non Found",
    web: "https://www.chrislanduniversity.edu.ng/",
    logo: "https://www.chrislanduniversity.edu.ng/static/img/logo/cu-logo.png",
  },
  {
    name: "Christopher University",
    acronym: "Christopher University",
    motto: "Non Found",
    web: "http://www.christopheruniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/18424.png",
  },
  {
    name: "Chukwuemeka Odumegwu Ojukwu University",
    acronym: "COOU",
    motto: "Education for Development",
    web: "https://coou.edu.ng/",
    logo: "https://coou.edu.ng/wp-content/uploads/2020/05/coou_logo.png",
  },
  {
    name: "Clifford University",
    acronym: "CLU",
    motto: "Excellence, Faith and Service",
    web: "https://clifforduni.edu.ng/",
    logo: "https://clifforduni.edu.ng/img/logo.png",
  },
  {
    name: "Coal City University",
    acronym: "CCU",
    motto: "Non Found",
    web: "https://ccu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/17746.png",
  },
  {
    name: "Covenant University",
    acronym: "CU",
    motto: "Raising a New Generation of Leaders",
    web: "https://covenantuniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/3452.png",
  },
  {
    name: "Crawford University",
    acronym: "CRU",
    motto: "Knowledge with Godliness",
    web: "https://crawforduniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10760.png",
  },
  {
    name: "Crescent University, Abeokuta",
    acronym: "CUAB",
    motto: "Knowledge and Faith",
    web: "https://crescent.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10761.png",
  },
  {
    name: "Cross River University of Technology",
    acronym: "CRUTECH",
    motto: "Technology for human advancement",
    web: "https://crutech.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10774.png",
  },
  {
    name: "Crown Hill University",
    acronym: "Crown Hill University",
    motto: "Knowledge is Power",
    web: "https://crownhilluniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/17747.png",
  },
  {
    name: "Delta State University, Abraka",
    acronym: "DELSU",
    motto: "Knowledge, Character and Service",
    web: "https://www.delsu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10734.png",
  },
  {
    name: "Dominican University, Ibadan",
    acronym: "DU",
    motto: "Non Found",
    web: "https://dui.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/17748.png",
  },
  {
    name: "Eastern Palm University",
    acronym: "EPU",
    motto: "Non Found",
    web: "https://www.epu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/17743.png",
  },
  {
    name: "Edo University",
    acronym: "EUI",
    motto: "Non Found",
    web: "https://www.edouniversity.edu.ng/",
    logo: "https://www.edouniversity.edu.ng/uploads/settings/logo.png",
  },
  {
    name: "Ebonyi State University",
    acronym: "EBSU",
    motto: "Non Found",
    web: "http://ebsu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10736.png",
  },
  {
    name: "Edwin Clark University",
    acronym: "ECU",
    motto: "Knowledge and Development",
    web: "https://www.edwinclarkuniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/17722.png",
  },
  {
    name: "Ekiti State University, Ado Ekiti",
    acronym: "EKSU",
    motto: "Knowledge, Honour, Service",
    web: "http://www.eksu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/8088.png",
  },
  {
    name: "Eko University of Medical and Health Sciences",
    acronym: "EKOUNIVMED",
    motto: "Non Found",
    web: "https://ekounimed.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/17751.png",
  },
  {
    name: "Elizade University",
    acronym: "ElizadeU",
    motto: "Pragmatic Innovation for Development",
    web: "https://www.elizadeuniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/15122.png",
  },
  {
    name: "Enugu State University of Science and Technology",
    acronym: "ESUTECH",
    motto: "Non Found",
    web: "https://www.esut.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10740.png",
  },
  {
    name: "Evangel University, Akaeze",
    acronym: "EUA",
    motto: "Non Found",
    web: "https://evangeluniversity.edu.ng/",
    logo: "https://evangeluniversity.edu.ng/images/eua-logo.png",
  },
  {
    name: "Federal University of Agriculture, Abeokuta",
    acronym: "FUNAAB",
    motto: "Knowledge for Development",
    web: "https://unaab.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/3458.png",
  },
  {
    name: "Federal University of Petroleum Resources",
    acronym: "FUPRE",
    motto: "Excellence and Relevance",
    web: "https://fupre.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/13950.png",
  },
  {
    name: "Federal University of Technology, Akure",
    acronym: "FUTA",
    motto: "Technology for Self Reliance",
    web: "http://www.futa.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/3453.png",
  },
  {
    name: "Federal University of Technology, Minna",
    acronym: "FUT Minna",
    motto: "Technology for Empowerment",
    web: "https://www.futminna.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10756.png",
  },
  {
    name: "Federal University of Technology, Owerri",
    acronym: "FUTO",
    motto: "Technology for Service",
    web: "https://futo.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/7665.png",
  },
  {
    name: "Federal University, Birnin Kebbi",
    acronym: "FUBK",
    motto: "Non Found",
    web: "http://www.fubk.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/17735.png",
  },
  {
    name: "Federal University, Dutse",
    acronym: "FUD",
    motto: "Non Found",
    web: "https://fud.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/13952.png",
  },
  {
    name: "Federal University, Dustin-Ma",
    acronym: "FUDMA",
    motto: "Non Found",
    web: "https://www.fudutsinma.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/13953.png",
  },
  {
    name: "Federal University, Gashua",
    acronym: "FUG",
    motto: "Non Found",
    web: "http://fugashua.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/17734.png",
  },
  {
    name: "Federal University, Gusau",
    acronym: "FUGUS",
    motto: "Knowledge, Innovation and Service",
    web: "https://www.fugusau.edu.ng/",
    logo: "https://www.fugusau.edu.ng/wp-content/uploads/2020/05/fugus.png",
  },
  {
    name: "Federal University, Kashere",
    acronym: "FUK",
    motto: "Education for global citizenship",
    web: "http://fukashere.edu.ng/",
    logo: "http://fukashere.edu.ng/wp-content/uploads/2020/03/cropped-images23df.jpg",
  },
  {
    name: "Federal University, Lafia",
    acronym: "Federal University, Lafia",
    motto: "Non Found",
    web: "http://fukashere.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/13955.png",
  },
  {
    name: "Federal University, Lokoja",
    acronym: "Federal University, Lokoja",
    motto: "The sky is the limit",
    web: "https://www.fulokoja.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/13956.png",
  },
  {
    name: "Federal University, Otuoke",
    acronym: "FUOTUOKE",
    motto: "Knowledge, Excellence, Service",
    web: "http://www.fuotuoke.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/13951.png",
  },
  {
    name: "Federal University, Oye-Ekiti",
    acronym: "FUOYE",
    motto: "Innovation and Character for National Transformation Dignity",
    web: "https://fuoye.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/13958.png",
  },
  {
    name: "Federal University, Wukari",
    acronym: "FUWUKARI",
    motto: "Character, Excellence and Service",
    web: "http://fuwukari.edu.ng/",
    logo: "http://fuwukari.edu.ng/storage/logo/logo.png",
  },
  {
    name: "Fountain University, Osogbo",
    acronym: "FUO",
    motto: "Faith, Knowledge and Service",
    web: "https://fuo.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10779.png",
  },
  {
    name: "Glorious Vision University",
    acronym: "GVU",
    motto: "To nurture for discipline and excellence",
    web: "https://www.sau.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/13975.png",
  },
  {
    name: "Godfrey Okoye University",
    acronym: "GO Uni",
    motto: "Unity of Knowledge",
    web: "http://www.gouni.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/13969.png",
  },
  {
    name: "Gombe State University",
    acronym: "GSU",
    motto: "Primus inter pares (First among equals)",
    web: "http://gsu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10742.png",
  },
  {
    name: "Gregory University, Uturu",
    acronym: "GUU",
    motto: "Knowledge for Tomorrow",
    web: "https://gregoryuniversityuturu.edu.ng/",
    logo: "https://gregoryuniversityuturu.edu.ng/website/wp-content/uploads/2017/06/cropped-logo-1.png",
  },
  {
    name: "Hallmark University, Ijebu-Itele",
    acronym: "HU",
    motto: "Non Found",
    web: "https://hallmarkuniversity.edu.ng/",
    logo: "https://hallmarkuniversity.edu.ng/images/logo3-1.png",
  },
  {
    name: "Hezekiah University",
    acronym: "UNIHEZ",
    motto: "Academic Excellence with Good Morals",
    web: "https://www.hezekiah.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/17726.png",
  },
  {
    name: "Ibrahim Badamasi Babangida University",
    acronym: "IBB University",
    motto: "Academic Excellence with Good Morals",
    web: "https://ibbu.edu.ng/",
    logo: "https://ibbu.edu.ng/wp-content/uploads/2019/10/logo.png",
  },
  {
    name: "Igbinedion University Okada",
    acronym: "IUO",
    motto: "Knowledge and Excellence",
    web: "https://www.iuokada.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10738.png",
  },
  {
    name: "Ignatius Ajuru University of Education",
    acronym: "IAUE",
    motto: "Non Found",
    web: "https://iauoe.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/17737.png",
  },
  {
    name: "Imo State University",
    acronym: "IMSU",
    motto: "Excellence in service",
    web: "http://www.imsu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10744.png",
  },
  {
    name: "Joseph Ayo Babalola University",
    acronym: "JABU",
    motto: "For knowledge and Godly Service",
    web: "https://jabu.edu.ng/",
    logo: "https://jabu.edu.ng/wp-content/uploads/2019/07/JABU-Logo-n-Header.png",
  },
  {
    name: "Kaduna State University",
    acronym: "KASU",
    motto: "Knowledge for Development and Unity",
    web: "https://www.kasu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10746.png",
  },
  {
    name: "Kano University of Science and Technology",
    acronym: "KUST",
    motto: "Non Found",
    web: "http://kustwudil.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10747.png",
  },
  {
    name: "Kebbi State University of Science and Technology",
    acronym: "KSUSTA",
    motto: "Knowledge for Self Reliance",
    web: "https://ksusta.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/12079.png",
  },
  {
    name: "Kings University",
    acronym: "KU",
    motto: "Raising Leaders, transforming minds",
    web: "https://www.kingsuniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/17727.png",
  },
  {
    name: "Kogi State University",
    acronym: "KSU",
    motto: "Knowledge for Self Reliance",
    web: "https://www.ksu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10750.png",
  },
  {
    name: "Kola Daisi University",
    acronym: "KDU",
    motto: "Knowledge for the Service of Honesty",
    web: "https://koladaisiuniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/17750.png",
  },
  {
    name: "Kwara State University",
    acronym: "KWASU",
    motto: "Service and Integrity",
    web: "https://www.kwasu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/12080.png",
  },
  {
    name: "Kwararafa University, Wukari",
    acronym: "KWU",
    motto: "Education for Total Development",
    web: "https://www.kuw.edu.ng/",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPoUC3pLu-LKpXOyd5oWh3qwBIV0ZJh1jH4Q&usqp=CAU",
  },
  {
    name: "Ladoke Akintola University of Technology",
    acronym: "LAUTECH",
    motto: "Excellence, Integrity and Service",
    web: "http://www.lautech.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10767.png",
  },
  {
    name: "Lagos State University",
    acronym: "LASU",
    motto: "Per la verità e di servizio (For Truth and Service)",
    web: "https://lasu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/8084.png",
  },
  {
    name: "Landmark University",
    acronym: "LMU",
    motto: "Breaking New Grounds",
    web: "https://lmu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/13970.png",
  },
  {
    name: "Lead City University",
    acronym: "LCU",
    motto: "Knowledge for Self-reliance",
    web: "https://www.lcu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10780.png",
  },
  {
    name: "Madonna University, Okija",
    acronym: "MU",
    motto: "Decency in Education and Morals",
    web: "https://www.madonnauniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10781.png",
  },
  {
    name: "Mcpherson University",
    acronym: "MCU",
    motto: "Knowledge, Integrity and Service",
    web: "http://www.mcu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/17728.png",
  },

  {
    name: "Michael and Cecilia Ibru University",
    acronym: "MCIU",
    motto: "Knowledge for the alleviation of poverty",
    web: "https://www.mciu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/17729.png",
  },
  {
    name: "Michael Okpara University of Agriculture",
    acronym: "MOUAU",
    motto: "Knowledge, Food and Security",
    web: "https://mouauportal.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/8085.png",
  },
  {
    name: "Modibbo Adama University of Technology",
    acronym: "MAUTECH",
    motto: "Technology for Development",
    web: "https://mautech.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10729.png",
  },
  {
    name: "Moshood Abiola University of Science and Technology, Abeokuta",
    acronym: "MAUSTECH",
    motto: "Technology for Development",
    web: "https://mapoly.edu.ng/web/",
    logo: "https://mapoly.edu.ng/web/wp-content/uploads/2015/01/logo2.png",
  },
  {
    name: "Mountain Top University",
    acronym: "MTU",
    motto: "Empowered to Excel",
    web: "http://www.mtu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/17730.png",
  },
  {
    name: "Nasarawa State University",
    acronym: "NSU",
    motto: "Knowledge for Development",
    web: "https://nsuk.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10775.png",
  },
  {
    name: "Niger Delta University",
    acronym: "NDU",
    motto: "Creativity, Excellence and Service",
    web: "http://ndu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10731.png",
  },
  {
    name: "Nigerian Maritime University, Okerenkoko",
    acronym: "NMU",
    motto: "Non Found",
    web: "https://www.nmu.edu.ng/",
    logo: "https://www.nmu.edu.ng/wp-content/uploads/2019/06/NMU2.jpg",
  },
  {
    name: "Nile University of Nigeria",
    acronym: "NTNU",
    motto: "Non Found",
    web: "https://www.nileuniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/13971.png",
  },
  {
    name: "Nnamdi Azikiwe University",
    acronym: "NAU",
    motto: "Discipline, Self Reliance and Excellence",
    web: "https://www.unizik.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/8086.png",
  },
  {
    name: "Novena University",
    acronym: "Novena University",
    motto: "Knowledge through diligence",
    web: "https://novenauniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10735.png",
  },
  {
    name: "Obafemi Awolowo University",
    acronym: "OAU",
    motto: "For Learning and Culture",
    web: "https://oauife.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/3456.png",
  },
  {
    name: "Obong University",
    acronym: "Obong",
    motto: "Education for Sustainable Development",
    web: "http://www.obonguniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10782.png",
  },
  {
    name: "Oduduwa University",
    acronym: "Oduduwa University",
    motto: "Learning for human development",
    web: "http://www.oduduwauniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/13972.png",
  },
  {
    name: "Olabisi Onabanjo University",
    acronym: "OOU",
    motto: "The Flowering of Human Abilities and Service to the Fatherland",
    web: "https://oouagoiwoye.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/7666.png",
  },
  {
    name: "Ondo State University of Science and Technology",
    acronym: "OSUSTECH",
    motto: "The Flowering of Human Abilities and Service to the Fatherland",
    web: "http://www.osustech.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/12081.png",
  },
  {
    name: "Osun State University",
    acronym: "UNIOSUN",
    motto: "Living Spring of Knowledge and Culture",
    web: "http://uniosun.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10776.png",
  },
  {
    name: "PAMO University of Medical Sciences",
    acronym: "PUMS",
    motto: "Non Found",
    web: "https://www.pums.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/18429.png",
  },
  {
    name: "Pan-Atlantic University",
    acronym: "PAU",
    motto: "Non Found",
    web: "https://www.pau.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10753.png",
  },
  {
    name: "Paul University",
    acronym: "Paul University",
    motto: "Ecce Ego Mite Me",
    web: "http://www.pauluniversity.edu.ng/",
    logo: "https://www.pauluniversity.edu.ng/wp-content/uploads/2018/12/paullogo_18.png",
  },
  {
    name: "Plateau State University",
    acronym: "PLASU",
    motto: "Non Found",
    web: "http://plasu.edu.ng/",
    logo: "http://plasu.edu.ng/Portals/25/plasulogo.png?ver=2020-05-22-062551-950",
  },
  {
    name: "Precious Cornerstone University",
    acronym: "PCU",
    motto: "Let there be light",
    web: "http://pcu.edu.ng/",
    logo: "https://pcu.edu.ng/wp-content/uploads/2019/08/logo.png",
  },
  {
    name: "Redeemer's University",
    acronym: "RUN",
    motto: "Running with a Vision",
    web: "http://run.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10763.png",
  },
  {
    name: "Ritman University",
    acronym: "Ritman University",
    motto: "Knowledge for Development",
    web: "https://www.ritmanuniversity.edu.ng/",
    logo: "https://static.wixstatic.com/media/c4c29c_febcf9eff2b842afa8044fda6d5182ca~mv2.jpg/v1/fill/w_63,h_63,al_c,q_80,usm_0.66_1.00_0.01/images-10.webp",
  },
  {
    name: "Rhema University",
    acronym: "RU",
    motto: "Equipping Humanity for Excellence",
    web: "https://www.rhemauniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/13974.png",
  },
  {
    name: "Rivers State University",
    acronym: "RSU",
    motto: "Excellence and Creativity",
    web: "http://www.rsu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/3457.png",
  },
  {
    name: "Salem University",
    acronym: "SU",
    motto: "Non Found",
    web: "https://www.salemuniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10783.png",
  },
  {
    name: "Skyline University Nigeria",
    acronym: "SUN",
    motto: "Non Found",
    web: "https://www.sun.edu.ng/",
    logo: "https://www.sun.edu.ng/images/logo.png",
  },
  {
    name: "Sokoto State University",
    acronym: "SSU",
    motto: "It all begins here!",
    web: "https://ssu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/13962.png",
  },
  {
    name: "Southwestern University, Nigeria",
    acronym: "SUN",
    motto: "In God We Trust",
    web: "https://southwesternuniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/17732.png",
  },
  {
    name: "Spiritan University, Nneochi",
    acronym: "SUN",
    motto: "Learning for Industry and Dignity",
    web: "https://www.spiritanuniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/18427.png",
  },
  {
    name: "Summit University Offa",
    acronym: "SUNO",
    motto: "Knowledge, Faith and Wisdom",
    web: "https://www.summituniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/17733.png",
  },
  {
    name: "Tai Solarin University of Education",
    acronym: "TASUED",
    motto: "Integrity and Selflessness",
    web: "https://tasued.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/12287.png",
  },
  {
    name: "Tansian University",
    acronym: "TANU",
    motto: "Knowledge is power and virtue",
    web: "http://www.tansianuniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10784.png",
  },
  {
    name: "Taraba State University",
    acronym: "TSU",
    motto: "Harnessing Nature's Gift",
    web: "http://www.tsuniversity.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/12083.png",
  },
  {
    name: "The Technical University",
    acronym: "Tech-U",
    motto: "Non Found",
    web: "http://tech-u.edu.ng/",
    logo: "https://tech-u.edu.ng/wp-content/uploads/2020/12/techu_logochristmas-1.png",
  },
  {
    name: "Umaru Musa Yar'Adua University",
    acronym: "UMYU",
    motto: "Non Found",
    web: "https://www.umyu.edu.ng/",
    logo: "https://umyu.edu.ng/wp-content/uploads/2020/08/logo2.png",
  },
  {
    name: "University of Abuja",
    acronym: "UNIABUJA",
    motto: "Non Found",
    web: "https://www.uniabuja.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10726.png",
  },
  {
    name: "University of Africa",
    acronym: "UAT",
    motto: "Understanding, Diligence, Service",
    web: "https://uat.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/17718.png",
  },
  {
    name: "University of Agriculture, Makurdi",
    acronym: "UAM",
    motto: "Innovation and Service",
    web: "https://uam.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10732.png",
  },
  {
    name: "University of Benin",
    acronym: "UNIBEN",
    motto: "Knowledge and Character",
    web: "https://www.uniben.edu/",
    logo: "https://www.4icu.org/i/logos-seals/3459.png",
  },
  {
    name: "University of Calabar",
    acronym: "UNICAL",
    motto: "Knowledge and Character",
    web: "https://www.unical.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/7668.png",
  },
  {
    name: "University of Ibadan",
    acronym: "UI",
    motto: "Right thinking is the fount (of knowledge)",
    web: "https://www.ui.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/3460.png",
  },
  {
    name: "University of Ilorin",
    acronym: "UIL",
    motto: "Character and learning",
    web: "http://www.unilorin.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/7222.png",
  },
  {
    name: "University of Jos",
    acronym: "UNIJOS",
    motto: "Discipline and Dedication",
    web: "https://www.unijos.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/3461.png",
  },
  {
    name: "University of Lagos",
    acronym: "UNILAG",
    motto: "In deed and in truth",
    web: "https://unilag.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/7249.png",
  },
  {
    name: "University of Maiduguri",
    acronym: "Unimaid",
    motto: "Center of Excellence",
    web: "http://www.unimaid.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/8089.png",
  },
  {
    name: "University of Medical Sciences",
    acronym: "UNIMED",
    motto: "Non Found",
    web: "https://www.unimed.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/17740.png",
  },
  {
    name: "University of Mkar",
    acronym: "UMM",
    motto: "Knowledge for Science",
    web: "https://www.umm.edu.ng/",
    logo: "https://www.umm.edu.ng/assets/images/ummlogo.jpg",
  },
  {
    name: "University of Nigeria",
    acronym: "UNN",
    motto: "To restore the dignity of man",
    web: "http://www.unn.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/8090.png",
  },
  {
    name: "University of Port Harcourt",
    acronym: "Uniport",
    motto: "For Enlightenment and Self-Reliance",
    web: "https://www.uniport.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/3464.png",
  },
  {
    name: "University of Uyo",
    acronym: "Uniuyo",
    motto: "Unity, Learning and Service",
    web: "http://www.uniuyo.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/8091.png",
  },
  {
    name: "Usmanu Danfodio University",
    acronym: "UDU",
    motto: "Read",
    web: "https://www.udusok.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/7669.png",
  },
  {
    name: "Veritas University",
    acronym: "VU",
    motto: "Seeking the Truth",
    web: "https://www.veritas.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/13976.png",
  },
  {
    name: "Wellspring University",
    acronym: "WU",
    motto: "Non Found",
    web: "http://wellspringuni.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/13977.png",
  },
  {
    name: "Wesley University of Science and Technology",
    acronym: "WUSTO",
    motto: "Knowledge and Character for Development",
    web: "https://wesleyuni.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/13978.png",
  },
  {
    name: "Western Delta University",
    acronym: "WDU",
    motto: "Knowledge for Human Advancement",
    web: "http://www.wdu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/10785.png",
  },
  {
    name: "Yobe State University",
    acronym: "YSU",
    motto: "Non Found",
    web: "https://ysu.edu.ng/ysu/",
    logo: "https://www.4icu.org/i/logos-seals/10770.png",
  },
  {
    name: "Yusuf Maitama Sule University Kano",
    acronym: "NWU",
    motto: "Non Found",
    web: "https://www.nwu.edu.ng/",
    logo: "https://www.4icu.org/i/logos-seals/16955.png",
  },
];
